import gql from 'graphql-tag';

export const getProjectLabels = gql`
  query getProjectLabels {
    project(order_by: { label: asc }, where: { archived: { _eq: false } }) {
      id
      label
    }
  }
`;
