import React, { useState } from 'react';
import styled from 'styled-components';

import { GetLogCodesQuery } from '@/generated/hasura.graphql';
import Close from '@/components/icons/close';
import LevelTag from '@molecules/level-tag';
import screens from '@/constants/screens';
import CodeItemEditor from '@/admin/codes/code-edit/code-item-edit';

// This is a hack to turn an array type into a single item.
export type CodeItem = GetLogCodesQuery['code'][0];

const Container = styled.div`
  background-color: white;
  border-radius: 0.25rem;
  margin: 0 1rem 0.25rem 0;
  position: relative;
`;

const Row = styled.div`
  align-items: center;
  cursor: pointer;
  display: grid;
  grid-template-columns: 0.5fr 0.5fr 0.5fr 3fr 1fr 2fr 3fr 2fr;
  gap: 1rem;
  padding: 1rem;

  @media (min-width: ${screens.md}) {
    grid-template-columns: 0.5fr 0.5fr 0.5fr 2fr 1fr 1fr 2fr 1.5fr;
  }

  @media (min-width: ${screens.xl}) {
    grid-template-columns: 0.5fr 0.5fr 0.5fr 4.5fr 1.25fr 1fr 4fr 2fr;
  }
`;

const BUTTON = styled.button`
  align-items: center;
  display: inline-flex;
  font-size: 0.875rem;
  position: absolute;
  right: 1.5rem;
  text-transform: uppercase;
  top: 1.25rem;

  svg {
    height: 0.9em;
    margin-left: 0.5em;
  }
`;

export const CodeItem = ({
  handleOnChecked,
  code,
}: {
  handleOnChecked: () => void;
  code: CodeItem;
}) => {
  const [open, setOpen] = useState<boolean>(false);

  if (open) {
    return (
      <Container>
        <BUTTON type="button" onClick={() => setOpen(false)}>
          Close <Close />
        </BUTTON>
        <CodeItemEditor {...code} />
      </Container>
    );
  }

  const {
    code_audiences: codeAudiences,
    code_responsibilities: codeResponsibilities,
    id,
    level,
    name,
    system,
    audio_notification: audioNotification,
  } = code;

  return (
    <Container>
      <Row>
        <input type="checkbox" onChange={handleOnChecked} />
        <span className="font-medium">{String(id).padStart(4, '0')}</span>
        <span className={audioNotification != null ? 'icon-bell' : ''} />
        <span
          role="button"
          tabIndex={0}
          onKeyDown={() => setOpen(true)}
          onClick={() => setOpen(true)}
        >
          {name}
        </span>
        <span>
          <LevelTag level={level} />
        </span>
        <span>{system?.name}</span>
        <span>
          {codeAudiences
            .map((ca) => ca.audience)
            .filter((a) => a.name)
            .map((audience) => audience.name)
            .join(', ')}
        </span>
        <span>
          {codeResponsibilities
            .map((cr) => cr.responsibility)
            .filter((r) => r?.name)
            .map((responsibility) => responsibility?.name)
            .join(', ')}
        </span>
      </Row>
    </Container>
  );
};

export default CodeItem;
