import gql from 'graphql-tag';

export const getSlumps = gql`
  query getSlumps(
    $startDate: timestamptz
    $endDate: timestamptz
    $systemIds: [Int!]
  ) {
    slump(
      where: {
        _and: {
          created_at: { _gte: $startDate, _lte: $endDate }
          system_id: { _in: $systemIds }
        }
      }
      order_by: { sampled_at: desc, batch_id: desc }
    ) {
      air_percent
      archived
      archived_at
      batch_offset
      batch_validated
      comment
      created_at
      cylinder
      cylinder_id
      cylinder_count
      density_gcm3
      empty_weight_g
      full_weight_g
      height_inches
      id
      large_air_meter_serial
      layer
      location
      lot_number
      material_temperature_f
      print_event_id
      print_lead
      project_id
      project_alias
      sampled_at
      slump_cm
      small_air_meter_serial
      submitted_by
      batch {
        batch_id
        batch_number
        id
      }
      system {
        name
        serial
      }
      slump_location {
        display_name
      }
      slump_type {
        display_name
        slump_cone {
          label
          volume_ml
        }
      }
    }
  }
`;
