import { nanoid } from 'nanoid';
import {
  Checklist as ChecklistDb,
  GetChecklistsQuery,
} from '@/generated/hasura.graphql';

import { camelCase, snakeCase } from '@/utils';
import { Checklist, ChecklistItem } from './types';

// Attempt to parse each value as JSON.
const tryParse = (value: unknown) => {
  if (typeof value !== 'string') {
    return value;
  }

  try {
    return JSON.parse(value);
  } catch (error) {
    return value;
  }
};

// Transform DB key format to client side format.
export const transformChecklistDbEntity = (
  checklist: ChecklistDb,
): Checklist => {
  return Object.entries(checklist)
    .filter(([key]) => !/typename/i.test(key))
    .reduce((o, [key, value]) => {
      if (key === 'items') {
        return {
          ...o,
          items: tryParse(value).map((v: ChecklistDb) =>
            transformChecklistDbEntity(v),
          ),
        };
      }

      return {
        ...o,
        [camelCase(key)]: tryParse(value),
      } as Checklist;
    }, {} as Checklist);
};

// Transform client side keys to database format.
export const transformChecklistToDbEntity = (
  checklist: Checklist | ChecklistItem,
): ChecklistDb => {
  return Object.entries(checklist).reduce((o, [key, value]) => {
    if (key === 'items') {
      return {
        ...o,
        items: value.map((v: ChecklistItem) =>
          transformChecklistToDbEntity(v),
        ),
      };
    }

    return {
      ...o,
      [snakeCase(key)]: tryParse(value),
    } as ChecklistDb;
  }, {} as ChecklistDb);
};

// PostgreSQL entities have underscores in their key values, but we're using
// the TS convention of camelCase for client side typing.
export const processGetChecklistDbResponse = (
  result: GetChecklistsQuery,
): Checklist[] => result.checklist.map((c) => transformChecklistDbEntity(c));

export const generateChecklistItem = (createdBy: string) => (
  name: string,
  id = nanoid(),
): ChecklistItem => ({
  archived: false,
  assigned: [],
  createdAt: new Date(),
  createdBy,
  description: '',
  isGroup: false,
  items: [],
  id,
  updatedAt: new Date(),
  name,
  systems: [],
});

export const generateChecklist = (createdBy: string) => (
  name: string,
  id = nanoid(),
): Checklist => ({
  archived: false,
  createdAt: new Date(),
  createdBy,
  description: '',
  items: [],
  id,
  updatedAt: new Date(),
  name,
});

export const removeChecklistById = (
  id: string,
  checklists: Checklist[],
): Checklist[] => {
  return checklists.filter((checklist) => checklist.id !== id);
};
