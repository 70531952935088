import * as Z from '@iconbuild/izzy';
import roundTo from 'round-to';
import { MaterialPlanningSettings } from './material-edit';

const cleanUpSitePlan = (sitePlan: Z.SitePlan): Z.SitePlan => ({
  ...sitePlan,
  items: sitePlan.items.map((item) => {
    const huh = item.buildPlan.actions.filter(
      (action) => action.toolPath.length > 0,
    );
    return {
      ...item,
      buildPlan: {
        ...item.buildPlan,
        actions: huh,
      },
    };
  }),
});

// Base line travel parameters.
// Can keep adding input parameters to fine tune the output
// values.
const travelParams: Z.PrintSpeedParams = {
  printParams: {
    beadVolumePercent: 0.85,
    cornerSmoothingInches: 0,
    infillSpeedPercent: 1.2,
    layerHeightInches: 0.75,
    minTravelHeightInches: 5,
    minTravelHeightBetweenItemsInches: 8,
    endExtrusionOffsetSeconds: 9,
    startExtrusionOffsetSeconds: 0.3,
    printInchesPerSecond: 7,
    stopExtrusionLookaheadSeconds: 0.5,
    startExtrusionLookaheadSeconds: 1.75,
    travelInchesPerSecond: 8,
    verticalTravelInchesPerSecond: 2,
    wipeInches: 2.5,
    wipeSpeedPercent: 0.3,
    travelOffsetInches: 0.5,
  },
  avgFeedRateTrim: 0.85,
};

/**
 * Input values for total print estimates.
 */
interface PrinterParam {
  timingParams: Z.PrintSpeedParams;
  volumePerSupersack: number;
  averageBatchWeight: number;
  lavacreteDensity: number;
}

interface TotalQuants {
  id: number;
  totalLinearFt: number;
  totalVolumeYards: number;
  totalTimeHours: number;
  numberOfSupersacks: number;
  numberOfBatches: number;
  liftTimeMinutesMin: number;
  liftTimeMinutesMax: number;
  liftTimeAverage: number;
  dutyCycle: number;
}

interface LevelQuants {
  id: number;
  startHeightInches: string;
  endHeightInches: string;
  layers: number;
  printInches: number;
  cubicYards: number;
  layerTimeMinutes: number;
  printTimeHours: number;
  dutyCycle: number;
}

interface LiftData {
  liftTimeMinutesMin: number;
  liftTimeMinutesMax: number;
  totalLayers: number;
}

/**
 * Extract the total print quantities for the site plan.
 * @param sitePlan
 * @param liftData
 * @param totalLinearFt
 * @param params
 */
const totalPrintQuantities = ({
  sitePlan,
  liftData,
  totalLinearFt,
  params,
  heightParams,
}: {
  sitePlan: Z.SitePlan;
  liftData: LiftData;
  totalLinearFt: number;
  params: PrinterParam;
  heightParams: {
    startHeightInches: number;
    endHeightInches: number;
  };
}): TotalQuants[] => {
  // Total Volume of lavacrete to complete the print.
  const volumeCubicInches = Z.sitePlanPrintVolume(sitePlan, {
    heightParams,
  });

  // Time estimates needed to complete the print.
  const sitePlanTime = Z.sitePlanTimeEstimates(params.timingParams)({
    sitePlan,
    heightParams,
  }).endOfPrint.secondsRemaining;

  // Total time to complete the project.
  const totalTimeHours = roundTo(sitePlanTime.timeRequiredSeconds / 3600, 2);

  return [
    {
      id: volumeCubicInches,
      totalVolumeYards: roundTo(
        Z.convertVolume(volumeCubicInches, 'in^3', 'yd^3'),
        2,
      ),
      totalLinearFt,
      totalTimeHours,
      numberOfSupersacks: Z.numberOfSupersacks({
        volumeCubicInches,
        volumePerSupersack: params.volumePerSupersack,
      }),
      numberOfBatches: Z.numberOfBatches({
        volumeCubicInches,
        averageBatchWeight: params.averageBatchWeight,
        lavacreteDensity: params.lavacreteDensity,
      }),
      liftTimeMinutesMin: liftData.liftTimeMinutesMin,
      liftTimeMinutesMax: liftData.liftTimeMinutesMax,
      liftTimeAverage: roundTo(
        (totalTimeHours * 60) / liftData.totalLayers,
        2,
      ),
      dutyCycle: roundTo(
        (100 * sitePlanTime.printTimeRequiredSeconds) /
          sitePlanTime.timeRequiredSeconds,
        2,
      ),
    },
  ];
};

/**
 * Extract the print quantities for each level iun the site plan.
 * @param sitePlan
 * @param params
 */
const levelPrintQuantities = ({
  sitePlan,
  params,
  heightParams,
}: {
  sitePlan: Z.SitePlan;
  params: PrinterParam;
  heightParams: {
    startHeightInches: number;
    endHeightInches: number;
  };
}): LevelQuants[] => {
  const { layerHeightInches } = params.timingParams.printParams;
  return Z.sitePlanPrintLevels(sitePlan, heightParams).map((level) => {
    const previousLevelOverprint =
      level.startHeightInches % layerHeightInches
        ? layerHeightInches - (level.startHeightInches % layerHeightInches)
        : 0;

    const timeToCompleteLevel = Z.timeToCompleteLevelSeconds(
      params.timingParams,
    )({
      ...level,
      startHeightInches: level.startHeightInches + previousLevelOverprint,
    });

    const time = roundTo(timeToCompleteLevel.timeRequiredSeconds / 3600, 2);

    const levelHeightInches =
      level.endHeightInches - level.startHeightInches - previousLevelOverprint;

    const numLayers = Math.ceil(levelHeightInches / layerHeightInches);

    // Negative layers happens when the print levels go below the slab
    // and the level height is not evenly divisible by the layer height
    // which means we'd have to print the first layer lower than the start of
    // the level in order for the top of the bead to be at the right level to
    // start the rest of the print on the slab. To flag this as a invalid
    // condition we show 0 layers for level.
    // Also 0 layer levels happens when the bead height is larger than the
    // level height, so we want to show 0s for the level in that case as well
    // rather than dividing by 0.
    if (numLayers <= 0) {
      return {
        id: level.startHeightInches,
        startHeightInches: `${level.startHeightInches}"`,
        endHeightInches: `${level.endHeightInches}"`,
        layers: 0,
        printInches: 0,
        cubicYards: 0,
        layerTimeMinutes: 0,
        printTimeHours: 0,
        dutyCycle: 0,
      };
    }

    return {
      id: level.startHeightInches,
      startHeightInches: `${level.startHeightInches}"`,
      endHeightInches: `${level.endHeightInches}"`,
      layers: roundTo(numLayers, 2),
      printInches: roundTo(
        level.items.reduce(
          (total, i) => total + Z.toolPathActiveLength(i.action.toolPath),
          0,
        ),
        0,
      ),
      cubicYards: roundTo(
        Z.convertVolume(
          Z.volumeFromLevel(level.items[0].action.beadWidthInches)(level),
          'in^3',
          'yd^3',
        ),
        2,
      ),
      layerTimeMinutes: roundTo((time * 60) / numLayers, 2),
      printTimeHours: time,
      dutyCycle: roundTo(
        (100 * timeToCompleteLevel.printTimeRequiredSeconds) /
          timeToCompleteLevel.timeRequiredSeconds,
        2,
      ),
    };
  });
};

/**
 * Iterate through the print level data and extract the lift time extremes and
 * total number of layers.
 * @param printDataByLevel
 */
const extractLiftData = (printDataByLevel: LevelQuants[]): LiftData =>
  printDataByLevel.reduce(
    (result, level) => {
      const liftTimeMinutesMin = Math.min(
        level.layerTimeMinutes,
        result.liftTimeMinutesMin,
      );
      const liftTimeMinutesMax = Math.max(
        level.layerTimeMinutes,
        result.liftTimeMinutesMax,
      );
      return {
        liftTimeMinutesMin,
        liftTimeMinutesMax,
        totalLayers: result.totalLayers + level.layers,
      };
    },
    {
      liftTimeMinutesMin: Infinity,
      liftTimeMinutesMax: 0,
      totalLayers: 0,
    },
  );

/**
 * Iterate through the print level data and extract the total length in linear
 * feet required to print the project.
 * @param printDataByLevel
 * @returns
 */
const extractPrintLength = (printDataByLevel: LevelQuants[]): number =>
  roundTo(
    printDataByLevel.reduce((result, level) => {
      return result + level.printInches * level.layers;
    }, 0) / 12,
    2,
  );

/**
 * Take in a site plan and return the print data (total and by layer).
 * @param sitePlan
 * @param props
 */
export const extractPrintQuantities = (
  sitePlan: Z.SitePlan | null,
  props: MaterialPlanningSettings,
): {
  totalPrintData: TotalQuants[];
  printDataByLevel: LevelQuants[];
} => {
  if (!sitePlan)
    return {
      totalPrintData: [],
      printDataByLevel: [],
    };

  // Remove any empty actions from the site plan.
  const updatedSitePlan = cleanUpSitePlan(sitePlan);

  const params: PrinterParam = {
    averageBatchWeight: props.averageBatchWeight,
    volumePerSupersack: props.volumePerSupersack,
    lavacreteDensity: 131,
    timingParams: {
      ...travelParams,
      printParams: {
        ...travelParams.printParams,
        printInchesPerSecond: props.printSpeedInchesPerSecond,
        travelInchesPerSecond: props.travelSpeedInchesPerSecond,
        layerHeightInches: props.layerHeightInches,
      },
    },
  };

  const printDataByLevel = levelPrintQuantities({
    sitePlan: updatedSitePlan,
    params,
    heightParams: {
      startHeightInches: props.startHeightInches,
      endHeightInches: props.endHeightInches,
    },
  });

  const totalPrintData = totalPrintQuantities({
    sitePlan: updatedSitePlan,
    liftData: extractLiftData(printDataByLevel),
    totalLinearFt: extractPrintLength(printDataByLevel),
    params,
    heightParams: {
      startHeightInches: props.startHeightInches,
      endHeightInches: props.endHeightInches,
    },
  });

  return { totalPrintData, printDataByLevel };
};
