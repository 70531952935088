import {
  Schema,
  validate as validateSchema,
  ValidationError,
  ValidatorResult,
} from 'jsonschema';

import { CodeFormValues } from './types';

export const LogCodeSchema: Schema = {
  type: 'object',
  title: 'Log Code Schema',
  required: ['description', 'level', 'disruptionLevel', 'name', 'system'],
  properties: {
    audience: {
      type: 'array',
      additionalItems: true,
      items: {
        anyOf: [
          {
            type: 'object',
            required: ['value', 'label'],
            properties: {
              value: {
                type: 'integer',
              },
              label: {
                type: 'string',
              },
            },
            additionalProperties: true,
          },
        ],
      },
    },
    description: {
      type: 'string',
    },
    resolution: {
      type: 'string',
    },
    level: {
      type: 'object',
      required: ['value', 'label'],
      properties: {
        value: {
          type: 'integer',
        },
        label: {
          type: 'string',
        },
      },
      additionalProperties: true,
    },
    name: {
      type: 'string',
      minLength: 2,
    },
    operation: {
      type: ['object', 'null'],
      required: ['value', 'label'],
      properties: {
        value: {
          type: 'integer',
        },
        label: {
          type: 'string',
        },
      },
      additionalProperties: true,
    },
    disruptionLevel: {
      type: 'object',
      required: ['value', 'label'],
      properties: {
        value: {
          type: 'integer',
        },
        label: {
          type: 'string',
        },
      },
      additionalProperties: true,
    },
    responsibility: {
      type: 'array',
      additionalItems: true,
      items: {
        anyOf: [
          {
            type: 'object',
            required: ['value', 'label'],
            properties: {
              value: {
                type: 'integer',
              },
              label: {
                type: 'string',
              },
            },
            additionalProperties: true,
          },
        ],
      },
    },
    system: {
      type: 'object',
      required: ['value', 'label'],
      properties: {
        value: {
          type: 'integer',
        },
        label: {
          type: 'string',
        },
      },
      additionalProperties: true,
    },
  },
  additionalProperties: true,
};

// Run jsonschema validate against the provided CodeForm.
export const validate = (logCode: CodeFormValues): ValidationError[] => {
  const validationResult: ValidatorResult = validateSchema(
    logCode,
    LogCodeSchema,
  );
  return validationResult.errors;
};
