import React from 'react';
import styled from 'styled-components';

import { User } from '@providers/auth';
import Avatar from '@atoms/avatar';
import screens from '@/constants/screens';

const Container = styled.div`
  padding: 1rem 2rem;
  display: flex;
  align-items: center;

  @media (min-width: ${screens.lg}) {
    padding: 1rem;
  }
`;

const UserNameRole = styled.div`
  margin-left: 8px;
  div:last-child {
    text-transform: uppercase;
    color: #888;
    font-size: 14px;
  }
`;

export const UserDetail = ({ user }: { user: User }) => (
  <Container>
    <Avatar name={user.name} size="medium" url={user.picture} />
    <UserNameRole>
      <div>{user.name}</div>
      <div>ICON Staff</div>
    </UserNameRole>
  </Container>
);

export default UserDetail;
