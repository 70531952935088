import moment from 'moment';
import React from 'react';
import styled from 'styled-components';

import colors from '@/constants/colors';
import HR from '@atoms/hr';
import Label from '@atoms/label';
import Input from '@atoms/input';
import Textarea, { TextAreaTheme } from '@atoms/textarea';
import { Checklist } from '../types';

const Container = styled.div`
  * + label {
    margin-top: 1rem;
  }

  hr {
    // Adjust for padding in panel.
    margin-left: -1rem;
    width: calc(100% + 2rem);
  }
`;

const H3 = styled.h3`
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 0.875rem;
  line-height: 1.5rem;
  text-transform: uppercase;
  color: ${colors.cyan['500']};
`;

const TABLE = styled.table`
  font-size: 0.875rem;
  margin-top: 1rem;

  td {
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
  }

  td:first-of-type {
    font-weight: 100;
    padding-right: 2rem;
  }
`;

interface ChecklistPanelProps extends Checklist {
  update: (update: Partial<Checklist>) => void;
}

export const ListPanel = ({
  createdAt,
  createdBy,
  description,
  name,
  update,
  updatedAt,
}: ChecklistPanelProps) => {
  return (
    <Container>
      <H3>List Details</H3>

      <HR />

      <Label>List Name</Label>
      <Input
        value={name}
        onChange={(ev) => {
          update({ name: ev.target.value });
        }}
      />

      <Label>Description</Label>
      <Textarea
        placeholder="Description here"
        value={description}
        onChange={(text) => {
          update({ description: text });
        }}
        theme={TextAreaTheme.Admin}
      />

      <HR className="mt-6" />

      <TABLE>
        <tbody>
          <tr>
            <td>Created by</td>
            <td>{createdBy}</td>
          </tr>
          <tr>
            <td>Created</td>
            <td>{moment(createdAt).format('M/D/YY h:mm a')}</td>
          </tr>
          <tr>
            <td>Modified</td>
            <td>{moment(updatedAt).format('M/D/YY h:mm a')}</td>
          </tr>
        </tbody>
      </TABLE>
    </Container>
  );
};

export default ListPanel;
