import {
  Options,
  Schema,
  validate as validateSchema,
  ValidationError,
  ValidatorResult,
} from 'jsonschema';

import { AdmixtureDosingForm, AdmixtureForm } from '../types';
import { fahrenheitToCelsius } from '../utils';

export const ambientTemperatureRangeMinimumLowDefined = 35;
export const ambientTemperatureRangeMinimumHighDefined = 110;

export const admixtureSchema: Schema = {
  type: 'object',
  title: 'Admixture Schema',
  properties: {
    key: {
      type: 'string',
      minLength: 1,
      required: true,
    },
    name: {
      type: 'string',
      minLength: 2,
      required: true,
    },
    density: {
      type: 'number',
      minimum: 0.01,
      maximum: 10,
      required: true,
    },
    enforcedPriority: {
      type: 'integer',
      minimum: 1,
      maximum: 6,
    },
  },
  additionalProperties: true,
};

const fixedDosingSchema: Schema = {
  type: 'object',
  properties: {
    type: {
      enum: ['FIXED'],
      required: true,
    },
    refBatchWeightInLbs: {
      type: 'number',
      minimum: 1,
      required: true,
    },
    volumeInMl: {
      type: 'number',
      minimum: 0,
      required: true,
    },
  },
};

const steppedDosingSchema: Schema = {
  type: 'object',
  properties: {
    type: {
      enum: ['STEPPED'],
      required: true,
    },
    refBatchWeightInLbs: {
      type: 'number',
      minimum: 1,
      required: true,
    },
    source: {
      type: 'string',
      required: true,
    },
    steps: {
      type: 'array',
      minItems: 1,
      items: {
        type: 'object',
        properties: {
          lowTempF: {
            type: 'number',
            required: true,
          },
          highTempF: {
            type: 'number',
            required: true,
          },
          volumeAtLowTempInMl: {
            type: 'number',
            required: true,
          },
          volumeAtHighTempInMl: {
            type: 'number',
            required: true,
          },
        },
      },
    },
  },
};

const validateDosing = (
  dosing?: AdmixtureDosingForm,
  options: Options = {},
): ValidationError[] => {
  if (dosing == null) {
    return [];
  }
  const basePropertyPath = options?.propertyName ?? 'instance';

  if (dosing.type === 'FIXED') {
    const { errors }: ValidatorResult = validateSchema(
      dosing,
      fixedDosingSchema,
      options,
    );
    return errors;
  }

  if (dosing.type === 'STEPPED') {
    const { errors }: ValidatorResult = validateSchema(
      dosing,
      steppedDosingSchema,
      options,
    );
    dosing.steps.forEach((step, index) => {
      if (
        step.lowTempF != null &&
        step.highTempF != null &&
        step.lowTempF >= step.highTempF
      ) {
        errors.push(
          new ValidationError(
            'Must be higher than low temperature.',
            dosing,
            undefined,
            `${basePropertyPath}.steps[${index}].highTempF`,
            'higherThan',
            step.highTempF,
          ),
        );
      }
    });

    if (
      dosing.source === 'ambient_temperature' &&
      dosing.steps.length > 0 &&
      dosing.steps[0].lowTempF != null &&
      dosing.steps[0].lowTempF > ambientTemperatureRangeMinimumLowDefined
    ) {
      const minLowCelsius = fahrenheitToCelsius(
        ambientTemperatureRangeMinimumLowDefined,
        2,
      );
      errors.push(
        new ValidationError(
          `Must be <= ${ambientTemperatureRangeMinimumLowDefined}\xB0F (${minLowCelsius}\xB0C)`,
          dosing,
          undefined,
          `${basePropertyPath}.steps[0].lowTempF`,
          'minimumRangeLow',
          dosing.steps[0].lowTempF,
        ),
      );
    }

    const lastStepIndex = dosing.steps.length - 1;
    if (
      dosing.source === 'ambient_temperature' &&
      dosing.steps.length > 0 &&
      dosing.steps[lastStepIndex].highTempF != null &&
      dosing.steps[lastStepIndex].highTempF <
        ambientTemperatureRangeMinimumHighDefined
    ) {
      const minHighCelsius = fahrenheitToCelsius(
        ambientTemperatureRangeMinimumHighDefined,
        2,
      );
      errors.push(
        new ValidationError(
          `Must be >= ${ambientTemperatureRangeMinimumHighDefined}\xB0F (${minHighCelsius}\xB0C)`,
          dosing,
          undefined,
          `${basePropertyPath}.steps[${lastStepIndex}].highTempF`,
          'minimumRangeHigh',
          dosing.steps[lastStepIndex].highTempF,
        ),
      );
    }

    return errors;
  }
  return [
    new ValidationError(
      'Unsupported type',
      dosing,
      undefined,
      `${basePropertyPath}.type`,
      'notSupported',
      undefined,
    ),
  ];
};

// Run jsonschema validate against the provided AdmixtureForm.
export const validate = (
  admixture: AdmixtureForm,
  existingKeys: Set<string>,
): ValidationError[] => {
  const { errors }: ValidatorResult = validateSchema(
    admixture,
    admixtureSchema,
  );
  if (admixture.key && existingKeys.has(admixture.key)) {
    errors.push(
      new ValidationError(
        'Key already exists',
        admixture,
        undefined,
        'instance.key',
        'duplicateKey',
        admixture.key,
      ),
    );
  }
  const dosingErrors = validateDosing(admixture.dosing, {
    propertyName: 'instance.dosing',
  });
  dosingErrors.forEach((e) => errors.push(e));

  return errors;
};

export const toErrorMessage = (validationError: ValidationError): string => {
  if (validationError.name === 'required') {
    return 'Required.';
  }
  if (validationError.name === 'minLength') {
    return `Must be at least ${validationError.argument} character(s) long.`;
  }
  if (validationError.name === 'maxLength') {
    return `Must be at most ${validationError.argument} character(s) long.`;
  }
  if (validationError.message) {
    return (
      validationError.message.charAt(0).toUpperCase() +
      validationError.message.slice(1)
    );
  }
  return 'Invalid value';
};
