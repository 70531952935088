import { Link } from 'gatsby';
import styled, { css, keyframes } from 'styled-components';
import React from 'react';
import colors from '@/constants/colors';

const Wrapper = styled.ul<{ ghosted: boolean }>`
  display: flex;
  height: 100%;
  margin: 0;
  opacity: ${({ ghosted }) => (ghosted ? 0.2 : 1)};
  padding: 0;
  transition: opacity 200ms;

  &:hover {
    opacity: 1;
  }
`;

interface ButtonProps {
  key: string | number;
  selected: boolean;
  dark?: boolean;
  display?: 'large' | 'marker';
  marker?: string | null;
}

export interface NavItem {
  label: string;
  selected: boolean;
  link: string | (() => void | Promise<void>);
  isGroup?: boolean;
  children?: NavItem[];
}

export interface ConfigProps {
  dark?: boolean;
  marker?: string | null;
  ghosted?: boolean;
  display?: 'large' | 'marker';
}

export interface TabsConfig extends ConfigProps {
  items: NavItem[];
}

const background = ({ selected, dark, display }: ButtonProps): string => {
  if (!selected || display === 'marker') return 'initial';
  return dark ? 'rgba(255, 255, 255, 0.1)' : 'rgba(0, 0, 0, 0.1)';
};

const menuTextColor = ({ dark }: ButtonProps) => {
  return dark ? colors.white : colors.gray['900'];
};

const menuContrastColor = ({ dark }: ButtonProps) => {
  return dark ? colors.gray['800'] : colors.gray['400'];
};

const menuBackgroundColor = ({ dark }: ConfigProps) => {
  return dark ? colors.gray['900'] : colors.white;
};

const buttonCSS = css<ButtonProps>`
  font-family: 'Montserrat', sans-serif;
  align-items: center;
  background: ${background};
  border-radius: ${({ display }) => (display === 'large' ? '4px' : 0)};
  box-sizing: border-box;
  color: ${(props) => menuTextColor(props)};
  display: flex;
  font-size: 1rem;
  font-weight: 400;
  height: ${({ display }) => (display === 'marker' ? '100%' : 'auto')};
  justify-content: center;
  outline: none;
  overflow: hidden;
  padding: 8px 16px;
  position: relative;
  text-transform: uppercase;
  transition: color 300ms;

  &:hover {
    color: ${({ dark }) => (dark ? colors.cyan['500'] : colors.cyan['500'])};
  }

  &::after {
    display: ${({ display, selected }) =>
      display === 'marker' && selected ? 'block' : 'none'};
    content: ' ';
    background: ${({ marker }) => marker};
    width: 10px;
    height: 10px;
    left: calc(50% - (14.14 / 2));
    bottom: -5px;
    transform: rotate(45deg);
    position: absolute;
  }
`;

const MenuButton = styled.button`
  ${buttonCSS}
`;

const StyledLink = styled(Link).withConfig({
  shouldForwardProp: (prop) =>
    !['display', 'dark', 'activeColor', 'selected'].includes(prop),
})`
  ${buttonCSS}
`;

const DropDownParentItem = styled.li`
  position: relative;

  div {
    display: none;
  }

  &:hover {
    div {
      display: block;
    }
  }
`;

const fadeInUp = keyframes`
  0% { 
    opacity: 0;
    transform: translate(-1.25rem, 0.25rem);
  }
  80% {
    opacity: 1;
  }
  100% {
    opacity: 1;
    transform: translate(-1.25rem, 0);
  }
`;

const DropDownMenuWrapper = styled.div<ButtonProps>`
  animation: ${fadeInUp} 350ms forwards;
  border-color: ${(props) => menuContrastColor(props)};
  border-radius: 4px;
  border-width: 1px;
  box-shadow: rgba(0, 0, 0, 0.176) 0px 6px 12px;
  left: 50%;
  min-width: 200px;
  position: absolute;
  top: 2.5rem;
  transform: translateX(-20px); // Scoot left so the arrow nub is in the middle
  z-index: 100;

  /* Add the triangle pointer nub */
  ::before {
    content: '';
    position: absolute;
    top: -10px;
    left: 0.6rem;
    color: red;
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom-width: 10px;
    border-bottom-style: solid;
    border-bottom-color: ${(props) => menuContrastColor(props)};
  }
`;

const DropDownListWrapper = styled.ul<ConfigProps>`
  background-color: ${(props) => menuBackgroundColor(props)};
  border-radius: 4px;
  border-width: 0px;
  display: block;
  height: 100%;
  margin: 0;
  opacity: 0.75;
  padding: 0;
  transition: opacity 200ms;

  li a {
    justify-content: normal;
    align-items: center;
    white-space: nowrap;
  }
`;

const GroupTab = (
  buttonProps: ButtonProps,
  label: string,
  children: NavItem[],
  tabsConfig: ConfigProps,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): any =>
  React.createElement(
    DropDownParentItem,
    {
      key: buttonProps.key,
    },
    [
      React.createElement(
        MenuButton,
        {
          ...buttonProps,
          key: `${buttonProps.key}-text`,
        },
        label,
      ),
      React.createElement(
        DropDownMenuWrapper,
        { ...buttonProps, key: `${buttonProps.key}-dropdown` },
        React.createElement(
          DropDownListWrapper,
          tabsConfig,
          children.map((child, i) =>
            // eslint-disable-next-line @typescript-eslint/no-use-before-define
            mapNavItem(child, `${buttonProps.key}-dropdown-${i}`, tabsConfig),
          ),
        ),
      ),
    ],
  );

const mapNavItem = (
  item: NavItem,
  key: string | number,
  tabsConfig: ConfigProps,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): any => {
  const buttonProps: ButtonProps = {
    key,
    selected: item.selected,
    display: tabsConfig.display,
    dark: tabsConfig.dark,
    marker: tabsConfig.marker,
  };
  if (item.isGroup) {
    return GroupTab(buttonProps, item.label, item.children || [], tabsConfig);
  }
  if (typeof item.link === 'string') {
    return React.createElement('li', { key }, [
      React.createElement(
        StyledLink,
        { ...buttonProps, to: item.link },
        item.label,
      ),
    ]);
  }
  return React.createElement(
    MenuButton,
    {
      ...buttonProps,
      className: 'focus:outline-none',
      onClick: item.link,
    },
    item.label,
  );
};

export const TabbedNav = (tabsConfig: TabsConfig) => (
  <Wrapper ghosted={tabsConfig.ghosted || false} data-testid="tabbed-nav">
    {tabsConfig.items.map((item, i) => mapNavItem(item, i, tabsConfig))}
  </Wrapper>
);

export default TabbedNav;
