import { useQuery } from '@apollo/client';
import React, { useState } from 'react';
import styled from 'styled-components';

import colors from '@/constants/colors';
import { GetProjectLabelsQuery, Project } from '@/generated/hasura.graphql';
import Loading from '@molecules/loading';
import Problem from '@molecules/problem';
import Select from '@/components/atoms/select';
import { ValueType } from 'react-select';
import { formatProjectLabel } from '@/admin/utils';
import { getProjectLabels as getProjectsQuery } from './queries';

type Item = { value: number; label: string; project?: Project };

const Container = styled.div`
  background-color: white;
  border-color: ${colors.gray['200']};
  border-width: 1px;
  border-radius: 4px;
  padding: 0 0 0 0.75rem;
  display: flex;
  align-items: center;

  > *:last-child {
    min-width: 8rem;
  }

  [class*='control'] {
    border-width: 0px;
  }
`;

const ProjectSelect = ({
  className,
  onChange,
}: {
  className?: string;
  onChange: (selectedProjects: Project[]) => void;
}) => {
  // Get printer data from database.
  const { data, loading, error } = useQuery<GetProjectLabelsQuery>(
    getProjectsQuery,
  );

  const [selectedProjects, setSelectedProjects] = useState<Project[]>([]);

  const selectChangeHandler = (selectedItems: ValueType<Item>) => {
    // The declared type for arg selectedItems is singular but the real type is
    // an array The typings for our Select atom (and the internal react-select
    // Select) are not comprehensive

    // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
    // @ts-ignore
    const projects = (selectedItems || []).map((x) => x.project);
    setSelectedProjects(projects);
    onChange(projects);
  };

  // Show loading or problem message if we don't have data yet.
  if (loading) return <Loading />;
  if (error || !data?.project) return <Problem />;

  return (
    <Container className={className}>
      <label className="text-sm uppercase font-semibold mr-2 text-gray-800">
        Project:
      </label>
      <Select
        isMulti
        isClearable
        onChange={selectChangeHandler}
        value={selectedProjects.map((x) => ({
          value: x.id,
          label: formatProjectLabel(x),
          project: x,
        }))}
        options={data.project.map((s) => ({
          value: s.id,
          label: formatProjectLabel(s),
          project: s,
        }))}
      />
    </Container>
  );
};

export default ProjectSelect;
