import * as Z from '@iconbuild/izzy';
import React from 'react';

import TableView from '@organisms/table-view';
import styled from 'styled-components';
import { extractPrintQuantities } from './material-quantities';
import { MaterialPlanningSettings } from './material-edit';

// Header for print quantities.
const Header = styled.h3`
  font-size: 1.125rem;
  font-weight: 500;
  padding: 0 1.25rem 0.5rem;
  width: calc(100% - 16px);

  * ~ & {
    margin-top: 2rem;
  }
`;

const ProjectMaterials = ({
  sitePlan = null,
  props,
}: {
  sitePlan: Z.SitePlan | null;
  props: MaterialPlanningSettings;
}) => {
  // Extract the print quantities from the site plan.
  const { totalPrintData, printDataByLevel } = extractPrintQuantities(
    sitePlan,
    props,
  );
  return (
    <>
      <Header>Total Project Print Quantities</Header>
      <TableView
        selected={-1}
        setSelected={() => null}
        data={{
          rows: totalPrintData,
          fields: [
            { ref: 'numberOfSupersacks', label: 'Supersacks' },
            { ref: 'numberOfBatches', label: 'Batches' },
            { ref: 'totalVolumeYards', label: 'Total Cubic Yards' },
            { ref: 'totalLinearFt', label: 'Total Linear (ft)' },
            { ref: 'totalTimeHours', label: 'Total Hours' },
            { ref: 'liftTimeMinutesMin', label: 'Min Lift Time (min)' },
            { ref: 'liftTimeMinutesMax', label: 'Max Lift Time (min)' },
            { ref: 'liftTimeAverage', label: 'Avg. Lift Time (min)' },
            { ref: 'dutyCycle', label: 'Duty Cycle (%)' },
          ],
        }}
      />
      <Header>Print Quantities by Level</Header>
      <TableView
        selected={-1}
        setSelected={() => null}
        data={{
          rows: printDataByLevel,
          fields: [
            { ref: 'startHeightInches', label: 'Start Height' },
            { ref: 'endHeightInches', label: 'End Height' },
            { ref: 'layers', label: 'Layers' },
            { ref: 'printInches', label: 'Linear In. / Layer' },
            { ref: 'layerTimeMinutes', label: 'Minutes / Layer' },
            { ref: 'printTimeHours', label: 'Total Hours' },
            { ref: 'cubicYards', label: 'Total Cubic Yards' },
            { ref: 'dutyCycle', label: 'Duty Cycle (%)' },
          ],
        }}
      />
    </>
  );
};

export default ProjectMaterials;
