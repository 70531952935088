import React, { useState, useEffect } from 'react';
import * as R from 'ramda';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import styled from 'styled-components';

import { SitePlan } from '@iconbuild/izzy';
import { SitePlan3D } from '@iconbuild/hubble';

import Button from '@/components/atoms/button';
import { toastSuccessOptions } from '@/constants/toast';
import { SizeTheme } from '@/types';

const OuterContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const ControlsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem;
`;

const ProgressWrapper = styled.div`
  display: inline-flex;
  align-items: center;
`;

const LayerHeight = styled.span`
  display: inline-block;
  font-weight: 600;
  margin-left: 0.75rem;
`;

// TODO(Ganser) export SitePlanActions interface from Hubble.
interface SitePlanActions {
  downloadObj: (filename?: string) => void;
}

const PlanPreview3D = ({
  plan,
  title = 'OBJFile',
}: {
  plan: SitePlan | null;
  title: string;
}) => {
  const [maxHeight, setMaxHeight] = useState<number>();
  const [progress, setProgress] = useState(1.0);
  const [showFoundation, setShowFoundation] = useState(true);
  const [actions, setActions] = useState<SitePlanActions>();

  const printHeight = (): null | number => {
    if (!maxHeight) return null;
    return Math.floor(maxHeight * progress);
  };

  useEffect(() => {
    // Calculate Max Height for Printed Structure.
    const heights = R.flatten(
      (plan?.items || []).map((item) => {
        return item.buildPlan.actions.map((action) => action.endHeightInches);
      }),
    );
    setMaxHeight(Math.max(...heights));
  }, []);

  const download = () => {
    const downloadPromise = new Promise((resolve) =>
      setTimeout(() => {
        const result = actions?.downloadObj(`${title}.obj`);
        resolve(result);
      }, 50),
    );
    toast.promise(
      downloadPromise,
      {
        pending: 'Download started...',
        success: `Downloaded ${title}.obj`,
        error: `Failed to download ${title}.obj`,
      },
      toastSuccessOptions,
    );
    return downloadPromise;
  };

  return (
    <OuterContainer>
      <ControlsContainer>
        <ProgressWrapper>
          <input
            type="range"
            step={0.005}
            min={0.0}
            max={1.0}
            value={progress}
            onChange={(e) => setProgress(parseFloat(e.target.value))}
          />
          <LayerHeight>{maxHeight ? printHeight() : null}</LayerHeight>
        </ProgressWrapper>
        <div>
          <input
            type="checkbox"
            checked={showFoundation}
            onChange={(e) => setShowFoundation(e.target.checked)}
            style={{ marginRight: '0.25rem' }}
          />
          Foundation
        </div>
        <Button sizeTheme={SizeTheme.Small} onClick={download}>
          Download OBJ File
        </Button>
      </ControlsContainer>
      {plan && (
        <SitePlan3D
          sitePlan={plan}
          progress={progress}
          showFoundation={showFoundation}
          setActions={setActions}
          minHeight="600px"
          beadHeightInches={0.75}
        />
      )}
      <ToastContainer />
    </OuterContainer>
  );
};

export default PlanPreview3D;
