import gql from 'graphql-tag';

export const SassCalFields = gql`
  fragment SassCalFields on sass_factory_calibration {
    camera_serial
    calibration_data
    created
    updated
    username
  }
`;
