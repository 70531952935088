import React from 'react';
import styled from 'styled-components';

import { cyan } from '@data/colors';
import { admin as adminNav } from '@data/nav';
import ListNav from '@molecules/nav-list';

const Menu = styled.div`
  background: #333;
`;

const MenuLabel = styled.div`
  padding: 0 32px;
  height: 64px;
  display: flex;
  align-items: center;
  background: ${cyan};
  font-size: 24px;
  text-transform: uppercase;
  font-family: 'Montserrat', sans-serif;
`;

const App = ({ section }: { section: string }) => (
  <Menu>
    <MenuLabel>Admin</MenuLabel>
    <ListNav
      dark
      activeColor={cyan}
      display="sidebar"
      items={adminNav.map((i) => ({
        ...i,
        selected: i.link === `/${section}`,
      }))}
    />
  </Menu>
);

export default App;
