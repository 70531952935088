import styled from 'styled-components';

// Section label for use in page or column, like the admin side bar.
export const SectionLabel = styled.div`
  overflow-wrap: break-word;
  font-weight: 500;
  font-size: 20px;
  padding-bottom: 8px;
  border-bottom: 1px solid #ccc;
  margin-bottom: 16px;
  font-family: 'Montserrat', sans-serif;
`;

export default SectionLabel;
