import React from 'react';
import { Router, RouteComponentProps } from '@reach/router';

import EditProject from './project-edit/project-edit';
import ProjectList from './project-list';

const Route = ({
  component,
}: RouteComponentProps & { component: React.ReactElement }) => component;

const ProjectsRouter = () => {
  return (
    <Router>
      <EditProject path="/:id/*" />
      <Route path="/" component={<ProjectList />} />
    </Router>
  );
};

export default ProjectsRouter;
