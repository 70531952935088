import { Link } from 'gatsby';
import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { darkGray, gradient } from '@data/colors';
import { Theme } from '@/types';
import { useAuth } from '@providers/auth';
import breakpoints from '@data/breakpoints';
import Hamburger from '@atoms/hamburger';
import { header, padX } from '@/constants/layout';
import Logo from '@atoms/logo';
import MobileNav from '@molecules/nav-mobile';
import screens from '@/constants/screens';
import AccountMenu from '../account-menu';

export enum HeaderBackgroundColor {
  Gray = 'gray',
  Transparent = 'transparent',
}

const applyTheme = ({ theme }: { theme: Theme }) => {
  if (theme === Theme.Content) {
    return css`
      left: 50%;
      max-width: 1680px;
      position: absolute;
      transform: translateX(-50%);
      width: 100%;
    `;
  }

  return '';
};

const background = ({
  theme,
  backgroundColor,
}: {
  theme: Theme;
  backgroundColor?: HeaderBackgroundColor;
}) => {
  // look at backgroundColor first, then theme, else default to darkGray.
  if (backgroundColor === HeaderBackgroundColor.Gray) {
    return darkGray;
  }
  if (backgroundColor === HeaderBackgroundColor.Transparent) {
    return 'transparent';
  }
  if (theme === Theme.Content) {
    return 'transparent';
  }
  return darkGray;
};

const Container = styled.header<{
  theme: Theme;
  backgroundColor?: HeaderBackgroundColor;
}>`
  align-items: center;
  background: ${background};
  display: flex;
  font-family: 'Montserrat', sans-serif;
  font-weight: 400;
  height: ${header.height};
  left: 0;
  letter-spacing: 0.015em;
  padding: ${header.paddingY} ${padX.default};
  right: 0;
  top: 0;
  z-index: 101;
  ${applyTheme}

  @media (min-width: ${screens.md}) {
    padding: 16px ${padX.md};
  }
`;

const LogoWrapper = styled.div`
  position: relative;
  top: -2px;
  margin-right: 40px;
`;

const AccountWrapper = styled.div`
  margin-left: auto;
`;

const NonMobileElements = styled.div<{ navOpen: boolean }>`
  align-items: center;
  display: ${({ navOpen }) => (navOpen ? 'none' : 'flex')};
  width: 100%;
  @media (max-width: ${breakpoints[3]}px) {
    display: none;
  }
`;

const MobileElements = styled.div<{ navOpen: boolean }>`
  margin-left: auto;
  @media (min-width: ${breakpoints[3] + 1}px) {
    display: ${({ navOpen }) => (navOpen ? 'auto' : 'none')};
  }
`;

const backdropTheme = ({ theme }: { theme: Theme }) => css`
  top: ${theme === Theme.Content ? '0' : '4.5rem'};
`;

const Backdrop = styled.div<{ active: boolean; theme: Theme }>`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.8);
  display: ${({ active }) => (active ? 'block' : 'none')};
  z-index: 98;
  ${backdropTheme}
`;

const Header = ({
  extraNav,
  theme = Theme.UI,
  backgroundColor,
}: {
  extraNav: React.ReactNode;
  theme?: Theme;
  backgroundColor?: HeaderBackgroundColor;
}) => {
  // State to keep track of whether the mobile is open or not.
  const [navOpen, setNavOpen] = useState(false);

  // Get auth context.
  const auth = useAuth();

  return (
    <div className="relative">
      <Container
        theme={theme}
        backgroundColor={backgroundColor}
        className="flex justify-between"
      >
        <LogoWrapper>
          <Link to="/">
            <Logo height={36} color={gradient} />
          </Link>
        </LogoWrapper>
        <div className="ml-auto">
          <NonMobileElements navOpen={navOpen}>
            <AccountWrapper>
              <AccountMenu />
            </AccountWrapper>
          </NonMobileElements>
          <MobileElements navOpen={navOpen}>
            <Hamburger
              active={navOpen}
              onClick={() => setNavOpen(!navOpen)}
              theme={theme}
            />
          </MobileElements>
        </div>
      </Container>
      <Backdrop
        onClick={() => setNavOpen(false)}
        active={navOpen}
        theme={theme}
      />
      <MobileNav
        active={navOpen}
        auth={auth}
        extraNav={extraNav}
        theme={theme}
      />
    </div>
  );
};

export default Header;
