import React from 'react';
import styled from 'styled-components';
import colors from '@/constants/colors';

const Container = styled.div`
  position: relative;
  width: 75px;
  display: inline-block;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  text-align: left;

  .toggle-switch-checkbox {
    display: none;
  }

  label {
    display: block;
    overflow: hidden;
    cursor: pointer;
    border: 0 solid #bbb;
    border-radius: 20px;
    margin: 0;
  }

  .toggle-switch-inner {
    display: block;
    width: 200%;
    margin-left: -100%;
    transition: margin 0.2s ease-in 0s;
  }
  .toggle-switch-inner:before,
  .toggle-switch-inner:after {
    display: block;
    float: left;
    width: 50%;
    height: 34px;
    padding: 0;
    line-height: 34px;
    font-size: 14px;
    color: white;
    font-weight: bold;
    box-sizing: border-box;
  }
  .toggle-switch-inner:before {
    content: attr(data-yes);
    text-transform: uppercase;
    padding-left: 10px;
    background-color: ${colors.cyan['400']};
    color: #fff;
  }
  .toggle-switch-inner:after {
    content: attr(data-no);
    text-transform: uppercase;
    padding-right: 10px;
    background-color: #bbb;
    color: #fff;
    text-align: right;
  }

  .toggle-switch-switch {
    display: block;
    width: 24px;
    margin: 5px;
    background: #fff;
    position: absolute;
    top: 0;
    bottom: 0;
    right: calc(100% - 35px);
    border: 0 solid #bbb;
    border-radius: 20px;
    transition: all 0.2s ease-in 0s;
  }

  .toggle-switch-checkbox:checked + .toggle-switch-label {
    .toggle-switch-inner {
      margin-left: 0;
    }
    .toggle-switch-switch {
      right: 0;
    }
  }
`;

interface ToggleSwitchProps {
  id: string;
  checked?: boolean;
  onChange?: (value: boolean) => void;
  name?: string;
  yesLabel?: string;
  noLabel?: string;
  disabled?: boolean;
  width?: string;
}

export const ToggleSwitch = ({
  id,
  checked = false,
  onChange,
  name,
  yesLabel = 'Yes',
  noLabel = 'No',
  disabled = false,
  width = '75px',
}: ToggleSwitchProps) => {
  const onInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.checked;
    if (onChange) {
      onChange(value);
    }
  };

  return (
    <Container style={{ width }}>
      <input
        type="checkbox"
        className="toggle-switch-checkbox"
        name={name || id}
        id={id}
        checked={checked}
        onChange={onInputChange}
        disabled={disabled}
      />
      <label className="toggle-switch-label" htmlFor={id}>
        <span
          className="toggle-switch-inner"
          data-yes={yesLabel}
          data-no={noLabel}
        />
        <span className="toggle-switch-switch" />
      </label>
    </Container>
  );
};

export default ToggleSwitch;
