import React, { useState } from 'react';
import styled from 'styled-components';

import colors from '@/constants/colors';

const inputStyles = `
  border-radius: 0.25rem;
  border: 1px solid ${colors.gray['300']};
  color: hsl(0,0%,20%);
  display: block;
  font-weight: 300;
  padding: 6px 10px;
  transition: border 250ms;
  width: 100%;

  &:focus {
    border-color: ${colors.cyan['400']};
  }
`;

interface InputProps {
  className?: string;
  placeholder?: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  value?: string | number;
  type?: string;
  step?: number;
}

const Input = styled.input`
  ${inputStyles}
`;

export const StyledInput = (props: InputProps) => {
  const [currentValue, setCurrentValue] = useState<string | number>(
    props.value || '',
  );

  return (
    <Input
      {...props}
      value={currentValue}
      onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
        setCurrentValue(event.target.value);
        props.onChange(event);
      }}
    />
  );
};

export default StyledInput;
