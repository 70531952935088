import React from 'react';
import styled from 'styled-components';

const Backdrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.7);
  z-index: 1000;
`;

const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 1001;
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: none;
`;

const Content = styled.div`
  background: white;
  border-radius: 4px;
  box-shadow: rgba(33, 33, 33, 0.15) 0px 4px 8px 3px;
  overflow: hidden;
  pointer-events: auto;
`;

const TitleBar = styled.div`
  background: #eee;
  font-weight: 600;
  padding: 16px 64px 16px 16px;
  position: relative;
`;

const PrimaryContent = styled.div`
  max-height: 80vh;
  overflow: scroll;
`;

const CloseButton = styled.button`
  position: absolute;
  right: 8px;
  top: 8px;
  padding: 8px;
  color: #999;
`;

export const Lightbox = ({
  children,
  close,
  title,
}: {
  children: React.ReactNode;
  title: string;
  close: () => void;
}) => {
  return (
    <>
      <Container>
        <Content>
          <TitleBar>
            {title}
            <CloseButton type="button" onClick={close}>
              <i className="icon-cancel" />
            </CloseButton>
          </TitleBar>
          <PrimaryContent>{children}</PrimaryContent>
        </Content>
      </Container>
      <Backdrop onClick={close} />
    </>
  );
};

export default Lightbox;
