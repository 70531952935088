import { useQuery } from '@apollo/client';
import React, { useState } from 'react';
import styled from 'styled-components';

import colors from '@/constants/colors';
import { GetSystemsQuery, System } from '@/generated/hasura.graphql';
import Loading from '@molecules/loading';
import Problem from '@molecules/problem';
import Select from '@/components/atoms/select';
import { ValueType } from 'react-select';
import { formatSystemLabel } from '@/admin/utils';
import { getSystems as getSystemsQuery } from './queries';

type Item = { value: number; label: string; system?: System };

const Container = styled.div`
  background-color: white;
  border-color: ${colors.gray['200']};
  border-width: 1px;
  border-radius: 4px;
  padding: 0 0 0 0.75rem;
  display: flex;
  align-items: center;

  > *:last-child {
    min-width: 8rem;
  }

  [class*='control'] {
    border-width: 0px;
  }
`;

const sortSerials = (a: string, b: string) => {
  try {
    if (!a.startsWith('2')) {
      return 1;
    }

    if (!b.startsWith('2')) {
      return -1;
    }

    const aSplit = a.split('.');
    const bSplit = b.split('.');
    const aMajor = Number(aSplit[0]) || 0;
    const bMajor = Number(bSplit[0]) || 0;
    const aMinor = Number(aSplit[1]) || 0;
    const bMinor = Number(bSplit[1]) || 0;
    const aPatch = Number(aSplit[2]) || 0;
    const bPatch = Number(bSplit[2]) || 0;

    if (aMajor > bMajor) {
      return 1;
    }
    if (aMajor < bMajor) {
      return -1;
    }
    if (aMinor > bMinor) {
      return 1;
    }
    if (aMinor < bMinor) {
      return -1;
    }
    if (aPatch > bPatch) {
      return 1;
    }
    if (aPatch < bPatch) {
      return -1;
    }
    return 0;
  } catch (_) {
    return 0;
  }
};

const SystemSelect = ({
  className,
  onChange,
}: {
  className?: string;
  onChange: (selectedSystems: System[]) => void;
}) => {
  // Get printer data from database.
  const { data, loading, error } = useQuery<GetSystemsQuery>(getSystemsQuery);

  const [selectedSystems, setSelectedSystems] = useState<System[]>([]);

  const selectChangeHandler = (selectedItems: ValueType<Item>) => {
    // The declared type for arg selectedItems is singular but the real type is
    // an array The typings for our Select atom (and the internal react-select
    // Select) are not comprehensive
    const systems = (selectedItems || []).map((x) => x.system);
    setSelectedSystems(systems);
    onChange(systems);
  };

  // Show loading or problem message if we don't have data yet.
  if (loading) return <Loading />;
  if (error || !data?.system) return <Problem />;

  const sorted = Array.from(data.system ?? []).sort((a, b) => {
    return sortSerials(a.serial, b.serial);
  });

  return (
    <Container className={className}>
      <label className="text-sm uppercase font-semibold mr-2 text-gray-800">
        System:
      </label>
      <Select
        isMulti
        isClearable
        onChange={selectChangeHandler}
        value={selectedSystems.map((x) => ({
          value: x.id,
          label: formatSystemLabel(x),
          system: x,
        }))}
        options={sorted.map((s) => ({
          value: s.id,
          label: formatSystemLabel(s),
          system: s,
        }))}
      />
    </Container>
  );
};

export default SystemSelect;
