import React from 'react';
import styled from 'styled-components';
import Button from '@atoms/button';

const Container = styled.div`
  padding: 16px;
  min-width: 400px;
`;

const Buttons = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 32px;
  button {
    margin-left: 8px;
    &:first-child {
      margin-left: 0;
    }
  }
`;

const AlertMessage = ({
  onClick,
  message,
}: {
  onClick: () => void;
  message: string;
}) => {
  return (
    <Container>
      <div>{message}</div>
      <Buttons>
        <Button onClick={onClick}>OK</Button>
      </Buttons>
    </Container>
  );
};

export default AlertMessage;
