import { move, insert } from 'ramda';
import React, { useState } from 'react';
import {
  SortableContainer,
  SortableElement,
  SortEnd,
} from 'react-sortable-hoc';
import styled from 'styled-components';

import { useAuth } from '@providers/auth';
import colors from '@/constants/colors';
import CirclePlusSvg from '@/components/icons/circle-plus';
import DragHandle from '@atoms/drag-handle';

import { nanoid } from 'nanoid';
import { ChecklistItem } from '../types';
import { generateChecklistItem } from '../calculations';
import ChecklistActions from '../actions';
import ChecklistButton from '../button';
import Item from '../item';

const ChecklistItemsContainer = styled.div`
  &.sorting {
    > div {
      transition: border-color 350ms, transform 350ms;
    }
  }
`;

const Name = styled.h3`
  color: ${colors.black};
  font-family: Roboto;
  font-size: 1rem;
  font-weight: 300;
  line-height: 1.25em;
  user-select: none;
`;

const SortableItemContainer = styled.div`
  background-color: ${colors.white};
  border: 1px solid ${colors.white};
  margin-bottom: 1rem;
  position: relative;
  transition: border-color 250ms;

  &:hover,
  &.active {
    border-color: ${colors.cyan['500']};
  }

  &.nested {
    .checklist-item {
      background-color: ${colors.gray['300']};
    }
  }

  &.archived {
    &::after {
      background-image: repeating-linear-gradient(
        45deg,
        transparent,
        transparent 10px,
        rgba(200, 200, 200, 0.35) 10px,
        rgba(200, 200, 200, 0.35) 12px
      );
      background-size: auto auto;
      border: 1px solid #ccc;
      bottom: 0;
      content: '';
      display: block;
      left: 0;
      pointer-events: none;
      position: absolute;
      right: 0;
      top: 0;
    }
  }
`;

const NestedListContainer = styled.div`
  padding: 0 1rem 1rem 1rem;
  margin-bottom: 1rem;

  &:last-child {
    margin-bottom: 0;
  }
`;

export const Sortable = ({
  items,
  setItems,
  selectedItem,
  setSelectedItem,
  updateItem,
}: {
  items: ChecklistItem[];
  setItems: (items: ChecklistItem[]) => void;
  selectedItem: ChecklistItem | null;
  setSelectedItem: (item: ChecklistItem | null) => void;
  updateItem: (item: Partial<ChecklistItem>, id?: string) => void;
}) => {
  const [isSorting, setIsSorting] = useState<boolean>(false);

  // Get auth context.
  const auth = useAuth();
  const userName = auth?.user ? auth.user.name : 'N/A';

  const onSortEnd = ({ oldIndex, newIndex }: SortEnd) => {
    setIsSorting(false);
    setItems(move(oldIndex, newIndex, items));
  };

  const onNestedSortEnd = (itemIndex: number) => ({
    oldIndex,
    newIndex,
  }: SortEnd) => {
    setIsSorting(false);
    const nestedItemsSorted = move(
      oldIndex,
      newIndex,
      items[itemIndex].items || [],
    );

    setItems(
      items.map((item, index) => {
        if (index !== itemIndex) return item;

        return {
          ...item,
          items: nestedItemsSorted,
        };
      }),
    );
  };

  const handleItemSelect = (item: ChecklistItem) => () => {
    if (selectedItem?.id === item.id) {
      setSelectedItem(null);
      return;
    }

    setSelectedItem(item);
  };

  const SortableItem = SortableElement(
    ({
      item,
      children,
      className = '',
    }: {
      item: ChecklistItem;
      children?: React.ReactNode | React.ReactNode[];
      className?: string;
    }) => {
      const getContainerClassName = () => {
        const classNames = [className];
        if (item.id === selectedItem?.id) classNames.push('active');
        if (item.archived) classNames.push('archived');
        return classNames.join(' ');
      };

      const removeChecklistItemById = (
        id: string,
        checklistItems: ChecklistItem[],
      ): ChecklistItem[] => {
        return checklistItems
          .filter((i) => i.id !== id)
          .map((i) => {
            return {
              ...i,
              items: removeChecklistItemById(id, i.items),
            };
          });
      };

      const archiveChecklistItemById = (
        id: string,
        checklistItems: ChecklistItem[],
      ): ChecklistItem[] => {
        return checklistItems.map((i) => {
          return {
            ...i,
            archived: i.id === id ? true : i.archived,
            items: archiveChecklistItemById(id, i.items),
          };
        });
      };

      const restoreChecklistItemById = (
        id: string,
        checklistItems: ChecklistItem[],
      ): ChecklistItem[] => {
        return checklistItems.map((i) => {
          return {
            ...i,
            archived: i.id === id ? false : i.archived,
            items: restoreChecklistItemById(id, i.items),
          };
        });
      };

      const duplicateChecklistItemById = (
        id: string,
        checklistItems: ChecklistItem[],
      ): ChecklistItem[] => {
        const foundIndex = checklistItems.findIndex((i) => {
          return i.id === id;
        });

        if (foundIndex >= 0) {
          const duplicateItem = {
            ...checklistItems[foundIndex],
            name: `${checklistItems[foundIndex].name} (COPY)`,
            id: nanoid(),
          };

          return insert(foundIndex + 1, duplicateItem, checklistItems);
        }

        return checklistItems.map((i) => {
          return {
            ...i,
            items: duplicateChecklistItemById(id, i.items),
          };
        });
      };

      return (
        <SortableItemContainer className={getContainerClassName()}>
          <Item key={`item-${item.id}`} onClick={handleItemSelect(item)}>
            <span className="flex items-center">
              <DragHandle />
              <Name>{item.name}</Name>
            </span>
            <span className="whitespace-no-wrap overflow-ellipsis">
              {item.systems.map((system) => system.serial).join(', ')}
            </span>
            <span>{item.assigned.map((a) => a.name).join(', ')}</span>
            <ChecklistActions
              actions={[
                {
                  label: 'Duplicate',
                  icon: 'duplicate',
                  action: () => {
                    setItems(duplicateChecklistItemById(item.id, items));
                  },
                },
                {
                  label: 'Delete',
                  icon: 'trash',
                  action: () => {
                    setItems(removeChecklistItemById(item.id, items));
                  },
                },
                {
                  label: item.archived ? 'Restore' : 'Archive',
                  icon: 'archive',
                  action: () => {
                    if (item.archived) {
                      setItems(restoreChecklistItemById(item.id, items));
                      return;
                    }

                    setItems(archiveChecklistItemById(item.id, items));
                  },
                },
              ]}
            />
          </Item>
          {children ? (
            <NestedListContainer>{children}</NestedListContainer>
          ) : null}
        </SortableItemContainer>
      );
    },
  );

  const SortableList = SortableContainer(
    ({
      className = '',
      isNested = false,
      sortableItems,
    }: {
      className?: string;
      isNested?: boolean;
      sortableItems: ChecklistItem[];
    }) => {
      return (
        <ChecklistItemsContainer className={className}>
          {sortableItems.map((item, index) => {
            if (item.isGroup) {
              return (
                <SortableItem
                  key={`sortable-${item.id}`}
                  index={index}
                  item={item}
                  className={isNested ? 'nested' : ''}
                >
                  <SortableList
                    className={isSorting ? 'sorting' : ''}
                    sortableItems={item.items}
                    onSortStart={() => setIsSorting(true)}
                    onSortEnd={onNestedSortEnd(index)}
                    useDragHandle
                    isNested
                    key={`nested-sortable-${item.id}`}
                  />
                  <ChecklistButton
                    className="mt-4"
                    onClick={() => {
                      updateItem(
                        {
                          items: [
                            ...item.items,
                            generateChecklistItem(userName)('New'),
                          ],
                        },
                        item.id,
                      );
                    }}
                    leading={<CirclePlusSvg />}
                  >
                    SUB ITEM
                  </ChecklistButton>
                </SortableItem>
              );
            }

            return (
              <SortableItem
                key={`sortable-${item.id}`}
                index={index}
                item={item}
                className={isNested ? 'nested' : ''}
              />
            );
          })}
        </ChecklistItemsContainer>
      );
    },
  );

  return (
    <SortableList
      className={isSorting ? 'sorting' : ''}
      sortableItems={items}
      onSortStart={() => setIsSorting(true)}
      onSortEnd={onSortEnd}
      useDragHandle
    />
  );
};

export default Sortable;
