/* eslint-disable react/jsx-curly-newline */
import * as Z from '@iconbuild/izzy';
import React from 'react';
import styled from 'styled-components';
import GridRow from '@molecules/grid-row';
import SectionLabel from '@atoms/section-label';
import { NumberDataField } from '@molecules/data-field';

const Row = styled.div`
  margin: 16px 0;
`;

export interface MaterialPlanningSettings {
  startHeightInches: number;
  endHeightInches: number;
  printSpeedInchesPerSecond: number;
  travelSpeedInchesPerSecond: number;
  volumePerSupersack: number;
  averageBatchWeight: number;
  layerHeightInches: number;
}

export const EditMaterialSpeeds = ({
  sitePlan = null,
  props,
  updateMaterialPlanningSettings,
}: {
  sitePlan: Z.SitePlan | null;
  props: MaterialPlanningSettings;
  updateMaterialPlanningSettings: (prop: MaterialPlanningSettings) => void;
}) => {
  if (!sitePlan) return null;

  const setField = (key: string, value: number) =>
    updateMaterialPlanningSettings({ ...props, [key]: value });

  return (
    <>
      <SectionLabel>Material Estimate Inputs</SectionLabel>
      <Row>
        <GridRow spacing={32} breakpoint={Infinity}>
          <NumberDataField
            label="Start Height (in)"
            value={props.startHeightInches}
            setValue={(value: number) => setField('startHeightInches', value)}
          />
          <NumberDataField
            label="End Height (in)"
            value={props.endHeightInches}
            setValue={(value: number) => setField('endHeightInches', value)}
          />
        </GridRow>
      </Row>
      <Row>
        <GridRow spacing={32} breakpoint={Infinity}>
          <NumberDataField
            label="Print Speed (in/s)"
            value={props.printSpeedInchesPerSecond}
            setValue={(value: number) =>
              setField('printSpeedInchesPerSecond', value)
            }
          />
          <NumberDataField
            label="Travel Speed (in/s)"
            value={props.travelSpeedInchesPerSecond}
            setValue={(value: number) =>
              setField('travelSpeedInchesPerSecond', value)
            }
          />
        </GridRow>
      </Row>
      <Row>
        <NumberDataField
          label="Supersack Volume (cubic yards)"
          value={props.volumePerSupersack}
          setValue={(value: number) => setField('volumePerSupersack', value)}
        />
      </Row>
      <Row>
        <NumberDataField
          label="Batch Weight (lbs)"
          value={props.averageBatchWeight}
          setValue={(value: number) => setField('averageBatchWeight', value)}
        />
      </Row>
      <Row>
        <NumberDataField
          label="Bead Height (in)"
          value={props.layerHeightInches}
          setValue={(value: number) =>
            setField('layerHeightInches', value || 0.75)
          }
        />
      </Row>
    </>
  );
};
