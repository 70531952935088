import DateRangePicker from '@wojtekmaj/react-daterange-picker';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import CloseSvg from '@components/icons/close';

import colors from '@/constants/colors';

interface DateRangeProps {
  className?: string;
  onChange: (dateRange: [Date | null, Date | null]) => void;
  value: [Date | null, Date | null];
}

const IconContainer = styled.span`
  svg {
    color: ${colors.gray['700']};
    height: 0.65em;
    margin-left: 0.5rem;
  }
`;

const Container = styled.div`
  &,
  .react-calendar {
    background-color: white;
    border-color: ${colors.gray['200']};
    border-width: 1px;
    border-radius: 4px;
    padding: 0 0.75rem;
  }

  .react-daterange-picker__wrapper {
    border: none;
  }

  .react-daterange-picker__wrapper {
    padding: 3px 0;

    > * {
      text-align: center;
    }
  }

  .react-daterange-picker__range-divider {
    margin: 0 0.25rem;
  }

  .react-calendar {
    margin-top: 0.25rem;

    abbr[title] {
      text-decoration: none;
    }
  }

  button:focus {
    outline: none;
  }

  .react-calendar__navigation {
    > * {
      text-align: center;
    }
  }

  .react-calendar__tile--now {
    background-color: transparent;

    &:not(.react-calendar__tile--hover):not(.react-calendar__tile--range) {
      position: relative;

      &::after {
        background-color: ${colors.cyan['500']};
        bottom: 0;
        content: '';
        display: block;
        height: 2px;
        left: 0;
        position: absolute;
        right: 0;
      }
    }
  }

  .react-calendar__month-view__days__day,
  .react-calendar__month-view__days__day--weekend {
    color: ${colors.gray['800']};
  }

  .react-calendar__tile {
    &.react-calendar__tile--hover,
    &.react-calendar__tile--range {
      color: white;
    }

    &.react-calendar__tile--range {
      background-color: ${colors.cyan['500']};
    }

    &:hover,
    &.react-calendar__tile--hover {
      background-color: ${colors.pink['500']};
    }
  }

  .react-calendar__tile--rangeStart,
  .react-calendar__tile--hoverStart {
    border-top-left-radius: 777px;
    border-bottom-left-radius: 777px;

    &.react-calendar__tile--hover:not(.react-calendar__tile--hoverStart),
    &.react-calendar__tile--range:not(.react-calendar__tile--rangeStart) {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
  }

  .react-calendar__tile--rangeEnd,
  .react-calendar__tile--hoverEnd {
    border-top-right-radius: 777px;
    border-bottom-right-radius: 777px;

    &.react-calendar__tile--hover:not(.react-calendar__tile--hoverEnd),
    &.react-calendar__tile--range:not(.react-calendar__tile--rangeEnd) {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
  }
`;

export const DateRange = ({
  className = '',
  onChange,
  value,
}: DateRangeProps) => {
  // Set Datepicker Default Date to Today.
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);

  const handleDateRangeChange = (val: [Date, Date]) => {
    // Clear / Reset date range.
    if (!Array.isArray(val)) {
      setStartDate(null);
      setEndDate(null);
      return;
    }

    // Assign start / end dates based on date range value.
    const [start, end] = val;
    setStartDate(start);
    setEndDate(end);
  };

  // Emit onChange event to parent component.
  useEffect(() => {
    onChange([startDate, endDate]);
  }, [startDate, endDate]);

  return (
    <Container className={className}>
      <label className="text-sm uppercase font-semibold mr-2 text-gray-800">
        Date Range:
      </label>
      <DateRangePicker
        calendarIcon={null}
        clearIcon={
          // eslint-disable-next-line react/jsx-wrap-multilines
          <IconContainer>
            <CloseSvg />
          </IconContainer>
        }
        onChange={handleDateRangeChange}
        value={value}
      />
    </Container>
  );
};

export default DateRange;
