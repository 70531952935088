import React from 'react';
import styled from 'styled-components';

import colors from '@/constants/colors';

const Container = styled.div`
  align-items: center;
  background-color: ${colors.white};
  border: 1px solid white;
  color: ${colors.black};
  display: flex;
  font-family: Roboto;
  font-size: 1rem;
  font-style: normal;
  font-weight: 300;
  line-height: 1.25em;
  min-height: 3.625rem;
  padding-left: 1.5rem;
  transition: border-color 350ms;

  &:hover,
  &.active {
    border-color: ${colors.cyan['500']};
  }

  > *:last-child {
    margin-left: auto;
  }

  > *:not(:last-child) {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;

export const Checklist = ({
  children,
  className = '',
  onClick,
}: {
  children: React.ReactNode | React.ReactNode[];
  className?: string;
  onClick?: React.MouseEventHandler;
}) => {
  if (onClick) {
    return (
      <Container className={`checklist-item ${className}`} onClick={onClick}>
        {children}
      </Container>
    );
  }

  return <Container className="checklist-item">{children}</Container>;
};

export default Checklist;
