import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import styled from 'styled-components';

const Container = styled.div`
  border: 1px solid #333;
  margin-bottom: 1rem;
  padding: 2rem;
`;

const SVG = styled.svg<{ hidePath: boolean; yReflect: boolean }>`
  margin-bottom: 2px;
  max-height: 20rem;
  overflow: visible;
  transform: ${({ yReflect }) => (yReflect ? 'scale(1, -1)' : 'scale(1, 1)')};

  path {
    opacity: ${({ hidePath }) => (hidePath ? '0' : '1')};
  }
`;

const pathStyles = {
  fill: 'none',
  strokeLineCap: 'round',
  strokeLineJoin: 'round',
  strokeWidth: '2.5px',
  stroke: 'rgba(110, 110, 110, 0.75)',
};

export interface BoundingBox {
  x: number;
  y: number;
  height: number;
  width: number;
}

// The PathPreview component renders an SVG preview of a path.
export const PathPreview = ({
  path,
  viewBox,
  yReflect = true,
}: {
  path: string | null | undefined;
  viewBox?: string;
  yReflect?: boolean;
}) => {
  const svgRef = useRef<SVGSVGElement>(null);
  const [hidePath, setHidePath] = useState<boolean>(true);
  const [bbox, setBbox] = useState<BoundingBox>({
    x: 0,
    y: 0,
    height: 1000,
    width: 300,
  });

  useEffect(() => {
    try {
      const { x, y, height, width } = svgRef.current?.getBBox() || {
        x: 0,
        y: 0,
        height: 0,
        width: 0,
      };
      setBbox({ x, y, height: height || 1, width: width || 1 });
      setHidePath(false);
    } catch (e) {
      // Unable to getBBox of SVG
    }
  }, [path]);

  useLayoutEffect(() => {
    setHidePath(true);
  }, [path]);

  if (!path) {
    return <div>N/A</div>;
  }

  return (
    <div data-testid="path-preview">
      <Container>
        <SVG
          hidePath={hidePath}
          ref={svgRef}
          viewBox={
            viewBox ||
            `${bbox.x - 3} ${bbox.y - 3} ${bbox.width + 3} ${bbox.height + 3}`
          }
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          yReflect={yReflect}
        >
          <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g
              transform="translate(2.000000, 2.000000)"
              stroke="#000000"
              strokeWidth={pathStyles.strokeWidth}
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <path
                d={path}
                fill={pathStyles.fill}
                strokeWidth={pathStyles.strokeWidth}
                stroke={pathStyles.stroke}
                style={pathStyles}
              />
            </g>
          </g>
        </SVG>
      </Container>
    </div>
  );
};

export default PathPreview;
