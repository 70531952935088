/* eslint-disable @typescript-eslint/camelcase */
import { useQuery, useMutation } from '@apollo/client';
import { pathOr } from 'ramda';
import React from 'react';
import styled from 'styled-components';

import { GetLogCodeQuery } from '@/generated/hasura.graphql';
import Loading from '@molecules/loading';
import Problem from '@molecules/problem';
import { CodeFormValues } from '../types';
import {
  getAddedLogCodeRelationships,
  getCodeFormValuesFromCodeDBEntry,
  getRemovedLogCodeRelationships,
} from '../utils';
import CodeEditor from '../code-editor';

import {
  updateLogCode as updateLogCodeMutation,
  addLogCodeAudience as addLogCodeAudienceMutation,
  addLogCodeResponsibility as addLogCodeResponsibilityMutation,
  removeLogCodeAudience as removeLogCodeAudienceMutation,
  removeLogCodeResponsibility as removeLogCodeResponsibilityMutation,
  getLogCode as getLogCodeQuery,
} from '../queries';

const StatusWrapper = styled.div`
  padding: 2rem 0;
`;

const CodeItemEdit = ({ id }: { id: number }) => {
  // Save Log Code
  const [updateLogCode] = useMutation(updateLogCodeMutation);
  const [addLogAudience] = useMutation(addLogCodeAudienceMutation);
  const [addLogResponsibility] = useMutation(addLogCodeResponsibilityMutation);
  const [removeLogAudience] = useMutation(removeLogCodeAudienceMutation);
  const [removeLogResponsibility] = useMutation(
    removeLogCodeResponsibilityMutation,
  );
  // Retrieve Log Code from database.
  const { loading, error, data, refetch } = useQuery<GetLogCodeQuery>(
    getLogCodeQuery,
    {
      fetchPolicy: 'no-cache',
      variables: { id },
    },
  );

  // Show loading or problem message if we don't have data yet.
  if (loading)
    return (
      <StatusWrapper>
        <Loading />
      </StatusWrapper>
    );
  if (error || !data)
    return (
      <StatusWrapper>
        <Problem />
      </StatusWrapper>
    );

  const initialLogCode = getCodeFormValuesFromCodeDBEntry(data);

  // Update Log Code.
  const handleUpdate = async (logData: CodeFormValues) => {
    const updatedLogCode = {
      description: pathOr(null, ['description'], logData),
      resolution: pathOr(null, ['resolution'], logData),
      level_id: pathOr(null, ['level', 'value'], logData),
      log_system_id: pathOr(null, ['system', 'value'], logData),
      name: pathOr(null, ['name'], logData),
      operation_id: pathOr(null, ['operation', 'value'], logData),
      disruption_level_id: pathOr(null, ['disruptionLevel', 'value'], logData),
      audio_notification_id: pathOr(
        null,
        ['audioNotification', 'value'],
        logData,
      ),
    };

    await updateLogCode({
      variables: {
        ...updatedLogCode,
        id,
      },
    });

    const addedRelationships = getAddedLogCodeRelationships(data, logData, id);
    const removedRelationships = getRemovedLogCodeRelationships(
      data,
      logData,
      id,
    );

    // Add & Remove relationships to Audience / Responsibility.
    const updateRelationships = [
      ...addedRelationships.audience.map((a) =>
        addLogAudience({ variables: a }),
      ),
      ...addedRelationships.responsibility.map((r) =>
        addLogResponsibility({ variables: r }),
      ),
      ...removedRelationships.audience.map((a) =>
        removeLogAudience({ variables: a }),
      ),
      ...removedRelationships.responsibility.map((r) =>
        removeLogResponsibility({ variables: r }),
      ),
    ];
    await Promise.all(updateRelationships);

    // Refresh LogCode after update.
    await refetch();
  };

  return (
    <CodeEditor initialLogCode={initialLogCode} save={handleUpdate}>
      <h2 className="border-b border-gray-300 font-normal mb-4 pb-2 text-xl">
        <span className="font-semibold">Code:</span> {id}
      </h2>
    </CodeEditor>
  );
};

export default CodeItemEdit;
