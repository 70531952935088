import { omit, pipe } from 'ramda';
import { useQuery } from '@apollo/client';
import moment from 'moment';
import React, { useEffect, useState } from 'react';

import { GetSlumpsSubscription, System } from '@/generated/hasura.graphql';
import App from '@templates/app';
import Button from '@atoms/button';
import DateRange from '@atoms/date-range';
import Loading from '@molecules/loading';
import Problem from '@molecules/problem';
import TableView from '@organisms/table-view';
import { getSlumps as getSlumpsQuery } from './queries';
import { formatSystemLabel } from '../utils';
import SystemSelect from '../magma-data/system-select';

type QCSamples = GetSlumpsSubscription['slump'];

const formatDate = (d: string | unknown) => {
  if (typeof d !== 'string') return '';
  return moment(d).format('M/D/YY h:mm a');
};

const QCDataTable = ({
  onChange,
  startDate = null,
  endDate = null,
  systems = [],
}: {
  onChange: (qcData: QCSamples) => void;
  startDate: Date | null;
  endDate: Date | null;
  systems: System[];
}) => {
  // Get QC data from database.
  const { data, loading, error } = useQuery<GetSlumpsSubscription>(
    getSlumpsQuery,
    {
      variables: {
        startDate,
        endDate,
        systemIds:
          systems && systems.length > 0
            ? systems.map((system) => system.id)
            : null,
      },
    },
  );

  // Dispatch onChange event when data is updated.
  useEffect(() => {
    if (data && Array.isArray(data?.slump)) onChange(data.slump);
  }, [data]);

  // Show loading or problem message if we don't have data yet.
  if (loading) return <Loading />;
  if (error || !data?.slump) return <Problem />;

  // Render Table view of Sample Data.
  return (
    <div>
      <TableView
        data={{
          fields: [
            {
              ref: 'system',
              label: 'System',
              formatter: (s) => formatSystemLabel(s),
            },
            {
              ref: 'sampled_at',
              label: 'Time',
              formatter: (d) => formatDate(d),
            },
            {
              ref: 'slump_location',
              label: 'Location',
              formatter: (d) => d.display_name,
            },
            {
              ref: 'slump_cm',
              label: 'Slump',
              formatter: (d) => (d == null ? '--' : `${d}cm`),
            },
            {
              ref: 'air_percent',
              label: 'Air',
              formatter: (d) => (d == null ? '--' : `${d}%`),
            },
            {
              ref: 'material_temperature_f',
              label: 'Mat. Temp',
              formatter: (d) => (d == null ? '--' : `${d}°F`),
            },
            {
              ref: 'batch',
              label: 'Batch',
              formatter: (d) => d?.batch_number ?? '--',
            },
            {
              ref: 'cylinder_id',
              label: 'Cylinder',
              formatter: (d) => d ?? '--',
            },
            {
              ref: 'project_alias',
              label: 'Project',
            },
            {
              ref: 'lot_number',
              label: 'Lot',
            },
            {
              ref: 'slump_type',
              label: 'Type',
              formatter: (d) => d.type,
            },
            {
              ref: 'submitted_by',
              label: 'Subm. By',
            },
            {
              ref: 'comment',
              label: 'Comment',
              formatter: (d) => d ?? '--',
            },
          ],
          rows: data.slump.map((d) => ({
            id: d.id,
            ...d,
          })),
        }}
      />
    </div>
  );
};

const QCData = () => {
  // Initialize QC Data Set state.
  const [qcData, setQCData] = useState<QCSamples>([]);

  // Initialize Date Range Filter state.
  const [dateRange, setDateRange] = useState<[Date | null, Date | null]>([
    null,
    null,
  ]);

  const [startDate, endDate] = dateRange;

  const [selectedSystems, setSelectedSystems] = useState<System[]>([]);

  const handleDownload = () => {
    // Flatten csvData to handle nested GraphQL queries.
    const flattenObject = (
      o: object,
      keys: string[] = [],
    ): { [key: string]: string | number | null } =>
      Object.entries(o).reduce((flatObject, [key, value]) => {
        if (Array.isArray(value))
          // Create an object from Arrays and increment the key by one for the
          // object property.
          return {
            ...flatObject,
            ...flattenObject(
              value.reduce(
                (obj, v, index) => ({ ...obj, [index + 1]: v }),
                {},
              ),
              [...keys, key],
            ),
          };

        // Keep null values
        if (typeof value === 'object' && value !== null)
          // Remove Extra GraphQL response properties from object.
          return {
            ...flatObject,
            ...flattenObject(omit(['__typename'], value), [...keys, key]),
          };

        return {
          ...flatObject,
          [[...keys, key].join('.')]: value,
        };
      }, {});

    // Format data values for spreadsheet.
    const formatValues = (o: object) =>
      Object.entries(o).reduce((formattedObject, [key, value]) => {
        if (/_time|_at/i.test(key)) {
          return {
            ...formattedObject,
            [key]: formatDate(value),
          };
        }

        return formattedObject;
      }, o);

    // Omit unnecessary keys from batch objects and flatten.
    const csvData = qcData.map((md) =>
      pipe(flattenObject, formatValues)(omit(['__typename'], md)),
    );

    const priorityColumns = [
      'system.name',
      'sampled_at',
      'slump_location.display_name',
      'slump_cm',
      'air_percent',
      'material_temperature_f',
      'batch.id',
      'batch.batch_id',
      'batch.batch_number',
      'cylinder_id',
      'project_alias',
      'lot_number',
      'slump_type.display_name',
      'submitted_by',
      'comment',
    ];

    const keys = [
      ...priorityColumns,
      ...Object.keys(csvData[0]).filter(
        (key) => !priorityColumns.includes(key),
      ),
    ];

    // Convert Batch Array into CSV data.
    const csv = [
      keys.join(','),
      ...csvData.map((data) => {
        return keys
          .map((key) => {
            // if data contains commas, wrap in quotes
            if (data[key] && data[key].toString().includes(',')) {
              return `"${data[key]}"`;
            }
            return data[key];
          })
          .join(',');
      }),
    ].join('\n');

    // Create HTMLAnchorElement to use for triggering a download.
    const link = document.createElement('a');
    link.setAttribute(
      'href',
      `data:text/csv;charset=utf-8,${encodeURIComponent(csv)}`,
    );
    link.setAttribute('download', 'qc-data.csv');
    link.click();
  };

  return (
    <App section="qc-data" title="QC Data">
      <div className="mb-6 flex justify-end">
        <SystemSelect className="mr-2" onChange={setSelectedSystems} />
        <DateRange
          className="mr-2"
          onChange={setDateRange}
          value={dateRange}
        />
        <Button onClick={qcData.length ? () => handleDownload() : undefined}>
          Download CSV
        </Button>
      </div>
      <QCDataTable
        endDate={endDate}
        onChange={setQCData}
        startDate={startDate}
        systems={selectedSystems}
      />
    </App>
  );
};
export default QCData;
