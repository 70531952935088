import React, { useState } from 'react';
import { ValidationError } from 'jsonschema';
import Label from '@atoms/label';
import FormError from '@atoms/form-error';

import { Input, LabelUnit } from './styles';
import { AdmixtureFixedDosingForm } from '../types';
import { toErrorMessage } from './validator';

interface FixedDosingEditorProps {
  initialFixedDosing: AdmixtureFixedDosingForm;
  onChange?: (fixedDosing: AdmixtureFixedDosingForm) => void;
  errors?: ValidationError[];
  errorBasePath: string;
}

export const FixedDosingEditor = ({
  initialFixedDosing,
  onChange,
  errors = [],
  errorBasePath = 'instance',
}: FixedDosingEditorProps) => {
  const [batchWeight, setBatchWeight] = useState(
    initialFixedDosing.refBatchWeightInLbs,
  );

  const [fixedVolume, setFixedVolume] = useState(
    initialFixedDosing.volumeInMl,
  );

  const onBatchWeightChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const refBatchWeightInLbs = event.target.value
      ? parseFloat(event.target.value)
      : undefined;
    setBatchWeight(refBatchWeightInLbs);

    if (onChange) {
      onChange({
        ...initialFixedDosing,
        refBatchWeightInLbs,
      });
    }
  };

  const onFixedVolumeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const volumeInMl = event.target.value
      ? parseFloat(event.target.value)
      : undefined;
    setFixedVolume(volumeInMl);

    if (onChange) {
      onChange({
        ...initialFixedDosing,
        volumeInMl,
      });
    }
  };

  const hasError = (key: string) => {
    return errors.some(
      (err) =>
        err.property === `${errorBasePath}.${key}` ||
        (err.property === errorBasePath && err.argument === key),
    );
  };

  const getError = (key: string) =>
    errors
      .filter(
        (err) =>
          err.property === `${errorBasePath}.${key}` ||
          (err.property === errorBasePath && err.argument === key),
      )
      .map(toErrorMessage)
      .join('\n');

  return (
    <>
      <div className="col-start-1">
        <Label>Reference batch weight</Label>
        <div className="flex items-center">
          <Input
            type="number"
            step={0.01}
            min={1}
            value={batchWeight}
            onChange={onBatchWeightChange}
          />
          <span className="pl-2">
            <LabelUnit>lbs</LabelUnit>
          </span>
        </div>
        {hasError(`refBatchWeightInLbs`) && (
          <FormError>{getError(`refBatchWeightInLbs`)}</FormError>
        )}
      </div>
      <div>
        <Label>Fixed dose</Label>
        <div className="flex items-center">
          <Input
            type="number"
            step={0.01}
            min={0}
            value={fixedVolume}
            onChange={onFixedVolumeChange}
          />
          <span className="pl-2">
            <LabelUnit>mL</LabelUnit>
          </span>
        </div>
        {hasError(`volumeInMl`) && (
          <FormError>{getError(`volumeInMl`)}</FormError>
        )}
      </div>
    </>
  );
};

export default FixedDosingEditor;
