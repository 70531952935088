/* eslint-disable @typescript-eslint/camelcase */
import { pathOr, pipe } from 'ramda';

import {
  GetLogCodeQuery,
  Code_Responsibility_Pk_Columns_Input,
  Code_Audience_Pk_Columns_Input,
} from '@/generated/hasura.graphql';
import { CodeItem } from './code-list/code-item';
import { CodeOption, CodeProperty, CodeFormValues } from './types';

// Convert a DB CodeProperty to a Select Option.
export const mapToOption = (item: CodeProperty): CodeOption => ({
  value: item.id,
  label: item.name,
});

// Take an array of DB values and convert it to an array of Select Options.
export const mapToOptions = (
  items: { id: number; name: string }[],
): { value: number; label: string }[] => items.map(mapToOption);

// Take the DB format for a Value and convert it to a Select Option format.
export const dbValueToOption = (item: CodeProperty | null) => {
  return item ? mapToOption(item) : null;
};

// Return items audience / responsibility relationships that have been ADDED to
// the Log Code.
export const getAddedLogCodeRelationships = (
  previous: GetLogCodeQuery,
  updated: CodeFormValues,
  code_id: number,
): {
  audience: Code_Audience_Pk_Columns_Input[];
  responsibility: Code_Responsibility_Pk_Columns_Input[];
} => ({
  audience: updated.audience
    .filter(
      (a) =>
        !(previous.code_by_pk?.code_audiences || []).find(
          (ca) => ca.audience.id === a.value,
        ),
    )
    .map((a) => ({ audience_id: a.value, code_id })),
  responsibility: updated.responsibility
    .filter(
      (r) =>
        !(previous.code_by_pk?.code_responsibilities || []).find(
          (cr) => cr.responsibility?.id === r.value,
        ),
    )
    .map((r) => ({ responsibility_id: r.value, code_id })),
});

// Return items audience / responsibility relationships that have been REMOVED
// from the Log Code.
export const getRemovedLogCodeRelationships = (
  previous: GetLogCodeQuery | undefined,
  updated: CodeFormValues,
  code_id: number,
): {
  audience: Code_Audience_Pk_Columns_Input[];
  responsibility: Code_Responsibility_Pk_Columns_Input[];
} => ({
  audience: (previous?.code_by_pk?.code_audiences || [])
    .filter((ca) => !updated.audience.find((a) => a.value === ca.audience.id))
    .map((ca) => ({ audience_id: ca.audience.id, code_id })),
  responsibility: (previous?.code_by_pk?.code_responsibilities || [])
    .filter(
      (cr) =>
        !updated.responsibility.find((r) => r.value === cr.responsibility?.id),
    )
    .map(
      (cr) =>
        ({
          responsibility_id: cr.responsibility?.id,
          code_id,
        } as Code_Responsibility_Pk_Columns_Input),
    ),
});

// Convert Log Code DB entry to CodeFormValues.
export const getCodeFormValuesFromCodeDBEntry = (
  data: GetLogCodeQuery | CodeItem,
): CodeFormValues => {
  const logCode = pathOr(data, ['code_by_pk'], data);
  return {
    audience: (logCode?.code_audiences || [])?.map((ca) =>
      mapToOption(ca.audience as { id: number; name: string }),
    ),
    description: pathOr('', ['description'], logCode),
    resolution: pathOr('', ['resolution'], logCode),
    level: pipe(pathOr(null, ['level']), dbValueToOption)(logCode),
    name: pathOr('', ['name'], logCode),
    disruptionLevel: pipe(
      pathOr(null, ['disruption_level']),
      dbValueToOption,
    )(logCode),
    operation: pipe(pathOr(null, ['operation']), dbValueToOption)(logCode),
    responsibility: (logCode?.code_responsibilities || [])?.map((cr) =>
      mapToOption(cr.responsibility as { id: number; name: string }),
    ),
    system: pipe(pathOr(null, ['system']), dbValueToOption)(logCode),
    audioNotification: pipe(
      pathOr(null, ['audio_notification']),
      dbValueToOption,
    )(logCode),
  };
};

// Break operation into strings and generate a unique key.
export const createProposedKey = (
  operation: string,
  requestedKeyCount = 1,
) => {
  // split word by spaces.
  const wordCount = operation.split(/\s+/).length;

  // Get number of characters depending on the requested key amount
  const charsPerWord = Math.floor(requestedKeyCount / wordCount);

  const remainder =
    wordCount * charsPerWord > 0
      ? requestedKeyCount % (wordCount * charsPerWord)
      : requestedKeyCount;

  /*
   * Split operation by spaces
   */
  return operation
    .toUpperCase()
    .split(/\s+/)
    .map((s, index) => {
      const end = charsPerWord + Math.max(0, remainder - index);
      return s.split('').slice(0, end).join('');
    })
    .join('');
};

/*  Recursively generate a unique key.
 *
 * operation: string created in the operation creatable select.
 * keys: list of keys that already exist in SOL.
 * proposedKey: Key generated recursively.
 *
 */

export const generateKey = (
  operation: string,
  keys: string[],
  proposedKey = '',
): string => {
  // Check if there is a propsed key, if not generate one
  if (!proposedKey.length) {
    return generateKey(operation, keys, createProposedKey(operation));
  }

  // If proposed key is in exisiting keys then generate a new one
  if (keys.includes(proposedKey)) {
    /*
     * continue going through the letters of the word until.
     * a key has has been created that does not exist in
     * the current list of keys in SOL Operations table.
     */
    return generateKey(
      operation,
      keys,
      createProposedKey(operation, proposedKey.length + 1),
    );
  }
  return proposedKey;
};
