import React from 'react';
import { CalibrationPoint } from '../project-editor';

const translateGCodeYCoordToSVGCoord = (y: number): number => y * -1;

export default function Circle({
  coords,
  handlePointerDown,
  active,
  startY,
  index,
}: {
  coords: CalibrationPoint;
  handlePointerDown: (e: React.MouseEvent) => void;
  active: boolean;
  startY: number;
  index: number;
}) {
  const circleLabel = index + 1;
  return (
    <g>
      {active && (
        <>
          <path
            strokeWidth=".5"
            stroke="#EF377A"
            d={`M 0,${translateGCodeYCoordToSVGCoord(coords.y)} H 500`}
          />
          <path
            strokeWidth=".5"
            stroke="#EF377A"
            d={`M ${coords.x},${startY} V 300`}
          />
        </>
      )}
      <circle
        onPointerDown={handlePointerDown}
        cx={coords.x}
        cy={translateGCodeYCoordToSVGCoord(coords.y)}
        r={4}
        fill="#EF377A"
        stroke="#EF377A"
        strokeOpacity="0.25"
        strokeWidth="4"
      />
      <rect
        x={coords.x < 33 ? coords.x + 8 : coords.x - 33}
        y={translateGCodeYCoordToSVGCoord(coords.y + 4)}
        width={25}
        height={8}
        rx="2"
        fill="black"
      />
      <text
        className="pointer-events-none select-none"
        fill="white"
        fontWeight="bold"
        x={coords.x < 33 ? coords.x + 12 : coords.x - 29}
        y={translateGCodeYCoordToSVGCoord(coords.y)}
        fontSize="3"
        textAnchor="middle"
        alignmentBaseline="central"
        pointerEvents="none"
      >
        X:
      </text>
      <text
        className="pointer-events-none select-none"
        fill="white"
        x={coords.x < 33 ? coords.x + 16 : coords.x - 24}
        y={translateGCodeYCoordToSVGCoord(coords.y)}
        fontSize="3"
        textAnchor="middle"
        alignmentBaseline="central"
        pointerEvents="none"
      >
        {Math.round(coords.x)}&quot;
      </text>
      <text
        className="pointer-events-none select-none"
        fill="white"
        fontWeight="bold"
        x={coords.x < 33 ? coords.x + 22 : coords.x - 18.5}
        y={translateGCodeYCoordToSVGCoord(coords.y)}
        fontSize="3"
        textAnchor="middle"
        alignmentBaseline="central"
        pointerEvents="none"
      >
        Y:
      </text>
      <text
        className="pointer-events-none select-none"
        fill="white"
        x={coords.x < 33 ? coords.x + 28 : coords.x - 13.5}
        y={translateGCodeYCoordToSVGCoord(coords.y)}
        fontSize="3"
        textAnchor="middle"
        alignmentBaseline="central"
        pointerEvents="none"
      >
        {Math.round(coords.y)}&quot;
      </text>
      <text
        className="pointer-events-none select-none"
        fill="white"
        x={coords.x}
        y={translateGCodeYCoordToSVGCoord(coords.y)}
        fontSize="3"
        textAnchor="middle"
        alignmentBaseline="central"
        pointerEvents="none"
      >
        {circleLabel}
      </text>
    </g>
  );
}
