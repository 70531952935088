import React from 'react';
import { Router, RouteComponentProps } from '@reach/router';

import SassCalibration from './sass-calibration';

const Route = ({
  component,
}: RouteComponentProps & { component: React.ReactElement }) => component;

const MiscRouter = () => {
  return (
    <Router>
      <SassCalibration path="/sass/save-factory-cal" />
    </Router>
  );
};

export default MiscRouter;
