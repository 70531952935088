/* eslint-disable @typescript-eslint/camelcase */
// import { useQuery } from '@apollo/client';
import { useQuery } from '@apollo/client';
import { navigate } from 'gatsby';

import React, { useState } from 'react';
import styled from 'styled-components';
import PathPreview from '@atoms/path-preview';
import SectionLabel from '@atoms/section-label';
import TableView from '@organisms/table-view';
import App from '@templates/app';
import PanelSticky from '@atoms/panel-sticky';
import { Container } from '@organisms/table-view/elements';
import { GetProjectsQuery, Project } from '@/generated/hasura.graphql';
import EmptyPage from '@/admin/empty-page';
import { getProjects as getProjectsQuery } from '../queries';

// Wrapper for project details in side bar (below the main label).
const Detail = styled.div`
  margin-bottom: 32px;
`;

const ActionsContainer = styled(Container)`
  display: flex;
  justify-content: flex-end;
  padding-bottom: 16px;
  background: transparent;
`;

// Use SearchField from quarks instead of this custom input - Ganser
const Input = styled.input`
  border: 1px solid rgb(175, 175, 175);
  box-sizing: border-box;
  font-size: 16px;
  line-height: 1.25;
  padding: 6px 12px;
  color: rgb(31, 31, 31);
  border-radius: 16px;
  font-family: Roboto, sans-serif;
  font-weight: 300;
  &:hover {
    border-color: rgb(0, 184, 219);
  }
  &::placeholder {
    font-style: italic;
  }
  &::-webkit-search-cancel-button {
    -webkit-appearance: none;
    appearance: none;
    height: 10px;
    width: 10px;
    background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg' %3E%3Ccircle cx='8' cy='8' r='8' fill='gray' /%3E%3Cpath fillRule='evenodd' clipRule='evenodd' d='M5.14645 5.14645C5.34171 4.95118 5.65829 4.95118 5.85355 5.14645L8.15165 7.44454L10.4497 5.14645C10.645 4.95118 10.9616 4.95118 11.1569 5.14645C11.3521 5.34171 11.3521 5.65829 11.1569 5.85355L8.85876 8.15165L11.1569 10.4497C11.3521 10.645 11.3521 10.9616 11.1569 11.1569C10.9616 11.3521 10.645 11.3521 10.4497 11.1569L8.15165 8.85876L5.85355 11.1569C5.65829 11.3521 5.34171 11.3521 5.14645 11.1569C4.95118 10.9616 4.95118 10.645 5.14645 10.4497L7.44454 8.15165L5.14645 5.85355C4.95118 5.65829 4.95118 5.34171 5.14645 5.14645Z' fill='white' /%3E%3C/svg%3E");
    background-size: 10px 10px;
  }
`;

const ProjectList = () => {
  const [searchFilter, setSearchFilter] = useState<string>('');

  const handleSearchTextChange = (e?: React.ChangeEvent<HTMLInputElement>) => {
    if (e?.target) setSearchFilter(e.target.value);
  };
  // Get data from database.
  const { loading, error, data } = useQuery<GetProjectsQuery>(
    getProjectsQuery,
  );

  // Keep track of which project is selected in the table.
  const [selected, setSelected] = useState(null as number | null);

  // Show loading or problem message if we don't have data yet.
  if (loading) return <EmptyPage sectionLabel="Projects" />;
  if (error || !data) return <EmptyPage problem sectionLabel="Projects" />;

  // Get reference to the selected project. Null if we don't have one.
  const selectedProject = data.project.find((p) => p.id === selected) || null;

  // Function to navigate to selected project page.
  const navigateToSelected = () =>
    selectedProject && navigate(`/projects/${selectedProject.id}`);

  const panel = selectedProject && (
    <PanelSticky>
      <SectionLabel>{selectedProject.label}</SectionLabel>
      <Detail>
        <strong>Site:</strong> {selectedProject.site}
        <br />
        <strong>Calibration Set:</strong>
        {selectedProject.calibration_data ? ' Yes' : ' No'}
      </Detail>
      <PathPreview path={selectedProject?.path} yReflect={false} />
    </PanelSticky>
  );

  return (
    <App section="projects" title="Projects" panel={panel}>
      <ActionsContainer>
        <Input
          autoFocus
          placeholder="Search by name"
          onChange={handleSearchTextChange}
          value={searchFilter}
          type="search"
        />
      </ActionsContainer>
      <TableView
        selected={selectedProject?.id}
        setSelected={navigateToSelected}
        hover={setSelected}
        data={{
          fields: [
            { ref: 'label', label: 'Name' },
            { ref: 'site', label: 'Location' },
            {
              ref: 'updated_at',
              label: 'Updated At',
              formatter: (x: unknown) =>
                new Date(x as string).toLocaleString(),
            },
          ],
          rows: data.project.filter((item: Partial<Project>) => {
            if (!searchFilter) return true;
            if (item.label) {
              return item.label
                .toLowerCase()
                .includes(searchFilter.toLowerCase());
            }
            return false;
          }),
        }}
      />
    </App>
  );
};

export default ProjectList;
