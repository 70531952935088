import { nanoid } from 'nanoid';
import moment from 'moment';
import React, { useState } from 'react';
import styled from 'styled-components';

import colors from '@/constants/colors';
import HR from '@atoms/hr';
import Label from '@atoms/label';
import Input from '@atoms/input';
import Select from '@atoms/select';
import Textarea, { TextAreaTheme } from '@atoms/textarea';
import { ChecklistItem, Role, System } from '../types';

const Container = styled.div`
  * + label {
    margin-top: 1rem;
  }

  hr {
    // Adjust for padding in panel.
    margin-left: -1rem;
    width: calc(100% + 2rem);
  }
`;

const H3 = styled.h3`
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 0.875rem;
  line-height: 1.5rem;
  text-transform: uppercase;
  color: ${colors.cyan['500']};
`;

const TABLE = styled.table`
  font-size: 0.875rem;
  margin-top: 1rem;

  td {
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
  }

  td:first-of-type {
    font-weight: 100;
    padding-right: 2rem;
  }
`;

interface EditPanelProps extends ChecklistItem {
  updateItem: (item: Partial<ChecklistItem>) => void;
  nested: boolean;
}

interface RoleOption {
  value: number;
  label: string;
  role: Role;
}

const roles: Role[] = [
  {
    id: 1,
    name: 'Vulcan Captain',
  },
  {
    id: 2,
    name: 'Magma Captain',
  },
  {
    id: 3,
    name: 'Titan Observer',
  },
  {
    id: 4,
    name: 'Terra User',
  },
  {
    id: 5,
    name: 'Mission Control',
  },
];

interface SystemOption {
  value: number;
  label: string;
  system: System;
}

const availableSystems = [
  {
    serial: '2.1.1',
    id: 1,
  },
  {
    serial: '0.1.1',
    id: 4,
  },
  {
    serial: '2.2.2',
    id: 3,
  },
  {
    serial: '2.2.1',
    id: 2,
  },
  {
    serial: '0.0.1',
    id: 6,
  },
  {
    serial: '2.4.1',
    id: 7,
  },
  {
    serial: '2.5.1',
    id: 8,
  },
  {
    serial: '2.5.2',
    id: 9,
  },
  {
    serial: '2.5.3',
    id: 10,
  },
  {
    serial: '2.5.4',
    id: 11,
  },
  {
    serial: '0.0.2',
    id: 12,
  },
];

const LABEL = styled.label`
  display: block;
  font-size: 0.875rem;
  line-height: 1.15em;
  user-select: none;

  > input[type='checkbox'] {
    display: inline-block;
    margin-right: 0.5rem;
  }
`;

const CheckBox = ({
  children,
  onChange,
  value,
}: {
  children: React.ReactNode | React.ReactNode[];
  onChange: (checked: boolean) => void;
  value: boolean;
}) => {
  const [id] = useState<string>(nanoid());
  const [currentValue, setCurrentValue] = useState<boolean>(value);
  const handleOnChange = () => {
    setCurrentValue(!currentValue);
    onChange(!currentValue);
  };

  return (
    <LABEL htmlFor={id}>
      <input
        checked={currentValue}
        id={id}
        onChange={handleOnChange}
        type="checkbox"
      />
      {children}
    </LABEL>
  );
};

export const ListPanel = ({
  assigned,
  createdAt,
  createdBy,
  description,
  isGroup,
  name,
  nested,
  systems,
  updatedAt,
  updateItem,
}: EditPanelProps) => {
  const [assignedTo, setAssignedTo] = useState<Role[] | null>(assigned);
  const [selectedSystems, setSelectedSystems] = useState<System[] | null>(
    systems,
  );

  const title = () => {
    if (nested) {
      return 'Sub-Item Details';
    }

    if (isGroup) {
      return 'Item Group Details';
    }

    return 'Item Details';
  };

  return (
    <Container>
      <H3>{title()}</H3>
      <HR />
      <Label>List Name</Label>
      <Input
        value={name}
        onChange={(ev) => updateItem({ name: ev.target.value })}
      />
      {nested ? null : (
        <CheckBox
          onChange={(active) => updateItem({ isGroup: active })}
          value={isGroup}
        >
          Item Group
        </CheckBox>
      )}
      <Label>Description</Label>
      <Textarea
        placeholder="Description here"
        value={description}
        onChange={(text) => updateItem({ description: text })}
        theme={TextAreaTheme.Admin}
      />
      <Label className={nested ? 'invisible' : ''}>Role Assignment</Label>
      <Select
        className={nested ? 'invisible' : ''}
        isMulti
        isClearable
        onChange={(opt) => {
          if (opt === null) {
            setAssignedTo(null);
            return;
          }

          const selected = opt as RoleOption[];
          const selecteds = selected.map((s) => s.role);
          setAssignedTo(selecteds);
          updateItem({ assigned: selecteds });
        }}
        value={
          assignedTo === null
            ? null
            : assignedTo.map((x) => ({
                value: x.id,
                label: x.name,
                role: x,
              }))
        }
        options={roles.map((role) => ({
          value: role.id,
          label: role.name,
          role,
        }))}
      />
      <Label className={nested ? 'invisible' : ''}>Available Systems</Label>
      <Select
        className={nested ? 'invisible' : ''}
        isMulti
        isClearable
        onChange={(opt) => {
          if (opt === null) {
            setSelectedSystems(null);
            return;
          }

          const selected = opt as SystemOption[];
          const selecteds = selected.map((s) => s.system);
          setSelectedSystems(selecteds);
          updateItem({ systems: selecteds });
        }}
        value={
          selectedSystems === null
            ? null
            : selectedSystems.map((x) => ({
                value: x.id,
                label: x.serial,
                system: x,
              }))
        }
        options={availableSystems.map((system) => ({
          value: system.id,
          label: system.serial,
          system,
        }))}
      />
      <HR className="mt-6" />
      <TABLE>
        <tbody>
          <tr>
            <td>Created by</td>
            <td>{createdBy}</td>
          </tr>
          <tr>
            <td>Created</td>
            <td>{moment(createdAt).format('M/D/YY h:mm a')}</td>
          </tr>
          <tr>
            <td>Modified</td>
            <td>{moment(updatedAt).format('M/D/YY h:mm a')}</td>
          </tr>
        </tbody>
      </TABLE>
    </Container>
  );
};

export default ListPanel;
