import * as R from 'ramda';
import { Link } from 'gatsby';
import styled, { css } from 'styled-components';
import React from 'react';

const Menu = styled.div`
  padding: 0;
  margin: 0;
`;

interface ButtonProps {
  display: 'small' | 'sidebar';
  dark: boolean;
  selected: boolean;
  activeColor: string;
}

const buttonBorderLeft = ({ selected, activeColor }: ButtonProps) =>
  selected ? `2px solid ${activeColor}` : 'none';

const backgroundColor = (props: ButtonProps): string | null => {
  if (!props.selected) return null;
  return props.dark ? '#444' : '#eee';
};

const hoverColor = (props: ButtonProps): string => {
  return props.dark ? '#383838' : '#f4f4f4';
};

const color = ({ dark }: ButtonProps) => (dark ? 'white' : 'black');

const iconColor = (props: ButtonProps): string => {
  if (props.selected) return color(props);
  return props.dark ? '#aaa' : '#555';
};

const buttonCSS = css<ButtonProps>`
  font-family: 'Montserrat', sans-serif;
  border-top: ${({ display }) =>
    display === 'sidebar' ? 'none' : '1px solid #ddd'};
  display: block;
  padding: ${({ display }) =>
    display === 'small' ? '8px 2rem' : '16px 24px'};
  box-sizing: border-box;
  width: 100%;
  color: ${color};
  text-transform: uppercase;
  font-size: ${({ display }) => (display === 'small' ? 14 : 16)}px;
  font-weight: ${({ selected }) => (selected ? 700 : 'normal')};
  border-left: ${buttonBorderLeft};
  i {
    margin-right: 8px;
    color: ${iconColor};
    margin-left: ${({ selected }) => (selected ? '-2px' : 0)};
    font-size: ${({ display }) => (display === 'sidebar' ? 20 : 14)}px;
  }
  background: ${R.defaultTo('auto', backgroundColor)};
  &:hover {
    background: ${(props) => backgroundColor(props) || hoverColor(props)};
  }
`;
const MenuButton = styled.button`
  ${buttonCSS}
`;

const StyledLink = styled(Link).withConfig({
  shouldForwardProp: (prop) =>
    !['display', 'dark', 'activeColor', 'selected'].includes(prop),
})`
  ${buttonCSS}
`;

export const ListNav = ({
  items,
  display = 'small',
  activeColor,
  dark = false,
}: {
  display?: 'small' | 'sidebar';
  dark?: boolean;
  items: {
    icon: string;
    label: string;
    selected: boolean;
    link: string | (() => void | Promise<void>);
  }[];
  activeColor?: string;
}) => (
  <Menu>
    {items.map(({ icon, label, selected, link }, i) => {
      const buttonProps = {
        key: i,
        selected,
        display,
        dark,
        activeColor: activeColor || 'white',
        children: (
          <>
            <i className={`icon-${icon}`} />
            {label}
          </>
        ),
      };
      if (typeof link === 'string') {
        return React.createElement(StyledLink, { ...buttonProps, to: link });
      }
      return React.createElement(MenuButton, {
        ...buttonProps,
        onClick: link,
      });
    })}
  </Menu>
);

export default ListNav;
