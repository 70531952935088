import { has, omit, path, pickAll, pipe } from 'ramda';
import { useQuery } from '@apollo/client';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import roundTo from 'round-to';

import { Batch, GetBatchesQuery, System } from '@/generated/hasura.graphql';
import App from '@templates/app';
import Button from '@atoms/button';
import DateRange from '@atoms/date-range';
import Loading from '@molecules/loading';
import Problem from '@molecules/problem';
import TableView from '@organisms/table-view';
import { getBatches as getBatchesQuery } from './queries';
import SystemSelect from './system-select';
import { formatSystemLabel } from '../utils';

type MagmaBatches = GetBatchesQuery['batch'];

const formatDate = (d: string | unknown) => {
  if (typeof d !== 'string') return '';
  return moment(d).format('M/D/YY h:mm a');
};

const formatAdditiveVolume = (value: any) => {
  return roundTo((value as number) || 0, 2);
};

const formatPump = (
  p:
    | {
        label: string;
        actualDispensedVolumemL: number;
        dispenseVolumemL: number;
      }
    | unknown,
) => {
  if (
    typeof p === 'object' &&
    has('label', p) &&
    has('actualDispensedVolumemL', p) &&
    has('dispenseVolumemL', p)
  ) {
    return `${path(['label'], p)}:\n ${formatAdditiveVolume(
      path(['actualDispensedVolumemL'], p),
    )}/${formatAdditiveVolume(path(['dispenseVolumemL'], p))}mL`;
  }
  return '--';
};

const formatSlump = (
  s:
    | {
        slump_cm: number;
        air_percent: number;
        material_temperature_f: number;
      }
    | unknown,
) => {
  const data = [];
  const slump = s as {
    slump_cm: number;
    air_percent: number;
    material_temperature_f: number;
  };
  if (slump.slump_cm) data.push(`${slump.slump_cm}cm`);
  if (slump.air_percent) data.push(`${slump.air_percent}%`);
  if (slump.material_temperature_f)
    data.push(`${slump.material_temperature_f}°F`);
  return `[${data.join(', ')}]`;
};

const formatOutcome = (d: Batch) => {
  if (d.dispensed_to_hopper) {
    return 'Dispensed';
  }
  if (d.build_end_time != null) {
    return 'Rejected';
  }
  return 'Canceled';
};

const MagmaDataTable = ({
  onChange,
  startDate = null,
  endDate = null,
  systems = [],
}: {
  onChange: (magmaData: MagmaBatches) => void;
  startDate: Date | null;
  endDate: Date | null;
  systems: System[];
}) => {
  // Get Magma data from database.
  const { data, loading, error } = useQuery<GetBatchesQuery>(getBatchesQuery, {
    variables: {
      startDate,
      endDate,
      systemIds:
        systems && systems.length > 0
          ? systems.map((system) => system.id)
          : null,
    },
  });

  // Dispatch onChange event when data is updated.
  useEffect(() => {
    if (data && Array.isArray(data?.batch)) onChange(data.batch);
  }, [data]);

  // Show loading or problem message if we don't have data yet.
  if (loading) return <Loading />;
  if (error || !data?.batch) return <Problem />;

  // Render Table view of Magma Data.
  return (
    <div>
      <TableView
        data={{
          fields: [
            {
              ref: 'system',
              label: 'System',
              // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
              //  @ts-ignore
              formatter: (s) => formatSystemLabel(s),
            },
            {
              ref: 'build_start_time',
              label: 'Time',
              formatter: (d) => formatDate(d),
            },
            { ref: 'batch_number', label: 'Batch' },
            { ref: 'dispensed', label: 'Dispensed' },
            { ref: 'settled_torque', label: 'Torque' },
            { ref: 'ambient_temp', label: 'Ambient' },
            { ref: 'ratio_achieved', label: 'Ratio' },
            { ref: 'total_powder_added_as_dispensed', label: 'Powder' },
            { ref: 'total_water_added_as_dispensed', label: 'Water' },
            {
              ref: 'admixtureData.0',
              label: 'Pump 1',
              formatter: (p) => formatPump(p),
            },
            {
              ref: 'admixtureData.1',
              label: 'Pump 2',
              formatter: (p) => formatPump(p),
            },
            {
              ref: 'admixtureData.2',
              label: 'Pump 3',
              formatter: (p) => formatPump(p),
            },
            {
              ref: 'admixtureData.3',
              label: 'Pump 4',
              formatter: (p) => formatPump(p),
            },
            {
              ref: 'admixtureData.4',
              label: 'Pump 5',
              formatter: (p) => formatPump(p),
            },
            {
              ref: 'admixtureData.5',
              label: 'Pump 6',
              formatter: (p) => formatPump(p),
            },
            {
              ref: 'slumps',
              label: 'Magma Samples',
              formatter: (sd: Slump[]) =>
                sd.length === 0
                  ? '--'
                  : sd
                      .filter((s) => s.location === 'magma')
                      .map((s) => formatSlump(s))
                      .join(', '),
            },
          ],
          rows: data.batch.map((d) => ({
            id: d.batch_id,
            dispensed: formatOutcome(d),
            ...d,
          })),
        }}
      />
    </div>
  );
};

const MagmaData = () => {
  // Initialize Magma Data Set state.
  const [magmaData, setMagmaData] = useState<MagmaBatches>([]);

  // Initialize Date Range Filter state.
  const [dateRange, setDateRange] = useState<[Date | null, Date | null]>([
    null,
    null,
  ]);

  const [startDate, endDate] = dateRange;

  const [selectedSystems, setSelectedSystems] = useState<System[]>([]);

  const handleDownload = () => {
    // Pre-process object by removing data that should not be part of the
    // spreadsheet.
    const preProcessObject = (
      o: object,
    ): { [key: string]: string | number | null } =>
      Object.entries(o).reduce(
        (preProcessed, [key, value]) => {
          if (key === 'admixtureData') {
            return {
              ...preProcessed,
              [key]: value.map(
                (v: { [k: string]: string | number } | null) => {
                  if (v === null)
                    return {
                      actualDispensedVolumemL: 0,
                      dispenseVolumemL: 0,
                      label: '',
                      priority: '',
                    };
                  return pickAll(
                    [
                      'actualDispensedVolumemL',
                      'dispenseVolumemL',
                      'label',
                      'priority',
                    ],
                    v,
                  );
                },
              ),
            };
          }
          if (key.startsWith('slumps')) {
            return {
              ...omit(['slumps'], preProcessed),
              // comma-delimited list affects the csv output,
              // using double space instead
              magmaSlumpCm: value
                .filter((v) => v.slump_cm != null)
                .filter((v) => v.location === 'magma')
                .map((v) => `${v.slump_cm}`)
                .join(', ')
                .toString(),
              magmaAirPercent: value
                .filter((v) => v.air_percent != null)
                .filter((v) => v.location === 'magma')
                .map((v) => `${v.air_percent}`)
                .join(', ')
                .toString(),
              magmaMaterialTempF: value
                .filter((v) => v.material_temperature_f != null)
                .filter((v) => v.location === 'magma')
                .map((v) => `${v.material_temperature_f}`)
                .join(', ')
                .toString(),
              qcComments: value
                .filter((v) => v.comment != null)
                .filter((v) => v.location === 'magma')
                .map((v) => `${v.comment}`)
                .join(', ')
                .toString(),
            };
          }
          return preProcessed;
        },
        { ...o },
      );

    // Flatten csvData to handle nested GraphQL queries.
    const flattenObject = (
      o: object,
      keys: string[] = [],
    ): { [key: string]: string | number | null } =>
      Object.entries(o).reduce((flatObject, [key, value]) => {
        if (Array.isArray(value))
          // Create an object from Arrays and increment the key by one for the
          // object property.
          return {
            ...flatObject,
            ...flattenObject(
              value.reduce(
                (obj, v, index) => ({ ...obj, [index + 1]: v }),
                {},
              ),
              [...keys, key],
            ),
          };

        // Keep null values
        if (typeof value === 'object' && value !== null)
          // Remove Extra GraphQL response properties from object.
          return {
            ...flatObject,
            ...flattenObject(omit(['__typename'], value), [...keys, key]),
          };

        return {
          ...flatObject,
          [[...keys, key].join('.')]: value,
        };
      }, {});

    // Add Calculated Values.
    const calculatedValues = (o: object) => {
      const buildStartTime = path<string | null>(['build_start_time'], o);
      const buildEndTime = path<string | null>(['build_end_time'], o);
      const getSeconds = (date: string) => new Date(date).getTime() / 1000;

      // If we have build_start_time and build_end_time in our data
      // set, we can calculate build_duration and return it in the
      // csv file.
      if (buildStartTime && buildEndTime) {
        const buildDurationKey = 'build_duration_seconds';
        return {
          ...o,
          [buildDurationKey]:
            getSeconds(buildEndTime) - getSeconds(buildStartTime),
        };
      }

      // By default, return the original object.
      return o;
    };

    // Format data values for spreadsheet.
    const formatValues = (o: object) =>
      Object.entries(o).reduce((formattedObject, [key, value]) => {
        if (/_time|_at/i.test(key)) {
          return {
            ...formattedObject,
            [key]: formatDate(value),
          };
        }

        return formattedObject;
      }, o);

    // Omit unnecessary keys from batch objects and flatten.
    const csvData = magmaData.map((md) =>
      pipe(
        preProcessObject,
        flattenObject,
        calculatedValues,
        formatValues,
      )(omit(['__typename'], md)),
    );

    // Convert Batch Array into CSV data.
    const csv = [
      Object.keys(csvData[0]).join(','),
      ...csvData.map((data) =>
        Object.values(data)
          .map((d) => {
            // if data contains commas, wrap in quotes
            if (d && d.toString().includes(',')) {
              return `"${d}"`;
            }
            return d;
          })
          .join(','),
      ),
    ].join('\n');

    // Create HTMLAnchorElement to use for triggering a download.
    const link = document.createElement('a');
    link.setAttribute(
      'href',
      `data:text/csv;charset=utf-8,${encodeURIComponent(csv)}`,
    );
    link.setAttribute('download', 'batch-data.csv');
    link.target = '_self';
    link.click();
  };

  return (
    <App section="magma-data" title="Magma Data">
      <div className="mb-6 flex justify-end">
        <SystemSelect className="mr-2" onChange={setSelectedSystems} />
        <DateRange
          className="mr-2"
          onChange={setDateRange}
          value={dateRange}
        />
        <Button onClick={magmaData.length ? handleDownload : undefined}>
          Download CSV
        </Button>
      </div>
      <MagmaDataTable
        endDate={endDate}
        onChange={setMagmaData}
        startDate={startDate}
        systems={selectedSystems}
      />
    </App>
  );
};
export default MagmaData;
