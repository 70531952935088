import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';

import { useAuth } from '@providers/auth';
import AccountNav from '@molecules/nav-account';
import colors from '@/constants/colors';
import AccountButton from './account-button';

const NavWrapper = styled.div`
  align-items: center;
  display: flex;
  margin-left: 1rem;
  position: relative;
`;

const SignInButton = styled.button`
  padding: 8px;
  color: white;
  font-size: 1rem;
  text-transform: uppercase;
  transition: color 300ms;

  &:hover {
    color: ${colors.purple['500']};
  }
`;

const MenuWrapper = styled.div`
  position: absolute;
  top: 2.5rem;
  right: 0;
  min-width: 200px;
  background: white;
  border-radius: 4px;
  box-shadow: rgba(0, 0, 0, 0.176) 0px 6px 12px;
  z-index: 100;
`;

const AccoutNav = () => {
  // State to keep track of whether the nav is open or not.
  const [navOpen, setNavOpen] = useState(false);

  // Get auth context.
  const auth = useAuth();

  const navRef = useRef<HTMLDivElement>(null);

  const handleClick = (e: Event) => {
    if (
      e.target instanceof HTMLElement &&
      navRef?.current &&
      navRef?.current.contains(e.target)
    )
      return;
    setNavOpen(false);
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClick);
    return () => document.removeEventListener('mousedown', handleClick);
  }, []);

  // If we don't have auth data or we are loading, don't show anything.
  if (!auth || auth.loading) return null;

  // If we are not authenticated, show the sign in button.
  if (!auth?.isAuthenticated || !auth.user) {
    return (
      <SignInButton type="button" onClick={auth?.login}>
        Log In
      </SignInButton>
    );
  }

  // Show the main account nav.
  return (
    <NavWrapper ref={navRef}>
      <AccountButton user={auth.user} onClick={() => setNavOpen(!navOpen)} />
      {navOpen && (
        <MenuWrapper>
          <AccountNav auth={auth} />
        </MenuWrapper>
      )}
    </NavWrapper>
  );
};

export default AccoutNav;
