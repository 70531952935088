import React from 'react';
import styled from 'styled-components';
import SectionLabel from '@atoms/section-label';

const PanelSection = styled.div`
  &:first-child {
    margin-top: 16px;
  }
  margin: 48px 0;
`;

const Row = styled.div`
  margin: 16px 0;
`;

export const calibrationPanel = (
  <PanelSection>
    <SectionLabel>Calibration Point</SectionLabel>
    <Row>
      Place point in a location free from obstruction of any print path.
    </Row>
    <hr />
  </PanelSection>
);
