import React, { useState } from 'react';
import styled from 'styled-components';

import colors from '@/constants/colors';

export interface CheckListAction {
  icon: string;
  label: string;
  action: Function;
}

const Dot = () => {
  return (
    <svg
      width="4"
      height="3"
      viewBox="0 0 4 3"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="1.57965" cy="1.5" r="1.5" fill="black" />
    </svg>
  );
};

const Actions = styled.span`
  align-items: center;
  cursor: pointer;
  display: inline-flex;
  height: 3.5rem;
  justify-content: center;
  margin-left: auto;
  position: relative;
  width: 5.25rem;

  > svg {
    display: inline-block;
    margin: 1px;
  }

  &.open {
    ul {
      display: block;
    }
  }
`;

const ActionsMenu = styled.ul`
  box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.25);
  display: none;
  margin: 0;
  position: absolute;
  right: calc(50% - 0.75rem);
  top: calc(100% - 0.5rem);
  z-index: 1;

  li:first-child {
    &::after {
      border-bottom-width: 0.5rem;
      border-color: ${colors.gray['300']};
      border-left-color: transparent;
      border-left-width: 0.5rem;
      border-right-color: transparent;
      border-right-width: 0.5rem;
      border-top-width: 0;
      bottom: 100%;
      content: '';
      display: block;
      position: absolute;
      right: 0.25rem;
      transition: border-color 350ms;
    }

    &:hover {
      &::after {
        border-bottom-color: ${colors.gray['400']};
      }
    }
  }
`;

const ActionsMenuItem = styled.li`
  background-color: ${colors.gray['300']};
  line-height: 2em;
  list-style: none;
  padding: 0.25rem 1.5rem;
  transition: background-color 350ms, color 350ms;
  user-select: none;
  white-space: nowrap;

  a {
    color: inherit;
    text-decoration: none;
  }

  &:hover {
    background-color: ${colors.gray['400']};

    a {
      color: ${colors.cyan['400']};
    }
  }
`;

const ChecklistActions = ({ actions }: { actions: CheckListAction[] }) => {
  const [open, setOpen] = useState<boolean>(false);

  return (
    <Actions
      className={open ? 'open' : 'closed'}
      onClick={(event: React.MouseEvent) => {
        event.stopPropagation();
        setOpen(!open);
      }}
      onMouseLeave={() => setOpen(false)}
    >
      <Dot />
      <Dot />
      <Dot />
      <ActionsMenu>
        {actions.map((action, i) => (
          <ActionsMenuItem
            key={`action-${i}`}
            onClick={(event) => {
              event.stopPropagation();
              action.action(event);
              setOpen(false);
            }}
          >
            <i className={`icon-${action.icon}`} /> {action.label}
          </ActionsMenuItem>
        ))}
      </ActionsMenu>
    </Actions>
  );
};

export default ChecklistActions;
