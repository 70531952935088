import React, { useEffect, useRef, useState } from 'react';

import PathPreview from '../path-preview';

const LevelPreview = ({
  startHeightInches,
  endHeightInches,
  path,
  title,
}: {
  startHeightInches: number;
  endHeightInches: number;
  path: string;
  title: string;
}) => {
  const wrapperRef = useRef<HTMLDivElement>(null);
  const [svg, setSvg] = useState<string>();

  useEffect(() => {
    try {
      setTimeout(() => {
        const svgHtml = `<?xml version="1.0" encoding="UTF-8"?>${
          wrapperRef.current?.querySelector('svg')?.outerHTML || ''
        }`;
        setSvg(btoa(svgHtml));
      }, 0);
    } catch (e) {
      // Unable to SVG markup
    }
  }, [path]);

  return (
    <div ref={wrapperRef} className="bg-white p-8">
      <div>
        {startHeightInches}–{endHeightInches}&quot;
        <a
          className="float-right"
          download={`${title}_${startHeightInches}-${endHeightInches}.svg`}
          hrefLang="image/svg+xml"
          href={`data:application/octet-stream;base64,${svg}`}
        >
          Download
        </a>
      </div>
      <PathPreview path={path} yReflect={false} />
    </div>
  );
};

export default LevelPreview;
