/* eslint-disable @typescript-eslint/camelcase */
import { navigate } from 'gatsby';
import { RouteComponentProps } from '@reach/router';
import { useQuery, useMutation } from '@apollo/client';
import { pathOr } from 'ramda';
import React from 'react';

import { GetLogCodeQuery } from '@/generated/hasura.graphql';
import App from '@templates/app';
import EmptyPage from '@/admin/empty-page';
import { CodeFormValues } from '../types';
import {
  getAddedLogCodeRelationships,
  getCodeFormValuesFromCodeDBEntry,
  getRemovedLogCodeRelationships,
} from '../utils';
import CodeEditor from '../code-editor';

import {
  updateLogCode as updateLogCodeMutation,
  addLogCodeAudience as addLogCodeAudienceMutation,
  addLogCodeResponsibility as addLogCodeResponsibilityMutation,
  removeLogCodeAudience as removeLogCodeAudienceMutation,
  removeLogCodeResponsibility as removeLogCodeResponsibilityMutation,
  getLogCode as getLogCodeQuery,
} from '../queries';

const CodeEdit = (props: { id?: number } & RouteComponentProps) => {
  // Save Log Code
  const [updateLogCode] = useMutation(updateLogCodeMutation);
  const [addLogAudience] = useMutation(addLogCodeAudienceMutation);
  const [addLogResponsibility] = useMutation(addLogCodeResponsibilityMutation);
  const [removeLogAudience] = useMutation(removeLogCodeAudienceMutation);
  const [removeLogResponsibility] = useMutation(
    removeLogCodeResponsibilityMutation,
  );

  // Retrieve Log Code from database.
  const { loading, error, data } = useQuery<GetLogCodeQuery>(getLogCodeQuery, {
    fetchPolicy: 'no-cache',
    variables: { id: props.id },
  });

  // Show loading or problem message if we don't have data yet.
  if (loading) return <EmptyPage sectionLabel="Edit Log Code" />;
  if (error || !data)
    return <EmptyPage problem sectionLabel="Edit Log Code" />;

  const initialLogCode = getCodeFormValuesFromCodeDBEntry(data);

  // Update Log Code.
  const handleUpdate = async (logData: CodeFormValues) => {
    const updatedLogCode = {
      description: pathOr(null, ['description'], logData),
      resolution: pathOr(null, ['resolution'], logData),
      level_id: pathOr(null, ['level', 'value'], logData),
      log_system_id: pathOr(null, ['system', 'value'], logData),
      name: pathOr(null, ['name'], logData),
      operation_id: pathOr(null, ['operation', 'value'], logData),
      disruption_level_id: pathOr(null, ['disruptionLevel', 'value'], logData),
      audio_notification_id: pathOr(
        null,
        ['audioNotification', 'value'],
        logData,
      ),
    };

    await updateLogCode({
      variables: {
        ...updatedLogCode,
        id: props.id,
      },
    });

    const addedRelationships = getAddedLogCodeRelationships(
      data,
      logData,
      Number(props.id),
    );
    const removedRelationships = getRemovedLogCodeRelationships(
      data,
      logData,
      Number(props.id),
    );

    // Add & Remove relationships to Audience / Responsibility.
    const updateRelationships = [
      ...addedRelationships.audience.map((a) =>
        addLogAudience({ variables: a }),
      ),
      ...addedRelationships.responsibility.map((r) =>
        addLogResponsibility({ variables: r }),
      ),
      ...removedRelationships.audience.map((a) =>
        removeLogAudience({ variables: a }),
      ),
      ...removedRelationships.responsibility.map((r) =>
        removeLogResponsibility({ variables: r }),
      ),
    ];
    await Promise.all(updateRelationships);

    // Return to Log Codes Page
    navigate(`/codes`);
  };

  return (
    <App
      onNavBack={() => navigate('/codes')}
      section="codes"
      subtitle="Edit Log Code"
      title="Log Codes"
    >
      <CodeEditor initialLogCode={initialLogCode} save={handleUpdate}>
        <h2 className="border-b border-gray-300 font-normal mb-4 pb-2 text-xl">
          <span className="font-semibold">Code:</span> {props.id}
        </h2>
      </CodeEditor>
    </App>
  );
};

export default CodeEdit;
