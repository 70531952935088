import styled from 'styled-components';
import React from 'react';

// Function to convert number to string, adding px suffix.
const pxString = (n: number) => `${n}px`;

// Function to get breakpoint specification for media query.
const breakpoint = (props: { breakpoint: number }) =>
  `(max-width: ${pxString(props.breakpoint)})`;

// Function to get spacing value, with px suffix.
const spacing = (props: { spacing: number }) => `${pxString(props.spacing)}`;

// Flex wrapper.
const Wrapper = styled.div<{ breakpoint: number }>`
  display: flex;
  @media ${breakpoint} {
    display: block;
  }
`;

const width = ({
  width: itemWidth,
  spacing: itemSpacing,
}: {
  width: number;
  spacing: number;
}) => {
  const columns = Math.round(100 / itemWidth);
  return `calc(${itemWidth}% - ${Math.round(
    (itemSpacing * (columns - 1)) / columns,
  )}px)`;
};

// Container for each column/item in the grid.
const Item = styled.div<{
  breakpoint: number;
  spacing: number;
  width: number;
}>`
  width: ${width};
  margin-right: ${spacing};
  &:last-child {
    margin-right: 0;
  }
  @media ${breakpoint} {
    margin-right: 0;
    width: 100%;
    margin-bottom: ${spacing};
    &:last-child {
      margin-bottom: 0;
    }
  }
`;

export const GridRow = (props: {
  breakpoint: number;
  spacing: number;
  children: React.ReactNode[];
}) => (
  <Wrapper role="presentation" breakpoint={props.breakpoint}>
    {props.children.map((c, ci) => (
      <Item
        width={100 / React.Children.count(props.children)}
        breakpoint={props.breakpoint}
        spacing={props.spacing}
        key={ci}
      >
        {c}
      </Item>
    ))}
  </Wrapper>
);

export default GridRow;
