import colors from '@/constants/colors';
import styled from 'styled-components';

export const inputStyles = `
  border-radius: 0.25rem;
  border: 1px solid ${colors.gray['300']};
  color: hsl(0,0%,20%);
  display: block;
  font-weight: 300;
  padding: 6px 10px;
  transition: border 250ms;
  width: 100%;

  &:focus {
    border-color: ${colors.cyan['400']};
  }
`;

export const Input = styled.input`
  ${inputStyles}
`;

export const LabelNote = styled.span`
  font-size: 10px;
  font-style: italic;
  margin-left: 0.5rem;
`;

export const LabelUnit = styled.span`
  font-size: 12px;
  margin-left: 0.25rem;
  text-transform: none;
`;
