import React, { useEffect, useRef, useState } from 'react';
import * as R from 'ramda';
import * as Z from '@iconbuild/izzy';
import Button from '@atoms/button';
import styled from 'styled-components';
import roundTo from 'round-to';
import Circle from './circle';
import { CalibrationPoint, CALIBRATION_POINT_COUNT } from '../project-editor';

const Container = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem;
`;

const Preview = styled.div`
  background: white;
  margin-top: 32px;
`;

const Path = styled.path`
  stroke: #888;
  stroke-width: 3px;
  fill: none;
  stroke-linecap: round;
  stroke-linejoin: round;
`;

export const ProjectCalibration = ({
  sitePlan,
  points,
  setPoints,
}: {
  sitePlan: Z.SitePlan | null;
  points: CalibrationPoint[];
  setPoints: (points: CalibrationPoint[]) => void;
}) => {
  const svgRef = useRef<SVGSVGElement>(null);
  const [selectedPoint, setSelectedPoint] = useState<number | null>(null);
  const [active, setActive] = useState<boolean>(false);
  const [moveCoords, setMoveCoords] = useState<CalibrationPoint>({
    x: 0,
    y: 0,
  });
  const [svgScale, setSvgScale] = useState<CalibrationPoint>({ x: 1, y: 1 });
  const [initialPosition, setInitialPosition] = useState<CalibrationPoint>({
    x: 0,
    y: 0,
  });

  if (!sitePlan) return null;

  const viewBox = [
    0,
    -sitePlan.foundationLengthInches,
    sitePlan.foundationWidthInches,
    sitePlan.foundationLengthInches,
  ].join(' ');

  const canvasSize = {
    width: sitePlan.foundationWidthInches,
    height: sitePlan.foundationLengthInches,
  };

  const handlePointerDown = (index: number) => (e: React.MouseEvent) => {
    setActive(true);
    setSelectedPoint(index);
    setInitialPosition({
      x: e.clientX,
      y: e.clientY,
    });
    setMoveCoords(points[index]);
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handlePointerUp = (_e: React.MouseEvent) => {
    if (selectedPoint == null) return;

    setActive(false);
    setPoints(R.update(selectedPoint, moveCoords, points));
    setSelectedPoint(null);
  };

  const handlePointerMove = (e: React.MouseEvent) => {
    if (!active || selectedPoint == null) return;
    const deltaX = e.clientX - initialPosition.x;
    const deltaY = (e.clientY - initialPosition.y) * -1;
    if (selectedPoint === 0) {
      setMoveCoords({
        x: roundTo(points[selectedPoint].x + deltaX * svgScale.x, 0),
        y: roundTo(points[selectedPoint].y + deltaY * svgScale.y, 0),
      });
    }
    if (selectedPoint === 1) {
      setMoveCoords({
        x: roundTo(points[selectedPoint].x + deltaX * svgScale.x, 0),
        y: points[0].y,
      });
    }
    if (selectedPoint === 2) {
      setMoveCoords({
        x: points[1].x,
        y: roundTo(points[selectedPoint].y + deltaY * svgScale.y, 0),
      });
    }
  };

  useEffect(() => {
    if (svgRef.current) {
      const { current: el } = svgRef;
      setSvgScale({
        x: canvasSize.width / el.clientWidth,
        y: canvasSize.height / el.clientHeight,
      });
    }
  }, [svgRef]);

  useEffect(() => {
    const handleResize = () => {
      if (svgRef.current) {
        const { current: el } = svgRef;
        setSvgScale({
          x: canvasSize.width / el.clientWidth,
          y: canvasSize.height / el.clientHeight,
        });
      }
    };

    window.addEventListener('resize', handleResize);

    return (): void => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const DEFAULT_OFFSET = 6;

  const DEFAULT_POINT = {
    x: DEFAULT_OFFSET,
    y: sitePlan.foundationLengthInches - DEFAULT_OFFSET,
  };

  const addPoint = () => {
    // Match Y Position of First Point.
    if (points.length === 1) {
      setPoints([
        ...points,
        {
          x: DEFAULT_POINT.x,
          y: points[0].y,
        },
      ]);
      return;
    }

    // Match X Position of Second Point.
    if (points.length === 2) {
      setPoints([
        ...points,
        {
          x: points[1].x,
          y: DEFAULT_POINT.y,
        },
      ]);
      return;
    }

    setPoints([...points, DEFAULT_POINT]);
  };

  const deletePoint = () => {
    const pointsCopy = [...points];
    pointsCopy.pop();
    setPoints(pointsCopy);
  };

  const addCalibrationPointText = (() => {
    const baseText = 'Add Calibration Point';
    if (CALIBRATION_POINT_COUNT > 1) {
      return `${baseText} (${Math.abs(
        points.length - CALIBRATION_POINT_COUNT,
      )})`;
    }
    return baseText;
  })();

  const deleteCalibrationPointText = (() => {
    const baseText = 'Delete Calibration Point';
    if (CALIBRATION_POINT_COUNT > 1) {
      return `${baseText} (${Math.abs(points.length)})`;
    }
    return baseText;
  })();

  return (
    <Container>
      <ButtonContainer>
        <Button
          icon="plus"
          onClick={
            points.length < CALIBRATION_POINT_COUNT ? addPoint : undefined
          }
        >
          {addCalibrationPointText}
        </Button>
        <Button danger onClick={points.length > 0 ? deletePoint : undefined}>
          {deleteCalibrationPointText}
        </Button>
      </ButtonContainer>
      <Preview>
        <svg
          ref={svgRef}
          x={0}
          y={sitePlan.foundationLengthInches * -1}
          viewBox={viewBox}
          onPointerMove={handlePointerMove}
          onPointerUp={handlePointerUp}
        >
          <rect
            x={0}
            y={-sitePlan.foundationLengthInches}
            width={sitePlan.foundationWidthInches}
            height={sitePlan.foundationLengthInches}
            stroke="black"
            strokeWidth="1"
            fill="none"
          />
          {R.flatten(
            Z.sitePlanPrintLevels(sitePlan).map(Z.sitePlanPrintLevelSVGPaths),
          ).map((p) => (
            <Path key={`${Math.random()}`} d={p} />
          ))}
          {points &&
            points
              .slice(0, CALIBRATION_POINT_COUNT)
              .map((point, index) => (
                <Circle
                  key={`circle-${index}`}
                  coords={selectedPoint === index ? moveCoords : point}
                  handlePointerDown={handlePointerDown(index)}
                  active={active}
                  startY={sitePlan.foundationLengthInches * -1}
                  index={index}
                />
              ))}
        </svg>
      </Preview>
    </Container>
  );
};
