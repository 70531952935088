import React, { useState } from 'react';
import styled from 'styled-components';

import { cyan, red } from '@data/colors';
import Label from '@atoms/label';

const Container = styled.div`
  input[type='range'],
  input[type='text'],
  input[type='number'] {
    width: 100%;
  }
  label {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;

const InputField = styled.input<{ error: boolean }>`
  -moz-box-shadow: none;
  -webkit-box-shadow: none;
  background-color: transparent;
  background-image: none;
  border-color: #eee;
  border-style: solid;
  border-width: 0 0 1px 0;
  box-shadow: none;
  color: black;
  font-family: 'Roboto';
  font-size: 16px;
  font-weight: 600;
  max-width: 100%;
  padding: 0 0 2px 0;
  transition: border-color 300ms;

  &:hover {
    border-bottom: 1px solid #ddd;
  }

  &:disabled:hover {
    border-bottom: 1px solid #eee;
  }

  &:focus,
  &:focus:hover {
    border-bottom: 1px solid ${({ error }) => (error ? red : cyan)};
  }

  &::-webkit-inner-spin-button,
  input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

const InputDataField = ({
  label,
  value,
  handleChange,
  reset,
  error,
  slider,
  step,
  type,
}: {
  label: string;
  value: number | string;
  handleChange: (value: string) => void;
  reset: () => void;
  error: boolean;
  slider?: {
    minimum: number;
    maximum: number;
  };
  step?: number;
  type?: string;
}) => {
  const handleFieldChange = (e: React.ChangeEvent<HTMLInputElement>) =>
    handleChange(e.target.value);
  const handleBlur = () => {
    if (error) reset();
  };
  return (
    <Container>
      <Label>{label}</Label>
      <InputField
        onBlur={handleBlur}
        error={error}
        value={value}
        type={type || 'text'}
        onChange={handleFieldChange}
      />
      {slider && (
        <input
          min={slider.minimum}
          max={slider.maximum}
          step={step || 1}
          type="range"
          value={value}
          onChange={handleFieldChange}
        />
      )}
    </Container>
  );
};

export const NumberDataField = ({
  value,
  setValue,
  label,
  slider,
  step,
}: {
  value: number;
  setValue: (v: number) => void;
  label: string;
  slider?: {
    minimum: number;
    maximum: number;
  };
  step?: number;
}) => {
  const [state, setState] = useState(`${value}`);
  const [error, setError] = useState(false);
  const handleChange = (newValue: string) => {
    setState(newValue);
    const parsed = parseFloat(newValue);
    if (isNaN(parsed)) {
      setError(true);
      return;
    }
    if (slider && (parsed > slider.maximum || parsed < slider.minimum)) {
      setError(true);
      return;
    }
    if (error) setError(false);
    setValue(parsed);
  };
  const reset = () => {
    setState(`${value}`);
    setError(false);
  };
  return React.createElement(InputDataField, {
    label,
    type: 'number',
    error,
    value: state,
    reset,
    handleChange,
    slider,
    step,
  });
};

export const TextDataField = ({
  value,
  setValue,
  label,
}: {
  value: string;
  setValue: (v: string) => void;
  label: string;
}) => {
  const [state, setState] = useState(`${value}`);
  const [error, setError] = useState(false);
  const handleChange = (newValue: string) => {
    setState(newValue);
    if (!newValue.length) {
      setError(true);
      return;
    }
    if (error) setError(false);
    setValue(newValue);
  };
  const reset = () => {
    setState(value);
    setError(false);
  };
  return React.createElement(InputDataField, {
    label,
    error,
    value: state,
    reset,
    handleChange,
  });
};

const Checkbox = styled.label`
  align-items: center;
  display: inline-flex;

  span {
    display: inline-block;
    font-size: 12px;
    line-height: 1em;
    margin-left: 0.75em;
    text-transform: uppercase;
    user-select: none;
  }
`;

export const BooleanDataField = ({
  value,
  setValue,
  formatter = (v: boolean) => (v ? 'on' : 'off'),
  label,
}: {
  value: boolean;
  setValue: (v: boolean) => void;
  label: string;
  formatter?: (v: boolean) => string;
}) => {
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) =>
    setValue(e.target.checked);
  return (
    <Container>
      <Label>{label}</Label>
      <Checkbox>
        <input onChange={handleChange} type="checkbox" checked={value} />
        <span>{formatter(value)}</span>
      </Checkbox>
    </Container>
  );
};

export default NumberDataField;
