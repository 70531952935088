import React from 'react';
import styled from 'styled-components';

import colors from '@/constants/colors';

export enum Color {
  cyan = 'cyan',
  gray = 'gray',
  green = 'green',
  orange = 'orange',
  pink = 'pink',
  purple = 'purple',
  red = 'red',
  yellow = 'yellow',
}

enum Shade {
  s100 = '100',
  s200 = '200',
  s300 = '300',
  s400 = '400',
  s500 = '500',
  s600 = '600',
  s700 = '700',
  s800 = '800',
  s900 = '900',
}

interface TagProps {
  children: React.ReactNode;
  color?: Color;
}

interface ContainerProps {
  color: Color;
}

const getColor = (color: Color, shade: Shade = Shade.s500) => {
  if (typeof color === 'undefined') return colors.gray[shade];
  return colors[color][shade];
};

const Container = styled.span<ContainerProps>`
  background-color: ${({ color }) => getColor(color, Shade.s400)};
  border-color: ${({ color }) => getColor(color, Shade.s400)};
  color: ${({ color }) => (color ? colors.white : colors.gray['700'])};
  display: inline-block;
  letter-spacing: 0.05em;
`;

export const Tag = ({ children, color = Color.gray }: TagProps) => {
  return (
    <Container
      data-testid="tag"
      className="border font-semibold px-4 py-1 rounded text-xs uppercase"
      color={color}
    >
      {children}
    </Container>
  );
};

export default Tag;
