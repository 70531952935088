import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  flex-direction: column;
  text-align: center;
`;

const BigText = styled.div`
  font-size: 40px;
  text-transform: uppercase;
  color: #777;
`;

const SmallText = styled.div`
  color: #999;
`;

export const Problem = () => (
  <Wrapper>
    <div>
      <BigText>Something went wrong!</BigText>
      <SmallText>Maybe try again?</SmallText>
    </div>
  </Wrapper>
);

export default Problem;
