import App from '@templates/app';
import { navigate } from 'gatsby';
import React, { useState } from 'react';
import { RouteComponentProps } from '@reach/router';
import { useMutation, useQuery } from '@apollo/client';
import {
  GetAdmixtureFunctionsQuery,
  GetAdmixtureQuery,
} from '@/generated/hasura.graphql';
import { pathOr } from 'ramda';
import EmptyPage from '@/admin/empty-page';
import Lightbox from '@molecules/lightbox';
import AlertMessage from '@molecules/alert-message';

import { AdmixtureForm } from '../types';
import { AdmixtureEditor } from '../admixture-editor';
import {
  getAdmixture,
  getAdmixtureFunctions,
  updateAdmixture as updateAdmixtureMutation,
} from '../queries';
import { getAdmixtureFormFromDBEntry } from '../utils';

const AdmixtureEdit = (props: { id?: string } & RouteComponentProps) => {
  // Retrieve Admixture from database.
  const admixtureResult = useQuery<GetAdmixtureQuery>(getAdmixture, {
    fetchPolicy: 'no-cache',
    variables: { key: props.id },
  });
  const admixtureFunctionsResult = useQuery<GetAdmixtureFunctionsQuery>(
    getAdmixtureFunctions,
    {
      fetchPolicy: 'no-cache',
    },
  );

  const [updateAdmixture] = useMutation(updateAdmixtureMutation);

  const [showError, setShowError] = useState<boolean>(false);

  // Show loading or problem message if we don't have data yet.
  if (admixtureResult.loading || admixtureFunctionsResult.loading)
    return <EmptyPage sectionLabel="Edit Admixture" />;
  if (
    admixtureResult.error ||
    admixtureFunctionsResult.error ||
    !admixtureResult.data ||
    !admixtureFunctionsResult.data
  )
    return <EmptyPage problem sectionLabel="Edit Admixture" />;

  const initialAdmixture = getAdmixtureFormFromDBEntry(
    pathOr(undefined, ['admixture_by_pk'], admixtureResult.data),
  );
  if (!initialAdmixture)
    return <EmptyPage problem sectionLabel="Edit Admixture" />;

  const admixtureFunctions = admixtureFunctionsResult.data.admixture_function.map(
    (af) => ({
      value: af.key,
      label: af.label,
    }),
  );

  const handleUpdate = async (admixture: AdmixtureForm) => {
    try {
      await updateAdmixture({
        variables: {
          key: admixture.key,
          name: admixture.name,
          density: admixture.density,
          enforcedPriority: admixture.enforcedPriority,
          dosingStrategy:
            admixture.dosing != null
              ? JSON.stringify(admixture.dosing)
              : undefined,
          admixtureFunctionKey: admixture.admixtureFunctionKey,
          producer: admixture.producer,
        },
      });

      navigate(`/admixtures`);
    } catch (e) {
      setShowError(true);
    }
  };

  return (
    <App
      onNavBack={() => navigate('/admixtures')}
      section="admixtures"
      subtitle="Edit Admixture"
      title="Admixtures"
    >
      {showError && (
        <Lightbox title="Error" close={() => setShowError(false)}>
          <AlertMessage
            message="Oops, something went wrong when saving the admixture."
            onClick={() => setShowError(false)}
          />
        </Lightbox>
      )}
      <AdmixtureEditor
        admixtureFunctions={admixtureFunctions}
        initialAdmixture={initialAdmixture}
        save={handleUpdate}
      >
        <h2 className="border-b border-gray-300 font-normal mb-4 pb-2 text-xl">
          <span className="font-semibold">Admixture:</span>
          <span className="text-sm ml-2 text-gray-500">{props.id}</span>
        </h2>
      </AdmixtureEditor>
    </App>
  );
};

export default AdmixtureEdit;
