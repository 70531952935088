import gql from 'graphql-tag';

import { SassCalFields } from './fragments';

export const addSassCalibrationData = gql`
  ${SassCalFields}
  mutation UpsertSassCalibration(
    $camera_serial: String!
    $calibration_data: String!
    $created: timestamptz!
    $updated: timestamptz!
    $username: String!
  ) {
    insert_sass_factory_calibration_one(
      object: {
        camera_serial: $camera_serial
        calibration_data: $calibration_data
        created: $created
        updated: $updated
        username: $username
      }
      on_conflict: {
        constraint: sass_factory_calibration_pkey
        update_columns: [calibration_data, updated, username]
      }
    ) {
      ...SassCalFields
    }
  }
`;
