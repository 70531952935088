import { useQuery } from '@apollo/client';
import React, { useState } from 'react';
import styled from 'styled-components';

import colors from '@/constants/colors';
import {
  GetSystemsNameAndSerialQuery,
  System,
} from '@/generated/hasura.graphql';
import Loading from '@molecules/loading';
import Problem from '@molecules/problem';
import Select from '@/components/atoms/select';
import { ValueType } from 'react-select';
import { formatSystemLabel } from '@/admin/utils';
import { getSystemsNameAndSerial as getSystemsQuery } from './queries';

type Item = { value: number; label: string; system?: System };

const Container = styled.div`
  background-color: white;
  border-color: ${colors.gray['200']};
  border-width: 1px;
  border-radius: 4px;
  padding: 0 0 0 0.75rem;
  display: flex;
  align-items: center;

  > *:last-child {
    min-width: 8rem;
  }

  [class*='control'] {
    border-width: 0;
  }
`;

const SystemSelect = ({
  className,
  onChange,
}: {
  className?: string;
  onChange: (selectedSystems: System[]) => void;
}) => {
  // Get printer data from database.
  const { data, loading, error } = useQuery<GetSystemsNameAndSerialQuery>(
    getSystemsQuery,
  );

  const [selectedSystems, setSelectedSystems] = useState<System[]>([]);

  const selectChangeHandler = (selectedItems: ValueType<Item>) => {
    // The declared type for arg selectedItems is singular but the real type is
    // an array The typings for our Select atom (and the internal react-select
    // Select) are not comprehensive

    // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
    // @ts-ignore
    const systems = (selectedItems || []).map((x) => x.system);
    setSelectedSystems(systems);
    onChange(systems);
  };

  // Show loading or problem message if we don't have data yet.
  if (loading) return <Loading />;
  if (error || !data?.system) return <Problem />;

  // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
  // @ts-ignore
  return (
    <Container className={className}>
      <label className="text-sm uppercase font-semibold mr-2 text-gray-800">
        System:
      </label>
      <Select
        isMulti
        isClearable
        onChange={selectChangeHandler}
        value={selectedSystems.map((x) => ({
          value: x.id,
          label: formatSystemLabel(x),
          system: x,
        }))}
        options={data.system.map((s) => ({
          value: s.id,
          // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
          // @ts-ignore
          label: formatSystemLabel(s),
          system: s,
        }))}
      />
    </Container>
  );
};

export default SystemSelect;
