const breakpoints: [number, number, number, number, number, number] = [
  375,
  480,
  720,
  1024,
  1280,
  1680,
];

export const maxWidth = Math.max(...breakpoints);
export default breakpoints;
