import React from 'react';
import styled from 'styled-components';
import { Helmet } from 'react-helmet';
import breakpoints from '@data/breakpoints';
import AlertSticky, { AlertMessage } from '@atoms/alert/alert-sticky';
import Header from '@organisms/header';
import TabbedNav, { NavItem } from '@molecules/nav-tabbed';
import Global from '../../global-styles';
import AdminMenu from './admin-menu';
import TitleBar from './title-bar';

// Container is given a max-height to allow the
// AlertSticky component to display correctly.
const Container = styled.div`
  max-height: 100vh;
  overflow: auto;
`;

const ContentWrapper = styled.div`
  background: #f1f1f1;
  flex: 1;
  display: flex;
`;

const Main = styled.div`
  display: flex;
  width: 100%;
  align-items: justify-content;
  min-height: 800px;
`;

const MainContent = styled.div`
  background: white;
  display: flex;
  flex: 1;
  flex-direction: column;
`;

// Wrapper for central content area, including subnav.
const Center = styled.div`
  display: flex;
  flex-flow: column;
  flex: 1;
  position: relative;
  height: 100%;
`;

const FlexGrow = styled.div`
  flex-grow: 1;
  height: 100%;
`;

const SubNav = styled.div`
  background: white;
  height: 48px;
`;

// Wrapper for the content area in center.
const Content = styled.div`
  padding: 2rem 2rem 2rem;
  height: 100%;
`;

// Optional detail side bar.
const DetailPanel = styled.div`
  width: 300px;
  background: white;
  padding: 16px;
  border-left: 1px solid #eee;
`;

const MenuWrapper = styled.div`
  width: 240px;
  > div {
    height: 100%;
  }
  @media (max-width: ${breakpoints[3]}px) {
    display: none;
  }
`;

export interface Props {
  section: string;
  children?: React.ReactNode;
  title: string;
  subtitle?: string;
  onNavBack?: () => void;
  panel?: React.ReactNode;
  action?: React.ReactNode;
  subNav?: NavItem[];
  alert?: AlertMessage;
}

export const App = ({
  section,
  children,
  title,
  subtitle,
  onNavBack,
  panel,
  action,
  subNav,
  alert,
}: Props) => (
  <>
    <Global />
    <Helmet>
      <title>{`${title} ${subtitle || ''}`} | ICON Admin</title>
      <link
        rel="icon"
        type="image/png"
        href="/favicon-32x32.png"
        sizes="32x32"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="16x16"
        href="/favicon-16x16.png"
      />
    </Helmet>
    <Container>
      <Header extraNav={<AdminMenu section={section} />} />
      <Main>
        <MenuWrapper>
          <AdminMenu section={section} />
        </MenuWrapper>
        <MainContent>
          <TitleBar {...{ action, title, subtitle, onNavBack }} />
          <ContentWrapper>
            <Center>
              <FlexGrow>
                {subNav && (
                  <SubNav>
                    <TabbedNav
                      items={subNav}
                      display="marker"
                      marker="#f1f1f1"
                    />
                  </SubNav>
                )}
                <Content>{children}</Content>
              </FlexGrow>
              {alert && <AlertSticky alert={alert} />}
            </Center>
            {panel && <DetailPanel>{panel}</DetailPanel>}
          </ContentWrapper>
        </MainContent>
      </Main>
    </Container>
  </>
);

export default App;
