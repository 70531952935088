import React from 'react';
import styled from 'styled-components';

const Container = styled.label`
  color: #999;
  display: block;
  font-size: 12px;
  font-weight: 500;
  margin-bottom: 4px;
  text-transform: uppercase;
`;

export const Label = ({
  children,
  className = '',
  htmlFor,
}: {
  children: React.ReactNode | React.ReactNode[];
  className?: string;
  htmlFor?: string;
}) => (
  <Container className={className} htmlFor={htmlFor}>
    {children}
  </Container>
);

export default Label;
