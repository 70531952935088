import { path } from 'ramda';
import React from 'react';

import {
  ColumnHead,
  Container,
  Row,
  Spacer,
  Table,
} from '@organisms/table-view/elements';

interface Data<T extends { id: number }> {
  fields: {
    ref: string;
    label: string;
    unitLabel?: React.ReactNode | React.ReactNode[];
    formatter?: (d: unknown, item: T) => string | JSX.Element;
  }[];
  rows: T[];
}

export function ListView<T extends { id: number }>({
  data,
  selected,
  setSelected,
  hover,
}: {
  data: Data<T>;
  selected?: number;
  setSelected?: (id: number) => void;
  hover?: (id: number) => void;
}) {
  return (
    <Container>
      <Table>
        <thead>
          <tr>
            {data.fields.map(({ ref, label, unitLabel }) => (
              <ColumnHead key={ref}>
                {label}
                {unitLabel && <span> ({unitLabel})</span>}
              </ColumnHead>
            ))}
          </tr>
          <Spacer />
        </thead>
        <tbody>
          {data.rows.length ? (
            data.rows.map((row) => (
              <Row
                readonly={!setSelected}
                key={row.id}
                onClick={() => {
                  if (!setSelected) return;
                  setSelected(selected === row.id ? -1 : row.id);
                }}
                onMouseOver={() => hover && hover(row.id)}
                onFocus={() => hover && hover(row.id)}
                selected={selected === row.id}
              >
                {data.fields.map(({ ref, formatter = (d) => `${d}` }) => {
                  const loc = ref.split('.');
                  return <td key={ref}>{formatter(path(loc, row), row)}</td>;
                })}
              </Row>
            ))
          ) : (
            <tr>
              <td
                colSpan={data.fields.length}
                className="italic px-3 text-gray-600 text-sm"
              >
                Empty
              </td>
            </tr>
          )}
        </tbody>
      </Table>
    </Container>
  );
}

export default ListView;
