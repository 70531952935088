import gql from 'graphql-tag';

export const getActiveAdmixtures = gql`
  query getActiveAdmixtures {
    admixture(where: { archived: { _eq: false } }, order_by: { name: asc }) {
      key
      name
      density
      archived
      enforced_priority
      dosing_strategy
      admixture_function_key
      producer
    }
  }
`;

export const getAdmixtureFunctions = gql`
  query getAdmixtureFunctions {
    admixture_function {
      key
      label
    }
  }
`;

export const getAllAdmixtures = gql`
  query getAllAdmixtures {
    admixture(order_by: { name: asc }) {
      key
      name
      density
      archived
      enforced_priority
      dosing_strategy
      admixture_function_key
      producer
    }
  }
`;

export const getAllAdmixtureKeys = gql`
  query getAllAdmixtureKeys {
    admixture {
      key
    }
  }
`;

export const getAdmixture = gql`
  query getAdmixture($key: String!) {
    admixture_by_pk(key: $key) {
      key
      name
      density
      archived
      enforced_priority
      dosing_strategy
      admixture_function_key
      producer
    }
  }
`;

export const archiveAdmixture = gql`
  mutation archiveAdmixture($key: String!) {
    update_admixture(where: { key: { _eq: $key } }, _set: { archived: true }) {
      affected_rows
    }
  }
`;

export const addAdmixture = gql`
  mutation addAdmixture(
    $key: String
    $name: String
    $density: numeric
    $enforcedPriority: Int
    $dosingStrategy: json
    $admixtureFunctionKey: String
    $producer: String
  ) {
    insert_admixture_one(
      object: {
        key: $key
        name: $name
        density: $density
        archived: false
        enforced_priority: $enforcedPriority
        dosing_strategy: $dosingStrategy
        admixture_function_key: $admixtureFunctionKey
        producer: $producer
      }
    ) {
      key
    }
  }
`;

export const updateAdmixture = gql`
  mutation updateAdmixture(
    $key: String!
    $name: String
    $density: numeric
    $enforcedPriority: Int
    $dosingStrategy: json
    $admixtureFunctionKey: String
    $producer: String
  ) {
    update_admixture(
      where: { key: { _eq: $key } }
      _set: {
        name: $name
        density: $density
        archived: false
        enforced_priority: $enforcedPriority
        dosing_strategy: $dosingStrategy
        admixture_function_key: $admixtureFunctionKey
        producer: $producer
      }
    ) {
      affected_rows
    }
  }
`;
