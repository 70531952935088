import gql from 'graphql-tag';

export const getLogCodes = gql`
  query getLogCodes {
    code(order_by: { id: asc }) {
      id
      name
      description
      resolution
      archived
      disruption_level {
        id
        name
      }
      operation {
        id
        name
      }
      system {
        id
        name
      }
      code_responsibilities {
        responsibility {
          id
          name
        }
      }
      code_audiences {
        audience {
          id
          name
        }
      }
      level {
        name
        id
      }
      audio_notification {
        id
        name
      }
    }
  }
`;

export const addLogCode = gql`
  mutation addLogCode(
    $description: String
    $resolution: String
    $level_id: Int
    $disruption_level_id: Int
    $name: String
    $operation_id: Int
    $log_system_id: Int
    $audio_notification_id: Int
  ) {
    insert_code_one(
      object: {
        description: $description
        resolution: $resolution
        level_id: $level_id
        disruption_level_id: $disruption_level_id
        name: $name
        operation_id: $operation_id
        log_system_id: $log_system_id
        audio_notification_id: $audio_notification_id
      }
    ) {
      id
    }
  }
`;

export const addLogCodeAudience = gql`
  mutation addLogCodeAudience($audience_id: Int, $code_id: Int) {
    insert_code_audience(
      objects: { audience_id: $audience_id, code_id: $code_id }
    ) {
      affected_rows
    }
  }
`;

export const removeLogCodeAudience = gql`
  mutation removeLogCodeAudience($audience_id: Int!, $code_id: Int!) {
    delete_code_audience_by_pk(audience_id: $audience_id, code_id: $code_id) {
      audience_id
    }
  }
`;

export const addLogCodeResponsibility = gql`
  mutation addLogCodeResponsibility($responsibility_id: Int, $code_id: Int) {
    insert_code_responsibility(
      objects: { responsibility_id: $responsibility_id, code_id: $code_id }
    ) {
      affected_rows
    }
  }
`;

export const removeLogCodeResponsibility = gql`
  mutation removeLogCodeResponsibility(
    $code_id: Int!
    $responsibility_id: Int!
  ) {
    delete_code_responsibility_by_pk(
      code_id: $code_id
      responsibility_id: $responsibility_id
    ) {
      responsibility_id
    }
  }
`;

export const updateLogCode = gql`
  mutation updateLogCode(
    $description: String
    $resolution: String
    $id: Int
    $level_id: Int
    $disruption_level_id: Int
    $name: String
    $operation_id: Int
    $log_system_id: Int
    $audio_notification_id: Int
  ) {
    update_code(
      where: { id: { _eq: $id } }
      _set: {
        description: $description
        resolution: $resolution
        level_id: $level_id
        disruption_level_id: $disruption_level_id
        name: $name
        operation_id: $operation_id
        log_system_id: $log_system_id
        audio_notification_id: $audio_notification_id
      }
    ) {
      affected_rows
    }
  }
`;

export const archiveLogCode = gql`
  mutation archiveLogCode($id: Int) {
    update_code(where: { id: { _eq: $id } }, _set: { archived: true }) {
      affected_rows
    }
  }
`;

export const getLogCodeOptions = gql`
  query getLogCodeOptions {
    audience {
      id
      name
    }
    level {
      id
      name
    }
    operation {
      id
      key
      name
    }
    responsibility {
      id
      name
    }
    system: log_system {
      id
      key
      name
    }
    disruption_level: disruption_level {
      id
      name
    }
    audio_notification {
      id
      name
    }
  }
`;

export const getLogCode = gql`
  query getLogCode($id: Int!) {
    code_by_pk(id: $id) {
      id
      name
      description
      resolution
      archived
      operation {
        id
        name
      }
      disruption_level {
        id
        name
      }
      system {
        id
        name
      }
      code_responsibilities {
        responsibility {
          id
          name
        }
      }
      code_audiences {
        audience {
          id
          name
        }
      }
      level {
        name
        id
      }
      audio_notification {
        id
        name
      }
    }
  }
`;

export const addOperation = gql`
  mutation addOperation($name: String, $key: String) {
    insert_operation_one(object: { name: $name, key: $key }) {
      id
      name
    }
  }
`;
