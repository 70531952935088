import React from 'react';

interface SvgProps {
  className?: string;
}

function Close(props: SvgProps) {
  return (
    <svg viewBox="0 0 35 36" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.5 21.535l13.732 13.733 3.536-3.536L21.035 18 34.769 4.268 31.232.732 17.5 14.464 3.768.732.232 4.268 13.964 18 .232 31.732l3.536 3.536L17.5 21.535z"
        fill="currentColor"
      />
    </svg>
  );
}

export default Close;
