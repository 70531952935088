import gql from 'graphql-tag';

// This query is for the table view & only queries for the latest 300
// print events to avoid a huge slowdown
export const getPrintEvents = gql`
  query getPrintEvents(
    $startDate: timestamptz
    $endDate: timestamptz
    $systemIds: [Int!]
    $projectIds: [numeric!]
    $types: [String!]
    $limit: Int
    $offset: Int
  ) {
    print_event(
      limit: $limit
      offset: $offset
      order_by: { occurred_at: desc }
      where: {
        _and: {
          occurred_at: { _gte: $startDate, _lte: $endDate }
          system_id: { _in: $systemIds }
          project_id: { _in: $projectIds }
          type: { _in: $types }
        }
      }
    ) {
      height
      id
      item_index
      layer
      occurred_at
      updated_at
      type
      tool_path_inches
      system {
        name
        serial
      }
      structure_id
      saturn_process_id
      project {
        id
        label
      }
      dry_run
      payload
      print_id
      site_plan_type
    }
  }
`;
