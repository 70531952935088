import { navigate } from 'gatsby';
import { useQuery, useMutation } from '@apollo/client';
import { ValueType } from 'react-select';
import React, { useState } from 'react';
import styled from 'styled-components';

import {
  GetLogCodeOptionsQuery,
  GetLogCodeQuery,
  GetLogCodesQuery,
} from '@/generated/hasura.graphql';
import { Container } from '@organisms/table-view/elements';
import ConfirmationMessage from '@molecules/confirmation-message';
import Lightbox from '@molecules/lightbox';
import App from '@templates/app';
import Button from '@atoms/button';
import EmptyPage from '@/admin/empty-page';
import Input from '@atoms/input';
import Label from '@atoms/label';
import Select from '@atoms/select';
import {
  getLogCodeOptions as getLogCodeOptionsQuery,
  getLogCodes as getLogCodesQuery,
  archiveLogCode as archiveLogCodeMutation,
} from '../queries';
import { mapToOptions } from '../utils';
import { CodeFormValues } from '../types';
import CodeItem from './code-item';

const Wrapper = styled.div`
  overflow: auto;
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: calc(100% - 16px);
`;

const CodeList = () => {
  // Search Text State
  const [searchFilters, setSearchFilters] = useState<
    Pick<CodeFormValues, 'system' | 'level'> & { search: string }
  >({ search: '', system: null, level: null });

  // Display warning modal to user when missing calibration points.
  const [confirmationOpen, setConfirmationOpen] = useState(false);

  const [checked, setChecked] = useState<Array<number>>([]);

  // Retrieve Options from SOL.
  const {
    loading: loadingOptions,
    error: errorOptions,
    data: dataOptions,
  } = useQuery<GetLogCodeOptionsQuery>(getLogCodeOptionsQuery);

  // Retrieve Log Codes from database.
  const { loading, error, data } = useQuery<GetLogCodesQuery>(
    getLogCodesQuery,
    {
      fetchPolicy: 'no-cache',
    },
  );

  const [archiveLog] = useMutation(archiveLogCodeMutation);

  // Show loading or problem message if we don't have data yet.
  if (loading || loadingOptions) return <EmptyPage sectionLabel="Log Codes" />;
  if (error || errorOptions || !data || !dataOptions)
    return <EmptyPage problem sectionLabel="Log Codes" />;

  // Map Options for Select Inputs
  const options = {
    level: mapToOptions(dataOptions.level),
    system: mapToOptions(dataOptions.system),
  };

  const handleSearchSelectChange = (key: string) => (
    selectedOption: ValueType<{ value: number; label: string }>,
  ): void => {
    setSearchFilters({
      ...searchFilters,
      [key]: selectedOption,
    });
  };

  const handleSearchTextChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ): void => {
    setSearchFilters({
      ...searchFilters,
      search: event.target.value,
    });
  };

  const removeLogCodeFromChecked = (index: number) => {
    const removedArr = [
      ...checked.slice(0, index),
      ...checked.slice(index + 1),
    ];
    setChecked(removedArr);
  };

  const handleOnChecked = (logCodeId: number): void => {
    const index = checked.indexOf(logCodeId);
    if (index === -1) {
      setChecked([...checked, logCodeId]);
    } else {
      removeLogCodeFromChecked(index);
    }
  };

  const handleArchive = (): void => {
    checked.forEach((logCodeId: number) => {
      archiveLog({ variables: { id: logCodeId } });
    });
    setConfirmationOpen(false);
    setChecked([]);
  };

  const isSearchTextMatch = (row: GetLogCodeQuery['code_by_pk']) => {
    if (!searchFilters.search) return true;
    const re = new RegExp(searchFilters.search, 'i');
    return (
      re.test(row?.name || '') ||
      re.test(row?.system?.name || '') ||
      re.test(String(row?.id))
    );
  };

  const isSelectMatch = (key: 'system' | 'level') => (
    row: GetLogCodeQuery['code_by_pk'],
  ): boolean => {
    if (!searchFilters[key]) return true;
    return searchFilters[key]?.value === row?.[key]?.id;
  };

  const isNotArchived = (row: GetLogCodeQuery['code_by_pk']): boolean => {
    return !row?.archived;
  };

  const isFilterMatch = (row: GetLogCodeQuery['code_by_pk']) => {
    return (
      isNotArchived(row) &&
      isSearchTextMatch(row) &&
      isSelectMatch('system')(row) &&
      isSelectMatch('level')(row)
    );
  };

  return (
    <App section="codes" title="Log Codes">
      <Wrapper>
        <Container className="mb-4">
          <div className="bg-white p-4 pt-3 rounded grid grid-cols-4 gap-4">
            <div className="col-span-2">
              <Label>Search</Label>
              <Input onChange={handleSearchTextChange} placeholder="Search" />
            </div>
            <div>
              <Label>Level</Label>
              <Select
                isClearable
                onChange={handleSearchSelectChange('level')}
                options={options.level}
                value={searchFilters.level}
              />
            </div>
            <div>
              <Label>System</Label>
              <Select
                isClearable
                onChange={handleSearchSelectChange('system')}
                options={options.system}
                value={searchFilters.system}
              />
            </div>
          </div>
        </Container>
        <ButtonContainer className="mt-4 mb-4">
          <Button icon="plus" onClick={() => navigate('/codes/add')}>
            Add Log Code
          </Button>
          <Button
            className=" ml-4"
            onClick={
              checked.length > 0 ? () => setConfirmationOpen(true) : undefined
            }
          >
            Archive
          </Button>
        </ButtonContainer>
        {confirmationOpen && (
          <Lightbox title="Warning" close={() => setConfirmationOpen(false)}>
            <ConfirmationMessage
              action={() => handleArchive()}
              cancel={() => setConfirmationOpen(false)}
              message="Are you sure you want to archive Log Codes?"
            />
          </Lightbox>
        )}
        <div>
          {data.code.filter(isFilterMatch).map((code) => (
            <CodeItem
              key={code.id}
              code={code}
              handleOnChecked={() => handleOnChecked(code.id)}
            />
          ))}
        </div>
      </Wrapper>
    </App>
  );
};

export default CodeList;
