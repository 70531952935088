import styled from 'styled-components';

const PanelSticky = styled.div`
  @media (min-height: 45rem) {
    position: sticky;
    top: 1rem;
  }
`;

export default PanelSticky;
