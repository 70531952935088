import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { cyan, purple, red } from '@data/colors';

import colors from '@/constants/colors';
import fontSize from '@/constants/font-size';
import screens from '@/constants/screens';

export enum TextAreaTheme {
  Admin = 'admin',
  Default = 'default',
  Contact = 'contact',
}

interface TextAreaProps {
  onChange: (value: string) => void;
  placeholder: string;
  theme: TextAreaTheme;
  value: string;
}

const borderColor = ({ error }: { error: boolean }) => {
  if (error) {
    return `border-bottom-color: ${red};`;
  }

  return css`
    border-bottom-color: ${cyan};
    border-image-slice: 1;
    border-image-source: linear-gradient(135deg, ${cyan} 0%, ${purple} 100%);
  `;
};

const width = ({ error }: { error: boolean }) => (error ? '100%' : '0');

const TextAreaEl = styled.textarea<{ error: boolean; theme: TextAreaTheme }>`
  width: 100%;

  ${({ theme }: { theme: TextAreaTheme }) => {
    // Contact Page Text Input Styles
    if (theme === TextAreaTheme.Contact) {
      return css`
        backdrop-filter: blur(4px);
        background: rgba(255, 255, 255, 0.1);
        border-radius: 5px;
        border: 1px solid white;
        color: white;
        display: block;
        font-size: 1.75rem;
        font-weight: 200;
        height: 117px;
        line-height: 1.2;
        padding: 7px 10px;
        transition: border 250ms;

        ::placeholder {
          color: white;
          font-weight: 200;
          opacity: 0.85;
        }

        &:focus {
          border-color: ${cyan};
        }

        * + & {
          margin-top: 10px;
        }

        @media (max-width: ${screens.lg}) {
          font-size: ${fontSize['2xl']};
        }
      `;
    }

    if (theme === TextAreaTheme.Admin) {
      return css`
        border-radius: 0.25rem;
        border: 1px solid ${colors.gray['300']};
        color: hsl(0, 0%, 20%);
        display: block;
        font-weight: 300;
        padding: 6px 10px;
        transition: border 250ms;
        width: 100%;

        &:focus {
          border-color: ${colors.cyan['400']};
        }
      `;
    }

    // Default styles include a focus indicator line.
    return css`
      input:focus + .indicator {
        width: 100%;
      }

      .indicator {
        transition: all 200ms;
        width: ${width};
        ${borderColor}
      }
    `;
  }}
`;

export const TextArea = ({
  onChange,
  placeholder = '',
  theme = TextAreaTheme.Default,
  value,
}: TextAreaProps) => {
  const [currentValue, setCurrentValue] = useState<string>(value);

  return (
    <TextAreaEl
      error={false}
      theme={theme}
      rows={3}
      placeholder={placeholder}
      onChange={(event: React.ChangeEvent<HTMLTextAreaElement>) => {
        setCurrentValue(event.target.value);
        onChange(event.target.value);
      }}
      value={currentValue}
    />
  );
};

export default TextArea;
