import styled from 'styled-components';

export const ColumnHead = styled.th`
  text-align: left;
  font-weight: 500;
  text-transform: uppercase;
  font-family: 'Montserrat', sans-serif;

  // units will come in as inner spans
  span {
    text-transform: none;
    font-weight: 400;
    font-style: italic;
  }
`;

export const Container = styled.div`
  background: white;
  border-radius: 4px;
  width: 100%;
  padding: 0;
`;

export const Row = styled.tr<{ selected: boolean; readonly: boolean }>`
  background: ${({ selected }) => (selected ? '#eee' : 'none')};
  cursor: ${({ readonly }) => (readonly ? 'default' : 'pointer')};

  &:hover {
    background: ${({ readonly, selected }) => {
      if (readonly) return 'none';
      return selected ? '#eee' : '#f8f8f8';
    }};
  }
`;

export const Spacer = styled.tr`
  border-bottom: 0 !important;
  height: 8px;
`;

export const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  & thead tr {
    border-bottom: 1px solid #ccc;
  }
  td,
  th {
    padding: 8px 12px;

    &:first-child {
      padding-left: 1.25rem;
    }

    &:last-child {
      padding-right: 1.25rem;
    }
  }
`;
