import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { omit } from 'ramda';
import { ValidationError } from 'jsonschema';
import Label from '@atoms/label';
import Select from '@atoms/select';
import Button from '@atoms/button';
import TableView from '@organisms/table-view/table-view';
import FormError from '@atoms/form-error';
import { ToggleSwitch } from '@atoms/toggle-switch';

import { AdmixtureSteppedDosingForm, DosingStepRow } from '../types';
import { Input, LabelUnit } from './styles';
import SteppedDosingGraph from './stepped-dosing-graph';
import { toErrorMessage } from './validator';
import { celsiusToFahrenheit, fahrenheitToCelsius } from '../utils';

const stepSourceVariables = [
  {
    value: 1,
    key: 'ambient_temperature',
    label: 'Ambient temperature',
    unit: '\xB0F',
  },
];

interface SteppedDosingEditorProps {
  initialStepDosing: AdmixtureSteppedDosingForm;
  onChange?: (steppedDosing: AdmixtureSteppedDosingForm) => void;
  errors?: ValidationError[];
  errorBasePath: string;
}

const ButtonIcon = styled.span`
  margin: -0.75rem;
`;

export const SteppedDosingEditor = ({
  initialStepDosing,
  onChange,
  errors = [],
  errorBasePath = 'instance',
}: SteppedDosingEditorProps) => {
  const [batchWeight, setBatchWeight] = useState(
    initialStepDosing.refBatchWeightInLbs,
  );

  const [selectedSource, setSelectedSource] = useState(
    initialStepDosing.source || stepSourceVariables[0].key,
  );

  const [steps, setSteps] = useState<DosingStepRow[]>(
    initialStepDosing.steps.map((step, index) => ({
      ...step,
      id: index,
      lowTempC: fahrenheitToCelsius(step.lowTempF, 2),
      highTempC: fahrenheitToCelsius(step.highTempF, 2),
    })),
  );

  const [isCelsius, setIsCelsius] = useState<boolean>(false);

  const sourceVariable = stepSourceVariables.find(
    (s) => s.key === selectedSource,
  );

  const selectedUnit = isCelsius ? '\xB0C' : sourceVariable?.unit;

  useEffect(() => {
    if (onChange && batchWeight !== undefined && selectedSource && steps) {
      onChange({
        type: 'STEPPED',
        refBatchWeightInLbs: batchWeight,
        source: selectedSource,
        steps: steps.map((step) => omit(['id'], step)),
      });
    }
  }, [batchWeight, selectedSource, steps]);

  const onBatchWeightChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const refBatchWeightInLbs = event.target.value
      ? parseFloat(event.target.value)
      : undefined;
    setBatchWeight(refBatchWeightInLbs);
  };

  const onStepSourceChange = (newValue: { value: number; label: string }) => {
    const newOption = stepSourceVariables.find(
      (v) => v.value === newValue.value,
    );
    setSelectedSource(newOption?.key || '');
  };

  const selectedSourceOption =
    stepSourceVariables.find((v) => v.key === selectedSource) || null;

  const onLowVolumeChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    row: DosingStepRow,
  ) => {
    const newSteps = [...steps];
    newSteps[row.id] = {
      ...row,
      volumeAtLowTempInMl: event.target.value
        ? parseFloat(event.target.value)
        : undefined,
    };
    setSteps(newSteps);
  };

  const onHighVolumeChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    row: DosingStepRow,
  ) => {
    const newSteps = [...steps];
    newSteps[row.id] = {
      ...row,
      volumeAtHighTempInMl: event.target.value
        ? parseFloat(event.target.value)
        : undefined,
    };
    setSteps(newSteps);
  };

  const onLowTemperatureChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    row: DosingStepRow,
  ) => {
    const newValue = event.target.value
      ? parseFloat(event.target.value)
      : undefined;

    const newSteps = [...steps];
    newSteps[row.id] = {
      ...row,
      lowTempF: isCelsius ? celsiusToFahrenheit(newValue, 2) : newValue,
      lowTempC: isCelsius ? newValue : fahrenheitToCelsius(newValue, 2),
    };
    if (row.id > 0) {
      // Also set high temperature of previous step to keep interval contiguous
      newSteps[row.id - 1] = {
        ...steps[row.id - 1],
        highTempF: isCelsius ? celsiusToFahrenheit(newValue, 2) : newValue,
        highTempC: isCelsius ? newValue : fahrenheitToCelsius(newValue, 2),
      };
    }
    setSteps(newSteps);
  };

  const onHighTemperatureChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    row: DosingStepRow,
  ) => {
    const newValue = event.target.value
      ? parseFloat(event.target.value)
      : undefined;
    const newSteps = [...steps];
    newSteps[row.id] = {
      ...row,
      highTempF: isCelsius ? celsiusToFahrenheit(newValue, 2) : newValue,
      highTempC: isCelsius ? newValue : fahrenheitToCelsius(newValue, 2),
    };
    if (row.id < steps.length - 1) {
      // Also set low temperature of next step to keep interval contiguous
      newSteps[row.id + 1] = {
        ...steps[row.id + 1],
        lowTempF: isCelsius ? celsiusToFahrenheit(newValue, 2) : newValue,
        lowTempC: isCelsius ? newValue : fahrenheitToCelsius(newValue, 2),
      };
    }
    setSteps(newSteps);
  };

  const onRemoveStep = (id: number) => (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    // Prevent default navigation
    event.preventDefault();

    let newSteps;
    if (id === steps.length - 1) {
      newSteps = [...steps.slice(0, id)];
    } else {
      newSteps = [
        ...steps.slice(0, id),
        ...steps.slice(id + 1).map((step) => ({
          ...step,
          id: step.id - 1,
        })),
      ];
    }
    // Update high temperature from previous step accordingly to keep range
    // contiguous
    newSteps[id - 1] = {
      ...steps[id - 1],
      highTempF:
        id === steps.length - 1 ? steps[id].highTempF : steps[id + 1].lowTempF,
      highTempC:
        id === steps.length - 1 ? steps[id].highTempC : steps[id + 1].lowTempC,
    };
    setSteps(newSteps);
  };

  const onAddStepAfter = (id: number) => (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    // Prevent default navigation
    event.preventDefault();

    const lowTemperaturePreviousItem = steps[id].lowTempF ?? 0;
    const highTemperaturePreviousItem = steps[id].highTempF ?? 0;
    const newLowTemperature =
      (highTemperaturePreviousItem - lowTemperaturePreviousItem) / 2 +
      lowTemperaturePreviousItem;
    const newStep: DosingStepRow = {
      id: id + 1,
      lowTempF: newLowTemperature,
      lowTempC: fahrenheitToCelsius(newLowTemperature, 2),
      volumeAtLowTempInMl: steps[id].volumeAtHighTempInMl,
      highTempF: steps[id].highTempF,
      highTempC: steps[id].highTempC,
      volumeAtHighTempInMl: steps[id].volumeAtHighTempInMl,
    };
    const newSteps = [
      ...steps.slice(0, id + 1),
      newStep,
      ...steps.slice(id + 1).map((step) => ({
        ...step,
        id: step.id + 1,
      })),
    ];
    newSteps[id] = {
      ...steps[id],
      highTempF: newLowTemperature,
      highTempC: celsiusToFahrenheit(newLowTemperature, 2),
    };
    setSteps(newSteps);
  };

  const hasError = (key: string) => {
    return errors.some(
      (err) =>
        err.property === `${errorBasePath}.${key}` ||
        (err.property === errorBasePath && err.argument === key),
    );
  };

  const getError = (key: string) =>
    errors
      .filter(
        (err) =>
          err.property === `${errorBasePath}.${key}` ||
          (err.property === errorBasePath && err.argument === key),
      )
      .map(toErrorMessage)
      .join('\n');

  const lowTemperatureFormatter = (_value: number, row: DosingStepRow) => {
    return (
      <div className="flex items-center">
        <div>
          <Input
            type="number"
            className="text-right"
            onChange={(event) => onLowVolumeChange(event, row)}
            value={row.volumeAtLowTempInMl ?? ''}
            step={0.01}
            min={0}
          />
          {hasError(`steps[${row.id}].volumeAtLowTempInMl`) && (
            <FormError>
              {getError(`steps[${row.id}].volumeAtLowTempInMl`)}
            </FormError>
          )}
        </div>
        <span className="pl-2">
          <LabelUnit>mL</LabelUnit>
        </span>
        <span className="px-4">@</span>
        <div>
          <Input
            type="number"
            className="text-right"
            onChange={(event) => onLowTemperatureChange(event, row)}
            value={(isCelsius ? row.lowTempC : row.lowTempF) ?? ''}
            step={1}
          />
          {hasError(`steps[${row.id}].lowTempF`) && (
            <FormError>{getError(`steps[${row.id}].lowTempF`)}</FormError>
          )}
        </div>
        <span className="pl-2">
          <LabelUnit>{selectedUnit}</LabelUnit>
        </span>
      </div>
    );
  };

  const highTemperatureFormatter = (_value: number, row: DosingStepRow) => {
    return (
      <div className="flex items-center">
        <div>
          <Input
            type="number"
            className="text-right"
            onChange={(event) => onHighVolumeChange(event, row)}
            value={row.volumeAtHighTempInMl ?? ''}
            step={0.01}
            min={0}
          />
          {hasError(`steps[${row.id}].volumeAtHighTempInMl`) && (
            <FormError>
              {getError(`steps[${row.id}].volumeAtHighTempInMl`)}
            </FormError>
          )}
        </div>
        <span className="pl-2">
          <LabelUnit>mL</LabelUnit>
        </span>
        <span className="px-4">@</span>

        <div>
          <Input
            type="number"
            className="text-right"
            onChange={(event) => onHighTemperatureChange(event, row)}
            value={(isCelsius ? row.highTempC : row.highTempF) ?? ''}
            step={1.0}
          />
          {hasError(`steps[${row.id}].highTempF`) && (
            <FormError>{getError(`steps[${row.id}].highTempF`)}</FormError>
          )}
        </div>
        <span className="pl-2">
          <LabelUnit>{selectedUnit}</LabelUnit>
        </span>
      </div>
    );
  };

  const actionButtonsFormatter = (id: number) => {
    return (
      <div className="flex justify-end">
        <Button onClick={onAddStepAfter(id)} className="mr-1">
          <ButtonIcon className="icon-plus" />
        </Button>
        <Button
          danger
          onClick={id > 0 ? onRemoveStep(id) : undefined}
          className="ml-1"
        >
          <ButtonIcon className="icon-trash" />
        </Button>
      </div>
    );
  };

  const lowHighSeparator = () => {
    return <ButtonIcon className="icon-right" />;
  };

  return (
    <>
      <div className="col-start-1">
        <Label>Reference batch weight</Label>
        <div className="flex items-center">
          <Input
            type="number"
            step={0.01}
            min={1}
            value={batchWeight}
            onChange={onBatchWeightChange}
          />
          <span className="pl-2">
            <LabelUnit>lbs</LabelUnit>
          </span>
        </div>
        {hasError(`refBatchWeightInLbs`) && (
          <FormError>{getError(`refBatchWeightInLbs`)}</FormError>
        )}
      </div>
      <div className="col-span-2">
        <Label>Source</Label>
        <Select
          options={stepSourceVariables}
          value={selectedSourceOption}
          onChange={onStepSourceChange}
        />
        {hasError(`source`) && <FormError>{getError(`source`)}</FormError>}
      </div>
      <div>
        <Label>Unit</Label>
        <ToggleSwitch
          id="is_fahrenheit"
          checked={!isCelsius}
          onChange={(v) => setIsCelsius(!v)}
          yesLabel={'\xB0F'}
          noLabel={'\xB0C'}
          width="60px"
        />
      </div>
      <div className="col-start-1 col-span-4">
        <Label>Steps</Label>
        <SteppedDosingGraph
          steps={steps}
          displayCelsiusTemp={isCelsius}
          source={{ label: sourceVariable?.label, unit: selectedUnit }}
        />
        <div className="text-sm">
          <TableView
            data={{
              fields: [
                {
                  ref: 'lowTempF',
                  label: 'Volume at Low temperature',
                  formatter: (value, row) =>
                    lowTemperatureFormatter(
                      value as number,
                      row as DosingStepRow,
                    ),
                },
                {
                  ref: '',
                  label: '',
                  formatter: lowHighSeparator,
                },
                {
                  ref: 'highTempF',
                  label: 'Volume at high temperature',
                  formatter: (value, row) =>
                    highTemperatureFormatter(
                      value as number,
                      row as DosingStepRow,
                    ),
                },
                {
                  ref: 'id',
                  label: '',
                  formatter: (id) => actionButtonsFormatter(id as number),
                },
              ],
              rows: steps.map((step, index) => ({
                ...step,
                id: index,
              })),
            }}
          />
        </div>
      </div>
    </>
  );
};

export default SteppedDosingEditor;
