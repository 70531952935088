import gql from 'graphql-tag';

import { ChecklistFields } from './fragments';

export const getChecklists = gql`
  ${ChecklistFields}
  query getChecklists {
    checklist(where: { archived: { _eq: false } }) {
      ...ChecklistFields
    }
  }
`;

export const getChecklist = gql`
  ${ChecklistFields}
  query getChecklist($id: String!) {
    checklist_by_pk(id: $id) {
      ...ChecklistFields
    }
  }
`;

export const addChecklist = gql`
  ${ChecklistFields}
  mutation addChecklist(
    $createdBy: String
    $description: String
    $id: String
    $items: String
    $name: String
  ) {
    insert_checklist_one(
      object: {
        created_by: $createdBy
        description: $description
        id: $id
        items: $items
        name: $name
      }
      on_conflict: {
        constraint: checklist_pkey
        update_columns: [archived, description, items, name]
      }
    ) {
      ...ChecklistFields
    }
  }
`;

export const softDeleteChecklist = gql`
  mutation softDeleteChecklist($id: String) {
    update_checklist(where: { id: { _eq: $id } }, _set: { archived: true }) {
      affected_rows
      returning {
        id
        archived
      }
    }
  }
`;
