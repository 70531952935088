/* eslint-disable @typescript-eslint/camelcase */
import { equals, isEmpty } from 'ramda';
import { useQuery, useMutation } from '@apollo/client';
import { ValidationError } from 'jsonschema';
import React, { useEffect, useState } from 'react';
import { ValueType } from 'react-select';
import styled from 'styled-components';
import 'prismjs';
import 'prismjs/components/prism-json';
import 'prismjs/themes/prism.css';

import {
  GetLogCodeOptionsQuery,
  AddOperationMutation,
} from '@/generated/hasura.graphql';
import Button from '@atoms/button';
import colors from '@/constants/colors';
import FormError from '@atoms/form-error';
import Loading from '@molecules/loading';
import Label from '@atoms/label';
import Problem from '@molecules/problem';
import Select from '@atoms/select';
import { CodeFormValues } from './types';
import {
  getLogCodeOptions as getLogCodeOptionsQuery,
  addOperation,
} from './queries';
import { mapToOptions, generateKey } from './utils';
import { validate } from './validator';

const inputStyles = `
  border-radius: 0.25rem;
  border: 1px solid ${colors.gray['300']};
  color: hsl(0,0%,20%);
  display: block;
  font-weight: 300;
  padding: 6px 10px;
  transition: border 250ms;
  width: 100%;

  &:focus {
    border-color: ${colors.cyan['400']};
  }
`;

const Textarea = styled.textarea`
  ${inputStyles}
`;

const Input = styled.input`
  ${inputStyles}
`;

interface CodeEditorProps {
  children?: React.ReactNode | React.ReactNode[];
  className?: string;
  initialLogCode?: CodeFormValues;
  isPanel?: boolean;
  save: (data: CodeFormValues) => void;
}

const defaultLogCode: CodeFormValues = {
  audience: [],
  description: '',
  resolution: '',
  level: null,
  name: '',
  disruptionLevel: null,
  operation: null,
  responsibility: [],
  system: null,
  audioNotification: null,
};

export const CodeEditor = ({
  children = null,
  className = '',
  initialLogCode = defaultLogCode,
  save,
}: CodeEditorProps) => {
  const [saving, setSaving] = useState<boolean>(false);
  const [savedLogCode, setSavedLogCode] = useState<CodeFormValues>(
    initialLogCode,
  );
  const [logCode, setLogCode] = useState<CodeFormValues>(initialLogCode);

  // Track inputs with errors by their LogCode key.
  const [errorKeys, setErrorKeys] = useState<string[]>([]);

  // Get Form Keys from Validation Errors
  const getErrorKeys = (errors: ValidationError[]) => {
    return errors.map(
      (e): string => (e?.property || '').split('.').pop() || '',
    );
  };

  // Clear out errors on change.
  useEffect(() => {
    const changeErrors = getErrorKeys(validate(logCode));
    setErrorKeys(errorKeys.filter((e) => changeErrors.includes(e)));
    setSavedLogCode(initialLogCode);
  }, [logCode, initialLogCode]);

  // Retrieve Options from SOL.
  const { loading, error, data, refetch } = useQuery<GetLogCodeOptionsQuery>(
    getLogCodeOptionsQuery,
    {
      // Force refreshing operations to prevent creating duplicates
      fetchPolicy: 'cache-and-network',
    },
  );

  // Add new Operation to SOL.
  const [addNewOperation] = useMutation<AddOperationMutation>(addOperation);

  // Show loading or problem message if we don't have data yet.
  if (loading) return <Loading />;
  if (error || !data) return <Problem />;

  const options = {
    audience: mapToOptions(data.audience),
    level: mapToOptions(data.level),
    operation: mapToOptions(data.operation),
    responsibility: mapToOptions(data.responsibility),
    system: mapToOptions(data.system),
    disruptionLevel: mapToOptions(data.disruption_level),
    audioNotification: mapToOptions(data.audio_notification),
  };

  const hasError = (key: string) => errorKeys.includes(key);

  const isNewOperation = (selectedOperation: string) => {
    const currentOperations = options.operation.map((item) => item.label);

    return !currentOperations.includes(selectedOperation);
  };

  const saveNewOperation = async (newCreatedOption: string) => {
    const operationKeys = data.operation.map((x) => x.key);

    try {
      const { data: insertedOperation } = await addNewOperation({
        variables: {
          name: newCreatedOption,
          key: generateKey(newCreatedOption, operationKeys),
        },
      });

      return insertedOperation;
    } catch (e) {
      console.error(e);
    }
    return null;
  };

  const handleSubmit = equals(savedLogCode, logCode)
    ? undefined
    : async (
        event:
          | React.FormEvent<HTMLFormElement>
          | React.FormEvent<HTMLButtonElement>,
      ): Promise<void> => {
        event.preventDefault();

        if (logCode.operation !== null) {
          if (isNewOperation(logCode.operation.label)) {
            const newOperation = await saveNewOperation(
              logCode.operation.label,
            );

            if (newOperation?.insert_operation_one) {
              logCode.operation = {
                value: newOperation.insert_operation_one.id,
                label: newOperation.insert_operation_one.name,
              };
            }
          }
        }

        const errors = validate(logCode);
        setErrorKeys(getErrorKeys(errors));

        if (isEmpty(errors)) {
          setSaving(true);
          await save(logCode);
          await refetch();
          setSaving(false);
        }
      };

  const handleSelectChange = (key: string, isMulti = false) => (
    selectedOption: ValueType<{ value: number; label: string }>,
  ): void => {
    setLogCode({
      ...logCode,
      [key]: isMulti && !selectedOption ? [] : selectedOption,
    });
  };

  // (Audric) Currently only works for Operation Select
  const handleOnCreateOption = (key: string) => async (
    newCreatedOption: string,
  ): Promise<void> => {
    // TODO (Audric): Create a dictionary to allow creating options
    // for other Selects.
    const newLog = {
      value: options.operation.length + 1,
      label: newCreatedOption,
    };

    handleSelectChange(key)(newLog);
  };

  const handleTextChange = (key: string) => (
    event:
      | React.ChangeEvent<HTMLTextAreaElement>
      | React.ChangeEvent<HTMLInputElement>,
  ): void => {
    setLogCode({
      ...logCode,
      [key]: event.target?.value || '',
    });
  };

  return (
    <div className={`bg-white p-6 rounded-md ${className}`}>
      {children}
      <form onSubmit={handleSubmit}>
        <div className="grid gap-4 grid-cols-2">
          <div className="col-span-2">
            <Label>Title</Label>
            <Input onChange={handleTextChange('name')} value={logCode.name} />
            {hasError('name') && <FormError>Required.</FormError>}
          </div>
          <div>
            <Label>Level</Label>
            <Select
              onChange={handleSelectChange('level')}
              options={options.level}
              value={logCode.level}
            />
            {hasError('level') && <FormError>Required.</FormError>}
          </div>
          <div>
            <Label>Disruption Level</Label>
            <Select
              onChange={handleSelectChange('disruptionLevel')}
              options={options.disruptionLevel}
              value={logCode.disruptionLevel}
            />
            {hasError('disruptionLevel') && <FormError>Required.</FormError>}
          </div>
          <div>
            <Label>Audience</Label>
            <Select
              isMulti
              onChange={handleSelectChange('audience', true)}
              options={options.audience}
              value={logCode.audience}
            />
            {hasError('audience') && <FormError>Required.</FormError>}
          </div>
          <div>
            <Label>Responsibility</Label>
            <Select
              isMulti
              onChange={handleSelectChange('responsibility', true)}
              options={options.responsibility}
              value={logCode.responsibility}
            />
            {hasError('responsibility') && <FormError>Required.</FormError>}
          </div>
          <div>
            <Label>System</Label>
            <Select
              onChange={handleSelectChange('system')}
              options={options.system}
              value={logCode.system}
            />
            {hasError('system') && <FormError>Required.</FormError>}
          </div>
          <div>
            <Label>Operation</Label>
            <Select
              isClearable
              isCreatable
              onChange={handleSelectChange('operation')}
              onCreateOption={handleOnCreateOption('operation')}
              options={options.operation}
              value={logCode.operation}
            />
            {hasError('operation') && <FormError>Required.</FormError>}
          </div>
          <div>
            <Label>Audio notification</Label>
            <Select
              isClearable
              onChange={handleSelectChange('audioNotification')}
              options={options.audioNotification}
              value={logCode.audioNotification}
            />
            {hasError('operation') && <FormError>Required.</FormError>}
          </div>
          <div className="col-span-2">
            <Label>Description</Label>
            <Textarea
              className="w-full"
              onChange={handleTextChange('description')}
              value={logCode.description}
            />
            {hasError('description') && <FormError>Required.</FormError>}
          </div>
          <div className="col-span-2">
            <Label>Resolution</Label>
            <Textarea
              className="w-full"
              onChange={handleTextChange('resolution')}
              value={logCode.resolution}
            />
            {hasError('resolution') && <FormError>Required.</FormError>}
          </div>
          <div className="col-span-2">
            <Button className="float-right" onClick={handleSubmit}>
              {saving ? 'Saving' : 'Save'}
            </Button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default CodeEditor;
