import gql from 'graphql-tag';

export const getSystemsNameAndSerial = gql`
  query getSystemsNameAndSerial {
    system(order_by: { name: asc }) {
      id
      name
      serial
    }
  }
`;
