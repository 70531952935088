import styled, { css } from 'styled-components';

import colors from '@/constants/colors';

export default styled.hr<{ className?: string }>`
  background-color: transparent;
  border: none;
  border-top: 1px solid ${colors.gray['300']};
  height: 1px;

  ${({ className = '' }) => {
    if (!/(mt-|m-)/i.test(className)) {
      return css`
        margin-top: 1rem;
      `;
    }

    if (!/(mb-|m-)/i.test(className)) {
      return css`
        margin-bottom: 1rem;
      `;
    }

    return '';
  }}
`;
