import React from 'react';
import styled from 'styled-components';
import { SortableHandle } from 'react-sortable-hoc';

import colors from '@/constants/colors';

const Handle = styled.span`
  align-items: center;
  cursor: pointer;
  display: flex;
  height: 3.5rem;
  justify-content: space-between;
  margin-right: 0.25rem;
  padding: 0.75ch 2ch;

  &::after,
  &::before {
    background-color: ${colors.black};
    content: '';
    display: block;
    height: 1rem;
    width: 1px;
  }

  &::after {
    margin-left: 0.5ch;
  }
`;

export const DragHandle = SortableHandle(() => <Handle />);

export default DragHandle;
