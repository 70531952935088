// Site header nav.
export const header = [
  { label: 'Technology', link: '/' },
  { label: 'Our Story', link: '/' },
  { label: 'Updates', link: '/' },
  { label: 'Contact', link: '/' },
];

// Site footer nav.
export const footer = [
  {
    label: 'About',
    items: [{ label: 'About' }, { label: 'FAQ' }, { label: 'Careers' }],
  },
  {
    label: 'Technology',
    items: [
      { label: 'Technology' },
      { label: 'Material' },
      { label: 'Software' },
    ],
  },
  {
    label: 'Updates',
    items: [
      { label: 'Latest Posts' },
      { label: 'YouTube' },
      { label: 'Instagram' },
      { label: 'Twitter' },
    ],
  },
  {
    label: 'Contact',
    items: [
      { label: 'Contact Us' },
      { label: 'I Want a Home' },
      { label: 'I Want a Printer' },
    ],
  },
];

// Admin nav.
export const admin = [
  {
    label: 'Projects',
    icon: 'house',
    link: '/projects',
  },
  {
    label: 'Magma Data',
    icon: 'list',
    link: '/magma-data',
  },
  {
    label: 'QC Data',
    icon: 'list',
    link: '/qc-data',
  },
  {
    label: 'Admixtures',
    icon: 'material',
    link: '/admixtures',
  },
  {
    label: 'Print Data',
    icon: 'nozzle',
    link: '/print-event-data',
  },
  {
    label: 'Log Codes',
    icon: 'box',
    link: '/codes',
  },
  {
    label: 'Checklists',
    icon: 'check',
    link: '/checklists',
  },
];
