import gql from 'graphql-tag';

export const getBatches = gql`
  query getBatches(
    $startDate: timestamptz
    $endDate: timestamptz
    $systemIds: [Int!]
  ) {
    batch(
      limit: 1500
      order_by: { created_at: desc }
      where: {
        _and: {
          created_at: { _gte: $startDate, _lte: $endDate }
          system_id: { _in: $systemIds }
        }
      }
    ) {
      system {
        name
        serial
      }
      slumps {
        id
        air_percent
        slump_cm
        material_temperature_f
        comment
        location
      }
      batch_id
      batch_number
      created_at
      ambient_temp
      relative_humidity
      dew_point
      wind_speed
      material_temp
      rotor_stator_temp
      ratio_achieved
      ratio_set_point
      peak_torque
      settled_torque
      admixtureData
      id
      powder_temperature_as_built
      total_powder_added_as_built
      total_powder_added_as_dispensed
      total_water_added_as_built
      total_water_added_as_dispensed
      water_temperature_as_built
      build_start_time
      build_end_time
      batch_complete_time
      dispensed_to_hopper
      fixed_by_operator
      dispense_reason
      feedback
    }
  }
`;
