import { useMutation, useQuery } from '@apollo/client';
import React, { ChangeEvent, useState } from 'react';
import styled from 'styled-components';
import { navigate } from 'gatsby';
import Lightbox from '@molecules/lightbox';
import ConfirmationMessage from '@molecules/confirmation-message';

import {
  GetActiveAdmixturesQuery,
  GetAdmixtureFunctionsQuery,
} from '@/generated/hasura.graphql';
import App from '@templates/app';
import EmptyPage from '@/admin/empty-page';
import TableView from '@organisms/table-view/table-view';
import Button from '@atoms/button';

import { AdmixtureDosingForm, AdmixtureForm } from '../types';
import {
  getActiveAdmixtures as getActiveAdmixturesQuery,
  archiveAdmixture as archiveAdmixtureMutation,
  getAdmixtureFunctions as getAdmixtureFunctionsQuery,
} from '../queries';
import { getAdmixtureFormFromDBEntry } from '../utils';

const Wrapper = styled.div`
  overflow: auto;
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: calc(100% - 16px);
  margin-bottom: 16px;
`;

interface AdmixtureFormRow extends AdmixtureForm {
  id: number;
}

const AdmixtureList = () => {
  // Retrieve Admixtures from database.
  const admixturesResult = useQuery<GetActiveAdmixturesQuery>(
    getActiveAdmixturesQuery,
    {
      fetchPolicy: 'no-cache',
    },
  );
  const admixtureFunctionsResult = useQuery<GetAdmixtureFunctionsQuery>(
    getAdmixtureFunctionsQuery,
    {
      fetchPolicy: 'no-cache',
    },
  );
  const [archiveAdmixture] = useMutation(archiveAdmixtureMutation);

  // Keep track of which admixture is selected in the table, which is checked
  // and if the archive confirmation dialog is open.
  const [selectedIndex, setSelectedIndex] = useState(null as number | null);
  const [checkedKeys, setCheckedKeys] = useState([] as string[]);
  const [archiveConfirmationOpen, setArchiveConfirmationOpen] = useState(
    false,
  );

  // Show loading or problem message if we don't have data yet.
  if (admixturesResult.loading || admixtureFunctionsResult.loading)
    return <EmptyPage sectionLabel="Admixtures" />;
  if (
    admixturesResult.error ||
    !admixturesResult.data ||
    admixtureFunctionsResult.error ||
    !admixtureFunctionsResult.data
  ) {
    return <EmptyPage problem sectionLabel="Admixtures" />;
  }

  // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
  // @ts-ignore
  const admixtures: AdmixtureFormRow[] = admixturesResult.data.admixture.map(
    (admixture, index) => ({
      id: index,
      ...getAdmixtureFormFromDBEntry(admixture),
    }),
  );

  const archiveCheckedItems = async () => {
    await Promise.all(
      checkedKeys.map((key: string) => {
        return archiveAdmixture({ variables: { key } });
      }),
    );
    setArchiveConfirmationOpen(false);
    setCheckedKeys([]);
  };

  const handleOnChecked = (
    event: ChangeEvent<HTMLInputElement>,
    key: string,
  ) => {
    if (event.target.checked) {
      setCheckedKeys([...checkedKeys, key]);
    } else {
      setCheckedKeys(checkedKeys.filter((k) => k !== key));
    }
  };

  const navigateToAddAdmixture = () => {
    navigate('/admixtures/add');
  };

  const navigateToSelectedAdmixture = () => {
    if (
      selectedIndex !== null &&
      selectedIndex >= 0 &&
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      selectedIndex < admixturesResult.data!.admixture.length
    ) {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const selectedAdmixture = admixturesResult.data!.admixture[
        selectedIndex
      ];
      navigate(`/admixtures/edit/${selectedAdmixture.key}`);
    }
  };

  const archiveCheckbox = (key: string) => (
    <input
      style={{ transform: 'scale(1.5)' }}
      type="checkbox"
      onChange={(event) => handleOnChecked(event, key)}
      checked={checkedKeys.includes(key as string)}
      // Stop click propagation when clicking on the checkbox to prevent
      // navigating to the admixture edit form (coming from row click)
      onClick={(event) => event.stopPropagation()}
    />
  );

  const formatDosage = (dosage?: AdmixtureDosingForm) => {
    if (dosage == null) {
      return 'None';
    }
    if ('type' in dosage && dosage.type === 'FIXED') {
      return 'Fixed';
    }
    return `Dynamic - ${dosage.type.toLocaleLowerCase()}`;
  };

  const formatNumber = (value: number | null): string => {
    if (value == null) {
      return '';
    }
    return value.toString();
  };

  const formatAdmixtureFunction = (value: string | null): string => {
    if (value == null) {
      return '';
    }
    return (
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      admixtureFunctionsResult.data!.admixture_function.find(
        (af) => af.key === value,
      )?.label ?? value
    );
  };

  return (
    <App section="admixtures" title="Admixtures">
      <Wrapper>
        <ButtonContainer>
          <Button icon="plus" onClick={navigateToAddAdmixture}>
            New admixture
          </Button>
          <Button
            className="ml-4"
            onClick={
              checkedKeys.length > 0
                ? () => setArchiveConfirmationOpen(true)
                : undefined
            }
          >
            Archive
          </Button>
        </ButtonContainer>
        {archiveConfirmationOpen && (
          <Lightbox
            title="Warning"
            close={() => setArchiveConfirmationOpen(false)}
          >
            <ConfirmationMessage
              action={archiveCheckedItems}
              cancel={() => setArchiveConfirmationOpen(false)}
              message={`Are you sure you want to archive ${checkedKeys.length} admixture(s)?`}
            />
          </Lightbox>
        )}
        <TableView
          selected={selectedIndex != null ? selectedIndex : undefined}
          setSelected={navigateToSelectedAdmixture}
          hover={setSelectedIndex}
          data={{
            fields: [
              {
                ref: 'key',
                label: '',
                formatter: (key) => archiveCheckbox(key as string),
              },
              {
                ref: 'name',
                label: 'Name',
              },
              {
                ref: 'producer',
                label: 'Producer',
              },
              {
                ref: 'density',
                label: 'Density',
                unitLabel: (
                  <>
                    g/cm<sup>3</sup>
                  </>
                ),
              },
              {
                ref: 'enforcedPriority',
                label: 'Enforced priority',
                formatter: (p) => formatNumber(p as number | null),
              },
              {
                ref: 'dosing',
                label: 'Recommended dosage',
                // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
                // @ts-ignore
                formatter: (d) => formatDosage(d),
              },
              {
                ref: 'admixtureFunctionKey',
                label: 'Function',
                formatter: (af) =>
                  formatAdmixtureFunction(af as string | null),
              },
            ],
            rows: admixtures,
          }}
        />
      </Wrapper>
    </App>
  );
};

export default AdmixtureList;
