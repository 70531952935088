import React from 'react';
import styled from 'styled-components';

import { User } from '@providers/auth';
import Avatar from '@atoms/avatar';

const Carat = styled.svg`
  height: 4px;
  margin-left: 6px;
  fill: none;
  stroke: rgba(255, 255, 255, 0.5);
`;

const Button = styled.button`
  align-items: center;
  display: flex;
  padding: 8px 0;

  &:focus {
    outline: none;
  }
`;

const AccountButton = ({
  user,
  onClick,
}: {
  user: User;
  onClick: () => void;
}) => (
  <Button type="button" onClick={onClick}>
    <Avatar size="small" url={user.picture} name={user.name} />
    <Carat viewBox="0 0 10 5">
      <path d="M0,0L5,5L10,0" />
    </Carat>
  </Button>
);

export default AccountButton;
