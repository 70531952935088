import React from 'react';
import { Router, RouteComponentProps } from '@reach/router';

import CodeAdd from './code-add';
import CodeEdit from './code-edit';
import CodeList from './code-list';

const Route = ({
  component,
}: RouteComponentProps & { component: React.ReactElement }) => component;

const ToolPathsRouter = () => {
  return (
    <Router>
      <CodeEdit path="/:id/*" />
      <Route path="/" component={<CodeList />} />
      <Route path="/add" component={<CodeAdd />} />
    </Router>
  );
};

export default ToolPathsRouter;
