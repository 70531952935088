import React from 'react';
import Select, { Styles, ValueType } from 'react-select';
import CreatableSelect from 'react-select/creatable';

import colors from '@/constants/colors';

const selectStyles: Styles = {
  control: (provided, state) => ({
    ...provided,
    boxShadow: 'none',
    borderColor: state.isFocused ? colors.cyan['400'] : colors.gray['300'],
    '&:hover': {
      borderColor: colors.cyan['400'],
    },
  }),
};

interface SelectProps {
  className?: string;
  isClearable?: boolean;
  isCreatable?: boolean;
  isMulti?: boolean;
  onChange: (
    selectedOption: ValueType<{ value: number | string; label: string }>,
  ) => void;
  onCreateOption?: (inputValue: string) => void;
  options: { value: number | string; label: string }[];
  value:
    | { value: number | string; label: string }[]
    | { value: number | string; label: string }
    | null;
}

export const StyledSelect = ({
  className = '',
  isClearable = false,
  isMulti = false,
  isCreatable = false,
  options,
  onChange,
  onCreateOption,
  value,
}: SelectProps) => {
  const selectComponent = (
    <Select
      className={className}
      isClearable={isClearable}
      isMulti={isMulti}
      onChange={onChange}
      options={options}
      styles={selectStyles}
      value={value}
    />
  );

  const creatableSelectComponent = (
    <CreatableSelect
      className={className}
      isClearable={isClearable}
      isMulti={isMulti}
      onChange={onChange}
      onCreateOption={onCreateOption}
      options={options}
      styles={selectStyles}
      value={value}
    />
  );
  return isCreatable ? creatableSelectComponent : selectComponent;
};

export default StyledSelect;
