import { AdmixtureDosing, AdmixtureForm } from '@/admin/admixtures/types';
import { pathOr } from 'ramda';

const parseDosageStrategy = (json?: string): AdmixtureDosing | undefined => {
  if (!json) {
    return undefined;
  }
  return JSON.parse(json);
};

export const getAdmixtureFormFromDBEntry = (
  admixture: unknown,
): AdmixtureForm | undefined => {
  if (!admixture) {
    return undefined;
  }

  const strPriority = pathOr(undefined, ['enforced_priority'], admixture);
  const jsonDosing = pathOr(undefined, ['dosing_strategy'], admixture);

  return {
    key: pathOr('', ['key'], admixture),
    name: pathOr('', ['name'], admixture),
    density: parseFloat(pathOr('1', ['density'], admixture)),
    enforcedPriority: strPriority ? parseInt(strPriority, 10) : undefined,
    dosing: parseDosageStrategy(jsonDosing),
    admixtureFunctionKey: pathOr(
      undefined,
      ['admixture_function_key'],
      admixture,
    ),
    producer: pathOr('', ['producer'], admixture),
  };
};

const MAX_DISCRIMINATING_KEY_SUFFIX = 999;

export const generateKey = (
  name: string,
  existingKeys: Set<string> = new Set(),
  minCharactersPerWord = 3,
) => {
  let possibleCharactersToAdd = 0;
  const words = name.split(/\s+/).map((w) => {
    const word = w.toLocaleLowerCase().replace(/[^a-z\d]/, '');
    const maxLetters = word.length;
    let selectedLetterCount;
    if (/^\d+$/.test(word)) {
      // All numbers so we include them all
      selectedLetterCount = word.length;
    } else {
      selectedLetterCount = Math.min(minCharactersPerWord, maxLetters);
    }
    possibleCharactersToAdd += maxLetters - selectedLetterCount;

    return {
      word,
      selectedLetterCount,
      maxLetters,
    };
  });

  const buildKey = () =>
    words.map((w) => w.word.slice(0, w.selectedLetterCount)).join('');

  let suggestedKey = buildKey();
  // Progressively include more letters from words starting from the last words
  // until we have resolved any conflict with existing keys or exhausted all
  // letters (i.e. full words included in the key)
  while (existingKeys.has(suggestedKey) && possibleCharactersToAdd >= 0) {
    for (let i = words.length - 1; i >= 0; i -= 1) {
      if (words[i].selectedLetterCount < words[i].maxLetters) {
        words[i].selectedLetterCount += 1;
        break;
      }
    }
    possibleCharactersToAdd -= 1;
    suggestedKey = buildKey();
  }

  if (!existingKeys.has(suggestedKey)) {
    return suggestedKey;
  }

  // Used all characters and still conflicts with an existing key, add a suffix!
  let suffix = 1;
  while (
    existingKeys.has(suggestedKey + suffix) &&
    suffix <= MAX_DISCRIMINATING_KEY_SUFFIX
  ) {
    suffix += 1;
  }

  return suggestedKey + suffix;
};

export const fahrenheitToCelsius = (
  value?: number,
  precision?: number,
): number | undefined => {
  if (value == null) {
    return value;
  }
  const celsius = ((value - 32) * 5) / 9;
  if (precision) {
    const factor = 10 ** precision;
    return Math.round(celsius * factor) / factor;
  }
  return celsius;
};

export const celsiusToFahrenheit = (
  value?: number,
  precision?: number,
): number | undefined => {
  if (value == null) {
    return value;
  }
  const fahrenheit = (value * 9) / 5 + 32;
  if (precision) {
    const factor = 10 ** precision;
    return Math.round(fahrenheit * factor) / factor;
  }
  return fahrenheit;
};
