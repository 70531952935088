const headerHeight = 72;
const headerPaddingY = 16;

export const header = {
  height: `${headerHeight}px`,
  paddingY: `${headerPaddingY}px`,
};

export const padX = {
  default: '2rem',
  md: '4rem',
};

export default {
  content: {
    padX,
  },
};
