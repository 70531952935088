import gql from 'graphql-tag';

export const getSystems = gql`
  query getSystems {
    system(order_by: { name: asc }) {
      id
      name
      serial
    }
  }
`;
