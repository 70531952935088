import React, { useState } from 'react';
import { navigate } from 'gatsby';
import { useMutation, useQuery } from '@apollo/client';
import Lightbox from '@molecules/lightbox';
import AlertMessage from '@molecules/alert-message';
import {
  GetAdmixtureFunctionsQuery,
  GetAllAdmixtureKeysQuery,
} from '@/generated/hasura.graphql';
import EmptyPage from '@/admin/empty-page';

import App from '@templates/app';
import {
  addAdmixture as addAdmixtureMutation,
  getAdmixtureFunctions,
  getAllAdmixtureKeys,
} from '../queries';
import { AdmixtureForm } from '../types';
import { AdmixtureEditor } from '../admixture-editor';

const AddAdmixture = () => {
  const admixtureKeysResult = useQuery<GetAllAdmixtureKeysQuery>(
    getAllAdmixtureKeys,
    {
      fetchPolicy: 'no-cache',
    },
  );
  const admixtureFunctionsResult = useQuery<GetAdmixtureFunctionsQuery>(
    getAdmixtureFunctions,
    {
      fetchPolicy: 'no-cache',
    },
  );

  const [addAdmixture] = useMutation(addAdmixtureMutation);

  const [showError, setShowError] = useState<boolean>(false);

  // Show loading or problem message if we don't have data yet.
  if (admixtureKeysResult.loading || admixtureFunctionsResult.loading)
    return <EmptyPage sectionLabel="New Admixture" />;
  if (
    admixtureKeysResult.error ||
    admixtureFunctionsResult.error ||
    !admixtureKeysResult.data ||
    !admixtureFunctionsResult.data
  )
    return <EmptyPage problem sectionLabel="New Admixture" />;

  const existingKeys = new Set(
    admixtureKeysResult.data.admixture.map((a) => a.key),
  );

  const admixtureFunctions = admixtureFunctionsResult.data.admixture_function.map(
    (af) => ({
      value: af.key,
      label: af.label,
    }),
  );

  const handleSave = async (admixture: AdmixtureForm) => {
    try {
      await addAdmixture({
        variables: {
          key: admixture.key,
          name: admixture.name,
          density: admixture.density,
          enforcedPriority: admixture.enforcedPriority,
          dosingStrategy:
            admixture.dosing != null
              ? JSON.stringify(admixture.dosing)
              : undefined,
          admixtureFunctionKey: admixture.admixtureFunctionKey,
          producer: admixture.producer,
        },
      });

      navigate(`/admixtures`);
    } catch (e) {
      setShowError(true);
    }
  };

  return (
    <App
      onNavBack={() => navigate('/admixtures')}
      section="admixtures"
      subtitle="New Admixture"
      title="Admixtures"
    >
      {showError && (
        <Lightbox title="Error" close={() => setShowError(false)}>
          <AlertMessage
            message="Oops, something went wrong when saving the admixture."
            onClick={() => setShowError(false)}
          />
        </Lightbox>
      )}
      <AdmixtureEditor
        admixtureFunctions={admixtureFunctions}
        save={handleSave}
        existingKeys={existingKeys}
        editableKey
      />
    </App>
  );
};

export default AddAdmixture;
