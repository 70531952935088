import React from 'react';
import styled from 'styled-components';

import { capitalize } from '@/utils';

const Container = styled.div`
  bottom: 0;
  position: sticky;
`;

const Message = styled.div`
  background-color: #fff2f3;
  border-radius: 4px;
  border: 1px solid #ffe7e7;
  bottom: 2.5rem;
  color: #666;
  display: inline-block;
  font-size: 0.875rem;
  max-width: 20rem;
  padding: 0.75rem 1rem;
  position: absolute;
  right: 2.5rem;
  width: auto;
`;

export interface AlertMessage {
  type: string;
  message: string;
}

export const StickyAlert = ({ alert }: { alert: AlertMessage }) => {
  return (
    <Container data-testid="alert-sticky">
      <Message>
        <strong>{capitalize(alert.type)}: </strong>
        {alert.message}
      </Message>
    </Container>
  );
};

export default StickyAlert;
