/* eslint-disable @typescript-eslint/camelcase */
import React, { useEffect, useState } from 'react';
import { useMutation } from '@apollo/client';
import { useAuth } from '@providers/auth';
import { RouteComponentProps } from '@reach/router';
import { Container } from '@organisms/table-view/elements';

import App from '@templates/app';

import { AddSassCalibrationDataMutation } from '@/generated/hasura.graphql';

import { addSassCalibrationData as addSassCalibrationDataMutation } from './queries';

const SassCalibration = (props: RouteComponentProps) => {
  const auth = useAuth();
  const userName = auth?.user ? auth.user.name : 'N/A';

  const queryParams = new URLSearchParams(props.location.search);

  const serial = queryParams.get('serial');
  const data = queryParams.get('data');

  const [doUpsert] = useMutation<AddSassCalibrationDataMutation>(
    addSassCalibrationDataMutation,
  );

  const [didSave, setDidSave] = useState<bool>(false);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    if (!serial || !serial.length) {
      setError('missing parameter: serial');
      return;
    }
    if (!data || !data.length) {
      setError('missing parameter: data');
      return;
    }
    const now = new Date().toISOString();
    doUpsert({
      variables: {
        camera_serial: serial,
        calibration_data: data,
        created: now,
        updated: now,
        username: userName,
      },
    }).then(() => {
      setTimeout(() => {
        setDidSave(true);
      }, 1000);
    });
  }, []);

  return (
    <App section="" title="Soft Alignment Camera Calibration">
      <Container className="">
        {!error ? (
          <Container className="m-2">
            {didSave ? (
              <div>
                <p>
                  <b>Successfully Saved!</b>
                </p>
                <p>User: {userName}</p>
                <p>Serial: {serial}</p>
                <p>Data: {data}</p>
              </div>
            ) : (
              <div>
                <p>Saving... please wait...</p>
              </div>
            )}
          </Container>
        ) : (
          <div>Error: {error}</div>
        )}
      </Container>
    </App>
  );
};

export default SassCalibration;
