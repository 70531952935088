import React from 'react';
import { Level } from '@/generated/hasura.graphql';

import Tag, { Color } from '@atoms/tag';

const tagColors = [
  {
    name: 'verbose',
    color: Color.purple,
  },
  {
    name: 'info',
    color: Color.cyan,
  },
  {
    name: 'warning',
    color: Color.yellow,
  },
  {
    name: 'error',
    color: Color.red,
  },
];

const getColor = (level: Pick<Level, 'name'>): Color => {
  const colorMatch = tagColors.find((tc) => {
    const re = new RegExp(tc.name, 'i');
    return re.test(level.name);
  });
  return colorMatch?.color || Color.gray;
};

interface LevelTagProps {
  level: Pick<Level, 'name'>;
}

export const LevelTag = ({ level }: LevelTagProps) => {
  return <Tag color={getColor(level)}>{level.name}</Tag>;
};

export default LevelTag;
