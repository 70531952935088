import React from 'react';
import * as Z from '@iconbuild/izzy';

import LevelPreview from '@/components/atoms/level-preview';

const ProjectPreview = ({
  sitePlan,
  title,
}: {
  sitePlan: Z.SitePlan | null;
  title?: string;
}) => {
  if (!sitePlan) return null;

  return (
    <div>
      {Z.sitePlanPrintLevels(sitePlan).map((a) => {
        const path = Z.sitePlanPrintLevelSVGPaths(a).join('');
        return (
          <LevelPreview
            key={a.startHeightInches}
            endHeightInches={a.endHeightInches}
            startHeightInches={a.startHeightInches}
            path={path}
            title={title || 'download'}
          />
        );
      })}
    </div>
  );
};

export default ProjectPreview;
