/* eslint-disable @typescript-eslint/camelcase */
import * as Z from '@iconbuild/izzy';
import {
  GetProjectQuery,
  UpdateProjectMutationVariables,
} from '@/generated/hasura.graphql';
import { Router, RouteComponentProps } from '@reach/router';
import App from '@templates/app';
import Button from '@atoms/button';
import ConfirmationMessage from '@molecules/confirmation-message';
import Lightbox from '@molecules/lightbox';
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import Materials from './materials';
import SiteDetails from './site-details';
import Project3DPreview from './project-3d-preview';
import ProjectPreview from './project-preview';
import { ProjectCalibration } from './calibration-points/project-calibration';
import { EditMaterialSpeeds, MaterialPlanningSettings } from './material-edit';
import { calibrationPanel } from './calibration-points/panel';
import GCode from './g-code';

// Project data from database that is not stored in the main JSON string
// with the site plan data. Mainly labels for display in UI.

export type CalibrationPoint = Z.XY;
export interface ProjectData {
  label: string;
  site: string;
}

export const CALIBRATION_POINT_COUNT = 1;

const DEFAULT_PROJECT_DATA = {
  latitudeDegrees: 30.216559,
  longitudeDegrees: -97.761057,
  orientationDegrees: 120,
  foundationWidthInches: 336,
  foundationLengthInches: 286,
  items: [],
};

const DEFAULT_CALIBRATION_DATA = {
  points: [],
};

const PanelSection = styled.div`
  &:first-child {
    margin-top: 16px;
  }
  margin: 48px 0;
`;

const TitleBarActions = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 48px;
`;

export interface ProjectMutationData {
  variables: {
    id?: number;
    label: string;
    path: string | null;
    site: string;
    site_plan: string;
    source_data: string;
    calibration_data: string | null;
  };
}

export interface ProjectProjectionData {
  printProjections: Z.PrintLevelProjection[];
  electricalProjections: Z.ElectricalProjection[];
  reinforcementProjections: Z.ReinforcementProjection[];
  plateProjections: Z.PlateProjection[];
}

type SaveApplyButtonInfo = Readonly<{
  buttonAction: (() => void) | undefined;
  buttonLabel: string;
}>;

const ProjectEditor = ({
  back,
  refresh,
  save,
  page,
  title,
  project,
}: {
  back: () => void;
  refresh: () => void;
  save: (data: UpdateProjectMutationVariables) => Promise<void>;
  page: string;
  title: string;
  project: GetProjectQuery['project_by_pk'];
}) => {
  // Display warning modal to user when missing calibration points.
  const [calibrationOpen, setCalibrationOpen] = useState(false);

  const [originalCalibrationData, setOriginalCalibrationData] = useState<
    string
  >(JSON.stringify(DEFAULT_CALIBRATION_DATA));

  const [materialEstimateData, setMaterialEstimateData] = useState<
    MaterialPlanningSettings
  >({
    startHeightInches: 0,
    endHeightInches: 126,
    printSpeedInchesPerSecond: 8,
    travelSpeedInchesPerSecond: 8,
    volumePerSupersack: 0.91,
    averageBatchWeight: 130,
    layerHeightInches: 0.75,
  });

  // Local state copy of source data for the project, parsed from the original
  // input from the database. We edit this data in state until ready to save,
  // then save it back to the database once the user hits the save button.
  const [sourceData, setSourceData] = useState(
    JSON.parse(
      project?.source_data || JSON.stringify(DEFAULT_PROJECT_DATA),
    ) as Z.SitePlanSourceData,
  );

  const sitePlan = JSON.parse(project?.site_plan || '{}');

  const initialCalibrationPoints = project?.calibration_data
    ? JSON.parse(project?.calibration_data).points
    : (DEFAULT_CALIBRATION_DATA.points as CalibrationPoint[]);

  const [calibrationPoints, setCalibrationPoints] = useState(
    initialCalibrationPoints,
  );

  // Local state copy of database values for project, so we can edit them in
  // state until ready to save.
  const [projectData, setProjectData] = useState<ProjectData>({
    label: project?.label || 'New Project',
    site: project?.site || 'The Lab',
  });

  // Track whether our local state data matches the original source data,
  // which tells us whether our local state data is saved or not. This starts
  // out true since we haven't had a chance to edit anything.
  const [saved, setSaved] = useState(true);

  // Save original input data to state any time we get updated project data.
  useEffect(() => {
    setProjectData({
      label: project?.label || 'New Project',
      site: project?.site || 'The Lab',
    });
    setOriginalCalibrationData(
      project?.calibration_data ?? JSON.stringify(DEFAULT_CALIBRATION_DATA),
    );
    setSaved(true);
  }, [project]);

  // Update saved state based on whether non-sourceData related project data
  // matches the original:
  useEffect(() => {
    const calibrationDataSaved =
      !originalCalibrationData ||
      JSON.stringify({ points: calibrationPoints }) ===
        originalCalibrationData;
    setSaved(calibrationDataSaved);
  }, [calibrationPoints, originalCalibrationData]);

  // Re-Calculate the Start / End Inches for Material Planning.
  useEffect(() => {
    if (!sitePlan) return;
    const levels = Z.sitePlanLevels(sitePlan);
    const startHeightInches = Math.min(
      ...levels.map((level) => level.startHeightInches),
    );
    const endHeightInches = Math.max(
      ...levels.map((level) => level.endHeightInches),
    );
    setMaterialEstimateData({
      ...materialEstimateData,
      startHeightInches,
      endHeightInches,
    });
  }, [!sitePlan]);

  const getTemplateData = (
    id: number | undefined,
  ): UpdateProjectMutationVariables => ({
    id,
    calibration_data: JSON.stringify({ points: calibrationPoints }),
  });

  const calibrationMessage = () => {
    if (calibrationPoints.length === 0) {
      return 'Saving without calibration points.';
    }
    if (calibrationPoints.length < CALIBRATION_POINT_COUNT) {
      return 'Please set all Calibration Points before saving.';
    }
    if (calibrationPoints[1].y !== calibrationPoints[0].y) {
      return 'Point 1 and 2 are not correctly set. Please Reset.';
    }
    if (calibrationPoints[2].x !== calibrationPoints[1].y) {
      return 'Point 2 and 3 are not correctly set. Please Reset.';
    }
    return 'All Points are misaligned. Please Reset.';
  };

  const checkCalibrationPoints = () => {
    // saving without calibration points
    if (calibrationPoints.length === 0) {
      return true;
    }

    // started calibration points but not all of them have been added.
    if (calibrationPoints.length < CALIBRATION_POINT_COUNT) {
      return false;
    }

    // No Point 1 / Point 2 comparisons required.
    if (CALIBRATION_POINT_COUNT < 2) {
      return true;
    }

    // Point 1 and Point 2 check
    if (calibrationPoints[0].y !== calibrationPoints[1].y) {
      return false;
    }

    // No Point 2 / Point 3 comparisons required.
    if (CALIBRATION_POINT_COUNT < 3) {
      return true;
    }

    // Point 2 and Point 3 check
    if (calibrationPoints[1].x !== calibrationPoints[2].x) {
      return false;
    }

    // Point 1 and 2 are stacked
    if (
      calibrationPoints[0].x === calibrationPoints[1].x &&
      calibrationPoints[0].y === calibrationPoints[1].y
    ) {
      return false;
    }

    // Point 2 and 3 are stacked
    if (
      calibrationPoints[1].x === calibrationPoints[2].x &&
      calibrationPoints[1].y === calibrationPoints[2].y
    ) {
      return false;
    }

    return true;
  };

  const [saveApplyButtonState, setSaveApplyButtonState] = useState<
    SaveApplyButtonInfo
  >({
    buttonAction: undefined,
    buttonLabel: 'Initializing...',
  });

  useEffect(() => {
    const saveProject = async () => {
      if (checkCalibrationPoints()) {
        if (calibrationPoints.length === 0) {
          setCalibrationOpen(true);
        }
        save(getTemplateData(project?.id));
        setSaved(true);
        refresh();
      } else {
        setCalibrationOpen(true);
      }
    };

    const calculateSaveApplyButtonInfo = (): SaveApplyButtonInfo => {
      if (saved) {
        return {
          buttonLabel: 'Saved',
          buttonAction: undefined,
        };
      }
      return {
        buttonLabel: 'Save',
        buttonAction: saveProject,
      };
    };

    setSaveApplyButtonState(calculateSaveApplyButtonInfo());
  }, [saved, calibrationPoints]);

  // Render the save button.
  const studioProjectId = project?.buildos_project_id ?? project?.id;
  const saveApplyButton = (
    <TitleBarActions>
      {studioProjectId && (
        <Button
          onClick={() =>
            window.open(
              `https://studio.iconbuild.com/designs/${studioProjectId}`,
              '_blank',
            )
          }
        >
          View in Studio
        </Button>
      )}
      <Button onClick={saveApplyButtonState.buttonAction}>
        {saveApplyButtonState.buttonLabel}
      </Button>
    </TitleBarActions>
  );

  // Render Materials Panel.
  const materialsPanel = (
    <EditMaterialSpeeds
      sitePlan={sitePlan}
      props={materialEstimateData}
      updateMaterialPlanningSettings={setMaterialEstimateData}
    />
  );

  // Render Edit Panel.
  const editPanel = (
    <>
      <PanelSection>{page === 'materials' && materialsPanel}</PanelSection>
      <PanelSection>
        <SiteDetails
          projectData={projectData}
          setProjectData={setProjectData}
          sourceData={sourceData}
          setSourceData={setSourceData}
        />
      </PanelSection>
    </>
  );

  // Render panel for specific page.
  const panel = (() => {
    if (page === 'calibration') return calibrationPanel;
    return editPanel;
  })();

  const Route = ({
    component,
  }: RouteComponentProps & { component: React.ReactElement }) => component;

  const setPoints = (points: CalibrationPoint[]) => {
    return setCalibrationPoints(points.slice(0, CALIBRATION_POINT_COUNT));
  };

  return (
    <App
      section="projects"
      title="Projects"
      subtitle={title}
      onNavBack={back}
      panel={panel}
      action={saveApplyButton}
      subNav={[
        {
          label: 'Levels',
          selected: page === 'levels',
          link: `/projects/${project?.id}`,
        },
        {
          label: '3D',
          selected: page === '3d',
          link: `/projects/${project?.id}/3d`,
        },
        {
          label: 'Material Planning',
          selected: page === 'materials',
          link: `/projects/${project?.id}/materials`,
        },
        {
          label: 'Calibration',
          selected: page === 'calibration',
          link: `/projects/${project?.id}/calibration`,
        },
        {
          label: 'G-Code',
          selected: page === 'g-code',
          link: `/projects/${project?.id}/g-code`,
        },
      ]}
    >
      <Router>
        <Route
          path="/materials"
          component={
            <Materials sitePlan={sitePlan} props={materialEstimateData} />
          }
        />
        <Route
          path="/"
          component={<ProjectPreview sitePlan={sitePlan} title={title} />}
        />
        <Route
          path="/3d"
          component={<Project3DPreview plan={sitePlan} title={title} />}
        />
        <Route
          path="/calibration"
          component={
            // eslint-disable-next-line react/jsx-wrap-multilines
            <ProjectCalibration
              sitePlan={sitePlan}
              points={calibrationPoints}
              setPoints={setPoints}
            />
          }
        />
        <Route
          path="/g-code"
          component={<GCode sitePlan={sitePlan} title={title} />}
        />
        <Route default component={<div>Not found</div>} />
      </Router>

      {calibrationOpen && (
        <Lightbox
          title="Calibration Points"
          close={() => setCalibrationOpen(false)}
        >
          <ConfirmationMessage
            action={() => setCalibrationOpen(false)}
            cancel={() => setCalibrationOpen(false)}
            message={calibrationMessage()}
          />
        </Lightbox>
      )}
    </App>
  );
};

export default ProjectEditor;
