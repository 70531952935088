// Colors used by Tailwinds and Styled Components
module.exports = {
  black: '#231F20',
  cyan: {
    '100': '#b3eff8',
    '200': '#80e5f3',
    '300': '#4ddbee',
    '400': '#26d3ea',
    '500': '#00cbe6',
    '600': '#00c6e3',
    '700': '#00bedf',
    '800': '#00b8db',
    '900': '#00acd5',
  },
  gray: {
    '100': '#f5f5f5',
    '200': '#eeeeee',
    '300': '#e0e0e0',
    '400': '#bdbdbd',
    '500': '#9e9e9e',
    '600': '#757575',
    '700': '#616161',
    '800': '#424242',
    '900': '#1f1f1f',
  },
  green: {
    '100': '#b3f4e1',
    '200': '#80eccd',
    '300': '#4de4b9',
    '400': '#26dfaa',
    '500': '#00d99b',
    '600': '#00d593',
    '700': '#00cf89',
    '800': '#00ca7f',
    '900': '#00c06d',
  },
  orange: {
    '100': '#ffcfc8',
    '200': '#ffafa4',
    '300': '#ff8e80',
    '400': '#ff7664',
    '500': '#ff5e49',
    '600': '#ff5642',
    '700': '#ff4c39',
    '800': '#ff4231',
    '900': '#ff3121',
  },
  pink: {
    '100': '#ffd0df',
    '200': '#ffb1ca',
    '300': '#ff91b4',
    '400': '#ff7aa4',
    '500': '#ff6294',
    '600': '#ff5a8c',
    '700': '#ff5081',
    '800': '#ff4677',
    '900': '#ff3465',
  },
  purple: {
    '100': '#ebdfff',
    '200': '#ddc9ff',
    '300': '#cfb3ff',
    '400': '#c5a3ff',
    '500': '#bb93ff',
    '600': '#b58bff',
    '700': '#ac80ff',
    '800': '#a476ff',
    '900': '#9664ff',
  },
  red: {
    '100': '#ffc3c6',
    '200': '#ff9ba1',
    '300': '#ff727b',
    '400': '#ff545e',
    '500': '#ff3642',
    '600': '#ff303c',
    '700': '#E83647',
    '800': '#E22937',
    '900': '#D81620',
  },
  white: '#ffffff',
  yellow: {
    '100': '#ffebb3',
    '200': '#ffde80',
    '300': '#ffd04d',
    '400': '#ffc626',
    '500': '#ffbc00',
    '600': '#ffb600',
    '700': '#ffad00',
    '800': '#ffa500',
    '900': '#ff9700',
  },
};
