import * as R from 'ramda';
import * as Z from '@iconbuild/izzy';

import {
  ContentfulAuthor,
  ContentfulLink,
} from '@/generated/contentful.graphql';

export const isBrowser = typeof window !== 'undefined';

// Capitalize the first letter in a string.
export const capitalize = R.converge(R.concat, [
  R.compose(R.toUpper, R.head),
  R.tail,
]);

// Make entire string kebab-case and remove all non-alphanumeric characters.
export const kebabCase = (str: string) => {
  const matchExp = /[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g;
  const matches = str.match(matchExp) || [];
  return (matches && matches.map((x) => x.toLowerCase()).join('-')) || '';
};

// Make entire string snake-case.
export const snakeCase = (str: string): string => {
  return str.replace(/([A-Z])/g, (x) => R.concat('_', x.toLowerCase()));
};

// Make the string camel case.
export const camelCase = (str: string) => {
  return str
    .replace(/[^a-z]([a-z])/gi, (_, l) => {
      return l.toUpperCase();
    })
    .replace(/[^a-z]+/gi, '')
    .replace(/^([A-Z])/, (_, l) => {
      return l.toLowerCase();
    });
};

// Return 'true' if value is not null or undefined.
export const hasValue = <TValue>(
  value: TValue | null | undefined,
): value is TValue => {
  return value !== null && value !== undefined;
};

// Turn list of Authors into a Name + Title string.
export const authorsToString = (authors: ContentfulAuthor[]) =>
  authors
    .map(
      (author) =>
        `${author?.name} ${author?.title ? `, ${author?.title}` : ''}`,
    )
    .join(', ');

// Filter out non-numeric values to cleanly handle "null" defaults.
export const numeric = (values: (number | null)[]): number[] =>
  values.filter((it): it is number => R.is(Number, it));

// Print Actions to SVG Paths.
export const printActionsToPath = (
  printActions = [] as Z.PrintBuildAction[],
): string =>
  printActions
    ? printActions
        .map((printAction) => {
          return printAction.toolPath.map(Z.pathSVG).join('');
        })
        .join('')
    : '';

// Ensure leading "/" for local links.
export const formatTo = (to: string) => {
  if (/^http/i.test(to)) return to;
  return /^\//.test(to) ? to : `/${to}`;
};

// Get Link "to" from ContentfulLink response
export const getLinkTo = (link: ContentfulLink | null | undefined) => {
  if (!link) return '';

  if (link.page?.__typename === 'ContentfulUpdate') {
    return `/updates/${link.page.slug}`;
  }

  const hash =
    (link.contentSection?.slug && `#${link.contentSection.slug}`) || '';

  if (link.page?.slug) {
    const category = R.pathOr(null, ['page', 'category'], link);
    return category
      ? `${(category as string).toLowerCase()}/${link.page.slug}${hash}`
      : formatTo(`${link.page.slug}${hash}`);
  }

  return `${link?.to || ''}${hash}`;
};

// Convert Location['search'] to a Record<string, string>
export const getSearchQuery = (
  search: Location['search'] | undefined,
): Record<string, string> => {
  return (typeof search === 'string' ? search : '')
    .replace(/^\?/, '')
    .split('&')
    .reduce((q, s) => {
      const [key, value] = s.split('=');
      return {
        ...q,
        [key]: value ?? true,
      };
    }, {});
};

export const delay = (milliseconds: number): Promise<void> => {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve();
    }, milliseconds);
  });
};
