import React from 'react';
import { Router, RouteComponentProps } from '@reach/router';

import AdmixtureList from './admixture-list';
import AddAdmixture from './admixture-add';
import AdmixtureEdit from './admixture-edit';

const Route = ({
  component,
}: RouteComponentProps & { component: React.ReactElement }) => component;

const ToolPathsRouter = () => {
  return (
    <Router>
      <AdmixtureEdit path="/edit/:id/*" />
      <Route path="/" component={<AdmixtureList />} />
      <Route path="/add" component={<AddAdmixture />} />
    </Router>
  );
};

export default ToolPathsRouter;
