import React from 'react';
import styled, { css } from 'styled-components';

import { AuthContext } from '@providers/auth';
import { Theme } from '@/types';
import AccountNav from '../nav-account';

const containerTheme = ({ theme }: { theme: Theme }) => css`
  padding-top: ${theme === Theme.Content ? '4.5rem' : '0'};
  top: ${theme === Theme.Content ? '0' : '4.5rem'};
`;

const Container = styled.div<{ active: boolean; theme: Theme }>`
  background: white;
  bottom: 0;
  font-family: 'Montserrat', sans-serif;
  width: 480px;
  max-width: 100vw;
  position: fixed;
  text-transform: uppercase;
  transition: right 200ms;
  right: ${({ active }) => (active ? 0 : '-480px')};
  z-index: 99;
  ${containerTheme}
`;

export const MobileNav = ({
  auth,
  active,
  extraNav = null,
  theme,
}: {
  active: boolean;
  auth: AuthContext | null;
  extraNav?: React.ReactNode | null;
  theme: Theme;
}) => (
  <Container active={active} theme={theme}>
    <div>{extraNav}</div>
    {auth && <AccountNav auth={auth} />}
  </Container>
);

export default MobileNav;
