import React from 'react';
import styled from 'styled-components';

import colors from '@/constants/colors';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  flex-direction: column;
  text-align: center;
`;

const Text = styled.div`
  color: ${colors.gray['700']};
  font-size: 1.25rem;
  padding: 3rem 0 0;
  text-transform: uppercase;
`;

export const NoResults = ({ title }: { title: string }) => (
  <Wrapper>
    <Text>{title}</Text>
  </Wrapper>
);

export default NoResults;
