import React from 'react';

interface FormErrorProps {
  children: React.ReactNode;
}

export const FormError = ({ children }: FormErrorProps) => {
  return (
    <span
      data-testid="form-error"
      className="text-sm italic pl-2 text-red-500"
    >
      {children}
    </span>
  );
};

export default FormError;
