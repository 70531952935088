import React from 'react';
import styled from 'styled-components';

import colors from '@/constants/colors';

interface ChecklistButtonProps {
  children: React.ReactNode | React.ReactNode[];
  color?: string;
  className?: string;
  disabled?: boolean;
  filled?: boolean;
  onClick: (event: React.MouseEvent) => void;
  leading?: React.ReactNode | React.ReactNode[];
  trailing?: React.ReactNode | React.ReactNode[];
}

const BUTTON = styled.button<{ color: string; filled: boolean }>`
  align-items: center;
  background-color: ${({ color, filled }) => (filled ? color : 'transparent')};
  border-radius: 0.25rem;
  border: 1px solid ${({ color }) => color};
  color: ${({ color, filled }) => (filled ? 'white' : color)};
  display: inline-flex;
  font-size: 1rem;
  font-weight: 500;
  letter-spacing: 0.1ch;
  line-height: 2.375em;
  margin: 0;
  padding: 0 0.75em;
  text-transform: uppercase;
  transition: background-color 200ms, color 200ms;

  &:focus {
    outline: none;
  }

  &:hover {
    background-color: ${({ color }) => color};
    color: white;
  }

  svg:first-child {
    margin-right: 0.5rem;
  }
`;

export const ChecklistButton = ({
  children,
  className = '',
  color = colors.cyan['500'],
  disabled = false,
  filled = false,
  onClick,
  leading,
  trailing,
}: ChecklistButtonProps) => {
  return (
    <BUTTON
      className={className}
      color={color}
      filled={filled}
      onClick={(event: React.MouseEvent) => {
        if (disabled) return;

        onClick(event);
      }}
    >
      {leading}
      {children}
      {trailing}
    </BUTTON>
  );
};

export default ChecklistButton;
