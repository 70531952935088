import React from 'react';
import { Router, RouteComponentProps } from '@reach/router';

import ChecklistsList from './checklists-list';
import ChecklistsEdit from './checklists-edit';

const Route = ({
  component,
}: RouteComponentProps & { component: React.ReactElement }) => component;

const ChecklistsRouter = () => {
  return (
    <Router>
      <ChecklistsEdit path="/:id" />
      <Route path="/" component={<ChecklistsList />} />
    </Router>
  );
};

export default ChecklistsRouter;
