import React from 'react';
import styled from 'styled-components';

export const sizes = {
  small: 24,
  medium: 48,
  large: 96,
};

export type AvatarSize = keyof typeof sizes;

const imgSize = ({ size }: { size: AvatarSize }) =>
  ({
    small: 24,
    medium: 48,
    large: 96,
  }[size]);

const Img = styled.img<{ size: AvatarSize }>`
  width: ${imgSize}px;
  height: ${imgSize}px;
  border-radius: 50%;
`;

export const Avatar = ({
  name,
  size,
  url,
}: {
  name: string;
  size: AvatarSize;
  url: string;
}) => <Img size={size} alt={name} src={url} referrerPolicy="no-referrer" />;

export default Avatar;
