import { equals, isEmpty } from 'ramda';
import { ValidationError } from 'jsonschema';
import React, { useEffect, useState } from 'react';

import 'prismjs/components/prism-json';
import 'prismjs/themes/prism.css';

import Button from '@atoms/button';
import FormError from '@atoms/form-error';
import Label from '@atoms/label';
import SectionLabel from '@atoms/section-label';
import Select from '@atoms/select';

import {
  AdmixtureFixedDosing,
  AdmixtureForm,
  AdmixtureFunction,
  AdmixtureSteppedDosing,
} from '../types';
import { generateKey } from '../utils';
import { validate, toErrorMessage } from './validator';
import { Input, LabelNote, LabelUnit } from './styles';
import FixedDosingEditor from './fixed-dosing-editor';
import SteppedDosingEditor from './stepped-dosing-editor';
import {
  defaultAdmixture,
  defaultFixedDosing,
  defaultSteppedDosing,
} from './defaults';

interface AdmixtureEditorProps {
  children?: React.ReactNode | React.ReactNode[];
  className?: string;
  initialAdmixture?: AdmixtureForm;
  editableKey?: boolean;
  existingKeys?: Set<string>;
  save: (data: AdmixtureForm) => void;
  admixtureFunctions: AdmixtureFunction[];
}

const enforcedPriorities = Array.from({ length: 7 }, (_, i) => ({
  value: i,
  label: i ? `${i}` : 'None',
}));

interface DosingType {
  value: number;
  key?: string;
  label: string;
}

const dosingTypes: DosingType[] = [
  {
    value: 0,
    label: 'None',
    key: undefined,
  },
  {
    value: 1,
    label: 'Fixed',
    key: 'FIXED',
  },
  {
    value: 2,
    label: 'Dynamic - Stepped',
    key: 'STEPPED',
  },
];

export const AdmixtureEditor = ({
  children = null,
  className = '',
  initialAdmixture = defaultAdmixture,
  save,
  editableKey = false,
  existingKeys = new Set<string>(),
  admixtureFunctions,
}: AdmixtureEditorProps) => {
  const [saving, setSaving] = useState<boolean>(false);
  const [savedAdmixture, setSavedAdmixture] = useState<AdmixtureForm>(
    initialAdmixture,
  );
  const [admixture, setAdmixture] = useState<AdmixtureForm>(initialAdmixture);
  const [errors, setErrors] = useState<ValidationError[]>([]);
  const initialDosingType: DosingType =
    dosingTypes.find((type) => type.key === initialAdmixture?.dosing?.type) ||
    dosingTypes[0];
  const [selectedDosingType, setSelectedDosingType] = useState<DosingType>(
    initialDosingType,
  );
  const [keyIsCustomized, setKeyIsCustomized] = useState<boolean>(
    !!initialAdmixture?.key,
  );

  // Keep state of each dosing individually to allow switching from one type
  // to another when editing without losing the values.
  const [fixedDosing, setFixedDosing] = useState<AdmixtureFixedDosing>(
    initialAdmixture?.dosing?.type === 'FIXED'
      ? (initialAdmixture?.dosing as AdmixtureFixedDosing)
      : defaultFixedDosing,
  );
  const [steppedDosing, setSteppedDosing] = useState<AdmixtureSteppedDosing>(
    initialAdmixture?.dosing?.type === 'STEPPED'
      ? (initialAdmixture?.dosing as AdmixtureSteppedDosing)
      : defaultSteppedDosing,
  );

  // Clear out errors on change.
  useEffect(() => {
    const changeErrors = validate(admixture, existingKeys);
    setErrors(errors.filter((e) => changeErrors.includes(e)));
    setSavedAdmixture(initialAdmixture);
  }, [admixture, initialAdmixture]);

  useEffect(() => {
    // Update admixture with updated selection if required
    if (selectedDosingType.key === 'FIXED') {
      if (admixture.dosing !== fixedDosing) {
        setAdmixture({
          ...admixture,
          dosing: fixedDosing,
        });
      }
    } else if (selectedDosingType.key === 'STEPPED') {
      if (admixture.dosing !== steppedDosing) {
        setAdmixture({
          ...admixture,
          dosing: steppedDosing,
        });
      }
    } else if (admixture.dosing) {
      admixture.dosing = undefined;
    }
  }, [admixture, selectedDosingType, fixedDosing, steppedDosing]);

  const hasError = (key: string) =>
    errors.some(
      (err) =>
        err.property === `instance.${key}` ||
        (err.property === 'instance' && err.argument === key),
    );

  const getError = (key: string) =>
    errors
      .filter(
        (err) =>
          err.property === `instance.${key}` ||
          (err.property === 'instance' && err.argument === key),
      )
      .map(toErrorMessage)
      .join('\n');

  const handleSubmit = equals(savedAdmixture, admixture)
    ? undefined
    : async (
        event:
          | React.FormEvent<HTMLFormElement>
          | React.FormEvent<HTMLButtonElement>,
      ): Promise<void> => {
        event.preventDefault();
        const validationErrors = validate(admixture, existingKeys);
        setErrors(validationErrors);

        if (isEmpty(validationErrors)) {
          setSaving(true);
          await save(admixture);
          setSaving(false);
        }
      };

  const onNameChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const name = event.target?.value || '';
    let { key } = admixture;
    if (editableKey && !keyIsCustomized) {
      // Suggest key
      key = generateKey(name, existingKeys);
    }
    setAdmixture({
      ...admixture,
      name,
      key,
    });
  };

  const onProducerChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ): void => {
    const value = event.target?.value;
    setAdmixture({
      ...admixture,
      producer: value || '',
    });
  };

  const onKeyChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const value = event.target?.value;
    setKeyIsCustomized(!!value);
    setAdmixture({
      ...admixture,
      key: value || '',
    });
  };

  const onDensityChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ): void => {
    setAdmixture({
      ...admixture,
      density: event.target?.value
        ? parseFloat(event.target?.value)
        : undefined,
    });
  };

  const onEnforcedPriorityChange = (selectedOption: {
    value: number;
    label: string;
  }) => {
    const enforcedPriority = selectedOption?.value
      ? selectedOption?.value
      : undefined;

    setAdmixture({
      ...admixture,
      enforcedPriority,
    });
  };

  const onAdmixtureFunctionChange = (selectedOption: {
    value: string;
    label: string;
  }) => {
    const admixtureFunctionKey = selectedOption?.value
      ? selectedOption?.value
      : undefined;

    setAdmixture({
      ...admixture,
      admixtureFunctionKey,
    });
  };

  const selectedEnforcedPriority =
    enforcedPriorities.find((p) => p.value === admixture.enforcedPriority) ||
    enforcedPriorities[0];

  const selectedAdmixtureFunction =
    admixtureFunctions.find(
      (p) => p.value === admixture.admixtureFunctionKey,
    ) || null;

  return (
    <div className={`bg-white p-6 rounded-md ${className}`}>
      {children}
      <form onSubmit={handleSubmit}>
        <div className="grid gap-4 grid-cols-4">
          <div className={editableKey ? 'col-span-1' : 'col-span-2'}>
            <Label>Name</Label>
            <Input onChange={onNameChange} value={admixture.name} />
            {hasError('name') && <FormError>{getError('name')}</FormError>}
          </div>
          {editableKey && (
            <div>
              <Label>
                <span>Key</span>
                {!keyIsCustomized && <LabelNote>(suggested)</LabelNote>}
              </Label>
              <Input onChange={onKeyChange} value={admixture.key} />
              {hasError('key') && <FormError>{getError('key')}</FormError>}
            </div>
          )}
          <div className="col-span-2">
            <Label>
              <span>Producer</span>
              <LabelNote>(optional)</LabelNote>
            </Label>
            <Input onChange={onProducerChange} value={admixture.producer} />
            {hasError('producer') && (
              <FormError>{getError('producer')}</FormError>
            )}
          </div>
          <div className="col-span-2">
            <Label>
              <span>Function</span>
              <LabelNote>(optional)</LabelNote>
            </Label>
            <Select
              isClearable
              onChange={onAdmixtureFunctionChange}
              options={admixtureFunctions}
              value={selectedAdmixtureFunction}
            />
            {hasError('admixtureFunction') && (
              <FormError>{getError('admixtureFunction')}</FormError>
            )}
          </div>
          <div className="col-span-2">
            <Label>
              <span>Density</span>
            </Label>
            <div className="flex items-center">
              <Input
                type="number"
                onChange={onDensityChange}
                value={admixture.density ?? ''}
                step={0.01}
                min={0}
                max={10}
              />
              <LabelUnit>
                g/cm<sup>3</sup>
              </LabelUnit>
            </div>
            {hasError('density') && (
              <FormError>{getError('density')}</FormError>
            )}
          </div>
          <div className="col-span-2">
            <Label>
              <span>Enforced priority</span>
              <LabelNote>(optional)</LabelNote>
            </Label>
            <Select
              onChange={onEnforcedPriorityChange}
              options={enforcedPriorities}
              value={selectedEnforcedPriority}
            />
            {hasError('enforcedPriority') && (
              <FormError>{getError('enforcedPriority')}</FormError>
            )}
          </div>
          <div className="col-span-4">
            <SectionLabel>Recommended dosage</SectionLabel>
          </div>
          <div>
            <Label>Type</Label>
            <Select
              options={dosingTypes}
              value={selectedDosingType}
              onChange={setSelectedDosingType}
            />
          </div>
          {selectedDosingType.key === 'FIXED' && (
            <FixedDosingEditor
              initialFixedDosing={fixedDosing}
              onChange={setFixedDosing}
              errors={errors}
              errorBasePath="instance.dosing"
            />
          )}
          {selectedDosingType.key === 'STEPPED' && (
            <SteppedDosingEditor
              initialStepDosing={steppedDosing}
              onChange={setSteppedDosing}
              errors={errors}
              errorBasePath="instance.dosing"
            />
          )}
          <div className="col-span-4 col-start-1">
            <Button className="float-right" onClick={handleSubmit}>
              {saving ? 'Saving' : 'Save'}
            </Button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default AdmixtureEditor;
