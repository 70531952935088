import React from 'react';
import styled from 'styled-components';
import Button from '@atoms/button';

const Container = styled.div`
  padding: 16px;
  min-width: 400px;
`;

const Buttons = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 32px;
  button {
    margin-left: 8px;
    &:first-child {
      margin-left: 0;
    }
  }
`;

const ConfirmationMessage = ({
  action,
  cancel,
  message,
}: {
  action: () => void;
  cancel: () => void;
  message: string;
}) => {
  return (
    <Container>
      <div>{message}</div>
      <Buttons>
        <Button secondary onClick={cancel}>
          Cancel
        </Button>
        <Button onClick={action} danger>
          Continue
        </Button>
      </Buttons>
    </Container>
  );
};

export default ConfirmationMessage;
