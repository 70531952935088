import { differenceWith, equals, omit, update } from 'ramda';
import { useQuery, useMutation } from '@apollo/client';
import React, { useState } from 'react';
import styled from 'styled-components';

import {
  AddChecklistMutation,
  GetChecklistsQuery,
  SoftDeleteChecklistMutation,
} from '@/generated/hasura.graphql';
import { useAuth } from '@providers/auth';
import App from '@templates/app';
import colors from '@/constants/colors';
import CirclePlusSvg from '@/components/icons/circle-plus';
import ConfirmationMessage from '@molecules/confirmation-message';
import Lightbox from '@molecules/lightbox';
import EmptyPage from '@/admin/empty-page';

import { Checklist } from '../types';
import {
  generateChecklist,
  processGetChecklistDbResponse,
  removeChecklistById,
  transformChecklistDbEntity,
  transformChecklistToDbEntity,
} from '../calculations';
import {
  addChecklist as addChecklistMutation,
  getChecklists as getChecklistsQuery,
  softDeleteChecklist as softDeleteChecklistMutation,
} from '../queries';
import ChecklistButton from '../button';
import CheckListPanel from './list-panel';
import ListItem from './list-item';

interface ChecklistsListProps {
  className?: string;
}

const Actions = styled.div`
  display: inline-flex;

  > * + * {
    margin-left: 0.5rem;
  }
`;

export const ChecklistsList = ({ className = '' }: ChecklistsListProps) => {
  // Retrieve checklists from Sol.
  const { loading, error, data } = useQuery<GetChecklistsQuery>(
    getChecklistsQuery,
  );

  // Get auth context.
  const auth = useAuth();
  const userName = auth?.user ? auth.user.name : 'N/A';

  // Flag state for initializing checklists.
  const [initialized, setInitialized] = useState<boolean>(false);

  // Actively selected Checklist for Panel rendering.
  const [selectedChecklist, setSelectedChecklist] = useState<string | null>(
    null,
  );

  // Checklist item id selected for deletion.
  const [deleteItems, setDeleteItems] = useState<Checklist[] | null>(null);

  // Mirror of Checklists as known to Sol.
  const [savedChecklists, setSavedChecklists] = useState<Checklist[]>([]);

  // Manage Working Checklists.
  const [checklists, setChecklists] = useState<Checklist[]>([]);

  // Add Checklist to DB
  const [addChecklist] = useMutation<AddChecklistMutation>(
    addChecklistMutation,
  );

  // Archive Checklist in DB.
  const [archiveChecklist] = useMutation<SoftDeleteChecklistMutation>(
    softDeleteChecklistMutation,
  );

  // Handle Loading / Error states for Get Checklists Query.
  if (loading) return <EmptyPage sectionLabel="Checklists" />;
  if (error || !data) return <EmptyPage problem sectionLabel="Checklists" />;

  // Populate initial state of Checklists.
  if (!initialized) {
    const checklistsData = processGetChecklistDbResponse(data);
    setSavedChecklists(checklistsData);
    setChecklists(checklistsData);
    setInitialized(true);
  }

  // Update Partial entry of Checklist by ID for working Checklists.
  const updateChecklist = (
    payload: Partial<Checklist>,
    id: string | null = selectedChecklist,
  ) => {
    const index = checklists.findIndex((c) => c.id === id);

    if (index > -1) {
      setChecklists(
        update(index, { ...checklists[index], ...payload }, checklists),
      );
    }
  };

  // Update Partial entry of Checklist by ID for saved Checklists.
  const updateSavedChecklists = (payload: Checklist) => {
    const index = savedChecklists.findIndex((c) => c.id === payload.id);

    if (index > -1) {
      setSavedChecklists(
        update(index, { ...checklists[index], ...payload }, checklists),
      );
      return;
    }

    // Add to saved checklists if this checklist is not in the existing set.
    setSavedChecklists([...savedChecklists, payload]);
  };

  // Modifies Checklist in DB and modifies the local state.
  const handleUpdateChecklist = async (checklist: Checklist) => {
    const payload = omit(['createdAt', 'updatedAt', 'archived'], checklist);

    const added = await addChecklist({
      variables: {
        ...payload,
        items: JSON.stringify(
          payload.items.map((i) => transformChecklistToDbEntity(i)),
        ),
      },
    });

    if (added.data?.insert_checklist_one) {
      const addedChecklist = transformChecklistDbEntity(
        added.data.insert_checklist_one,
      );
      const { id } = addedChecklist;
      updateChecklist(addedChecklist, id);
      updateSavedChecklists(addedChecklist);
    }
  };

  // Archive Checklists that were "Deleted" before clicking "Save".
  const handleArchiveChecklists = async (items: Checklist[]) => {
    const promises = items.map((checklist) => {
      return archiveChecklist({
        variables: {
          id: checklist.id,
        },
      });
    });

    await Promise.all(promises);
    setDeleteItems(null);

    // Remove "Saved" checklist items that are within this list.
    const deleteIds = items.map((i) => i.id);
    setSavedChecklists(
      savedChecklists.filter((checklist) => {
        return !deleteIds.includes(checklist.id);
      }),
    );
  };

  const selectedChecklistItem = checklists.find(
    (checklist) => checklist.id === selectedChecklist,
  );

  const panel = selectedChecklistItem ? (
    <CheckListPanel
      key={selectedChecklist || ''}
      update={updateChecklist}
      {...selectedChecklistItem}
    />
  ) : null;

  const changed = !equals(savedChecklists, checklists);

  const actions = (
    <Actions>
      <ChecklistButton
        filled
        leading={<CirclePlusSvg />}
        onClick={() => {
          setChecklists([
            ...checklists,
            generateChecklist(userName)('New Checklist'),
          ]);
        }}
      >
        List
      </ChecklistButton>
      <ChecklistButton
        className="mt-4"
        color={changed ? colors.cyan['500'] : colors.gray['300']}
        disabled={!changed}
        filled
        onClick={() => {
          checklists.forEach((checklist) => {
            const savedChecklist = savedChecklists.find(
              (sc) => sc.id === checklist.id,
            );

            // Update each changed checklist.
            if (!equals(savedChecklist, checklist)) {
              handleUpdateChecklist(checklist);
            }
          });

          // Look to see if any checklists have been removed.
          const cmp = (a: Checklist, b: Checklist) => a.id === b.id;
          const removed = differenceWith(cmp, savedChecklists, checklists);

          if (removed.length) {
            setDeleteItems(removed);
          }
        }}
      >
        Save
      </ChecklistButton>
    </Actions>
  );

  return (
    <App
      section="checklists"
      title="Checklists"
      panel={panel}
      action={actions}
    >
      <div className={`${className}`}>
        {checklists.map((item, i) => {
          return (
            <div key={`checklist-wrapper-${i}`} className="mb-4">
              <ListItem
                className={selectedChecklist === item.id ? 'active' : ''}
                key={`checklist-${i}`}
                remove={() => {
                  setChecklists(removeChecklistById(item.id, checklists));
                }}
                toggle={() => {
                  if (item.id === selectedChecklist) {
                    setSelectedChecklist(null);
                    return;
                  }

                  setSelectedChecklist(item.id);
                }}
                {...item}
              />
            </div>
          );
        })}
      </div>
      {deleteItems !== null ? (
        <Lightbox
          title="Delete Checklist Items"
          close={() => setDeleteItems(null)}
        >
          <ConfirmationMessage
            action={() => handleArchiveChecklists(deleteItems)}
            cancel={() => setDeleteItems(null)}
            message={`Are you sure you want to delete (${deleteItems.length}) checklist items?`}
          />
        </Lightbox>
      ) : null}
    </App>
  );
};

export default ChecklistsList;
