import {
  ApolloClient,
  ApolloProvider,
  HttpLink,
  InMemoryCache,
  NormalizedCacheObject,
} from '@apollo/client';

import React, { useState, useEffect } from 'react';

import { useAuth } from './auth';

const createApolloClient = (authToken: string) => {
  return new ApolloClient({
    link: new HttpLink({
      uri: process.env.SOL_URL || '',
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    }),
    cache: new InMemoryCache(),
  });
};

const Provider = ({ children }: { children: React.ReactNode }) => {
  const auth = useAuth();
  const token = auth?.token;

  const [client, setClient] = useState(
    null as ApolloClient<NormalizedCacheObject> | null,
  );

  useEffect(() => {
    if (!token) return;
    setClient(createApolloClient(token));
  }, [token]);

  if (!client) return null;
  return <ApolloProvider client={client}>{children}</ApolloProvider>;
};

export default Provider;
