import React from 'react';

import Loading from '@molecules/loading';
import Problem from '@molecules/problem';
import App from '@templates/app';

// Component to show loading/error state when we don't have data yet.
const EmptyPage = ({
  problem = false,
  section = 'project',
  sectionLabel,
}: {
  problem?: boolean;
  section?: string;
  sectionLabel: string;
}) => (
  <App section={section} title={sectionLabel} data-testid="empty-page">
    {problem ? <Problem /> : <Loading />}
  </App>
);

export default EmptyPage;
