export enum ColorTheme {
  Dark = 'dark',
  Light = 'light',
}

export interface MatcherBase {
  typename: string;
}

export enum SizeTheme {
  Default = 'default',
  Jumbo = 'jumbo',
  Large = 'large',
  Small = 'small',
}

export enum Theme {
  Content = 'content',
  UI = 'ui',
  ProUI = 'pro-ui',
}

export enum ColumnTitleSize {
  Default = 'default',
  Large = 'large',
}

export enum ColumnTitleTheme {
  Default = 'default',
  Gradient = 'gradient',
}
