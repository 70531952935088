import React from 'react';
import styled from 'styled-components';
import { Theme } from '@/types';
import { darkGray, white } from '@/data/colors';

const SVG = styled.svg<{ active: boolean }>`
  color: ${({ active, theme }) =>
    active && theme === Theme.Content ? darkGray : white};
  height: 40px;
  display: block;
  width: 40px;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
  transition: transform 400ms;
  user-select: none;
  transform: ${({ active }) => (active ? 'rotate(45deg)' : 'none')};
  transition: color 300ms;
`;

const Line = styled.path`
  fill: none;
  transition: stroke-dasharray 400ms, stroke-dashoffset 400ms;
  stroke: currentColor;
  stroke-width: 3;
  stroke-linecap: round;
`;

const FancyLine = styled(Line)<{ active: boolean }>`
  stroke-dasharray: 40 121;
  stroke-dashoffset: ${({ active }) => (active ? '-68px' : 'none')};
`;

export const Hamburger = ({
  active = false,
  onClick,
  theme,
}: {
  active?: boolean;
  onClick: () => void;
  theme: Theme;
}) => (
  <SVG
    active={active}
    onClick={onClick}
    theme={theme}
    viewBox="15 15 70 70"
    width="80"
  >
    <FancyLine
      active={active}
      d="m 70,33 h -40 c 0,0 -8.5,-0.149796 -8.5,8.5 0,8.649796 8.5,8.5 8.5,8.5 h 20 v -20"
    />
    <Line d="m 70,50 h -40" />
    <FancyLine
      active={active}
      d="m 30,67 h 40 c 0,0 8.5,0.149796 8.5,-8.5 0,-8.649796 -8.5,-8.5 -8.5,-8.5 h -20 v 20"
    />
  </SVG>
);

export default Hamburger;
