import { omit, pipe } from 'ramda';
import { useQuery } from '@apollo/client';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import {
  GetPrintEventsSubscription,
  Project,
  System,
} from '@/generated/hasura.graphql';
import App from '@templates/app';
import Button from '@atoms/button';
import DateRange from '@atoms/date-range';
import Loading from '@molecules/loading';
import Problem from '@molecules/problem';
import TableView from '@organisms/table-view';
import { getPrintEvents } from './queries';
import SystemSelect from './system-select';
import {
  formatDate,
  formatItemLength,
  formatProjectLabel,
  formatSystemLabel,
} from '../utils';
import ProjectSelect from './project-select';
import TypeSelect from './type-select/type-select';

type PrintEvents = GetPrintEventsSubscription['print_event'];

const FittedDiv = styled.div`
  width: fit-content;
  float: right;
  margin-bottom: 0.5rem;
`;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const formatPayload = (payload: any) => {
  if (payload === undefined || payload === null) {
    return '';
  }
  if (payload.admixture) {
    return `${payload.admixture.name}: ${payload.targetDosage}`;
  }
  return '';
};

const PrintEventDataTable = ({
  onChange,
  startDate = null,
  endDate = null,
  limit = 300,
  offset = 0,
  systems = [],
  projects = [],
  types = [],
}: {
  onChange: (printEventData: PrintEvents) => void;
  startDate: Date | null;
  endDate: Date | null;
  limit: number | null;
  offset: number | null;
  systems: System[];
  projects: Project[];
  types: string[];
}) => {
  // Get Print Event data from database.
  const { data, loading, error } = useQuery<GetPrintEventsSubscription>(
    getPrintEvents,
    {
      variables: {
        startDate,
        endDate,
        limit,
        offset,
        systemIds:
          systems && systems.length > 0
            ? systems.map((system) => system.id)
            : null,
        projectIds:
          projects && projects.length > 0
            ? projects.map((project) => project.id)
            : null,
        types: types && types.length > 0 ? types : null,
      },
    },
  );

  // Dispatch onChange event when data is updated.
  useEffect(() => {
    if (data && Array.isArray(data?.print_event)) onChange(data.print_event);
  }, [data]);

  // Show loading or problem message if we don't have data yet.
  if (loading) return <Loading />;
  if (error || !data?.print_event) return <Problem />;

  const printEvents = data.print_event.filter((e) => {
    // Ignore print events with no project attached
    return !!e.project;
  });

  // Render Table view of Print Event Data.
  return (
    <div>
      <TableView
        data={{
          fields: [
            {
              ref: 'system',
              label: 'System',
              formatter: (s) => formatSystemLabel(s),
            },
            {
              ref: 'occurred_at',
              label: 'Time',
              formatter: (d) => formatDate(d),
            },
            {
              ref: 'project',
              label: 'Project',
              formatter: (p) => formatProjectLabel(p),
            },
            { ref: 'layer', label: 'Layer' },
            {
              ref: 'tool_path_inches',
              label: 'Item Length',
              formatter: (tpi) => formatItemLength(tpi),
            },
            { ref: 'type', label: 'Type' },
            { ref: 'height', label: 'Height' },
            { ref: 'dry_run', label: 'Dry Run' },
            {
              ref: 'payload',
              label: 'Data',
              formatter: (payload) => formatPayload(payload),
            },
          ],
          rows: printEvents.map((p, i) => ({
            id: i,
            ...omit(['id'], p),
          })),
        }}
      />
    </div>
  );
};

const PrintEventData = () => {
  // Initialize Print Event Data Set state.
  const [printEventData, setPrintEventData] = useState<PrintEvents>([]);

  // Initialize Date Range Filter state.
  const [dateRange, setDateRange] = useState<[Date | null, Date | null]>([
    null,
    null,
  ]);
  const [startDate, endDate] = dateRange;

  const [selectedTypes, setSelectedTypes] = useState<string[]>([]);
  const [selectedSystems, setSelectedSystems] = useState<System[]>([]);
  const [selectedProjects, setSelectedProjects] = useState<Project[]>([]);
  const [queryOffset, setQueryOffset] = useState<number>(0);
  const [queryLimit] = useState<number>(300);

  const handleDownload = () => {
    // Flatten csvData to handle nested GraphQL queries.
    const flattenObject = (
      o: object,
      keys: string[] = [],
    ): { [key: string]: string | number | null } =>
      Object.entries(o).reduce((flatObject, [key, value]) => {
        if (Array.isArray(value))
          // Create an object from Arrays and increment the key by one for the
          // object property.
          return {
            ...flatObject,
            ...flattenObject(
              value.reduce(
                (obj, v, index) => ({ ...obj, [index + 1]: v }),
                {},
              ),
              [...keys, key],
            ),
          };

        if (typeof value === 'object')
          // Remove Extra GraphQL response properties from object.
          return {
            ...flatObject,
            ...flattenObject(omit(['__typename'], value), [...keys, key]),
          };

        return {
          ...flatObject,
          [[...keys, key].join('.')]: value,
        };
      }, {});

    // Format data values for spreadsheet.
    const formatValues = (o: object) =>
      Object.entries(o).reduce((formattedObject, [key, value]) => {
        if (/_time|_at/i.test(key)) {
          return {
            ...formattedObject,
            [key]: formatDate(value),
          };
        }
        if (/_path|_inches/i.test(key)) {
          return {
            ...formattedObject,
            [key]: formatItemLength(value),
          };
        }

        return formattedObject;
      }, o);

    // Omit unnecessary keys from print event objects and flatten.
    const csvData = printEventData.map((md) =>
      pipe(flattenObject, formatValues)(omit(['__typename'], md)),
    );

    // We need to calculate a full list of the unique keys because the each
    // row may have different fields populated.  There isn't a great way
    // to do this other than loop through all the keys of all the rows
    // and build the set of field names.
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const allKeys: string[] = csvData.reduce((acc: string[], d: any) => {
      return Object.keys(d).reduce((acc2, k) => {
        if (!acc2.includes(k)) {
          return [...acc2, k];
        }
        return acc2;
      }, acc);
    }, []);

    // Convert Print Event Array into CSV data.
    const csv = [
      allKeys.join(','),
      ...csvData.map((d) =>
        // use the list of all keys to pull each field for each row
        allKeys
          .map((k) => {
            // Pull out each field, normalizing to '' if missing or null
            return d[k] === undefined || d[k] === null ? '' : d[k];
          })
          .join(','),
      ),
    ].join('\n');

    // Create HTMLAnchorElement to use for triggering a download.
    const link = document.createElement('a');
    link.setAttribute(
      'href',
      `data:text/csv;charset=utf-8,${encodeURIComponent(csv)}`,
    );
    link.setAttribute('download', 'print-event-data.csv');
    link.click();
    return null;
  };

  const previousPageOnClick = () => {
    return queryOffset > 0
      ? () => setQueryOffset(queryOffset - queryLimit)
      : undefined;
  };

  const nextPageOnClick = () => {
    // When the amount of results is less than the query limit, we have reached
    // the end of the results.
    return printEventData.length >= queryLimit
      ? () => setQueryOffset(queryOffset + queryLimit)
      : undefined;
  };

  return (
    <App section="printevent-data" title="Print Event Data">
      <div className="mb-4 flex flex-wrap justify-end">
        <div className="mb-2 flex float-left">
          <SystemSelect className="mr-2" onChange={setSelectedSystems} />
          <DateRange
            className="mr-2"
            onChange={setDateRange}
            value={dateRange}
          />
          <TypeSelect className="mr-2" onChange={setSelectedTypes} />
          <ProjectSelect className="mr-2" onChange={setSelectedProjects} />
        </div>
        <FittedDiv>
          <Button onClick={printEventData.length ? handleDownload : undefined}>
            Download CSV
          </Button>
        </FittedDiv>
      </div>
      <PrintEventDataTable
        endDate={endDate}
        onChange={setPrintEventData}
        startDate={startDate}
        limit={queryLimit}
        offset={queryOffset}
        systems={selectedSystems}
        projects={selectedProjects}
        types={selectedTypes}
      />
      <div className="float-left my-4">
        <Button onClick={previousPageOnClick()}>Prev</Button>
      </div>
      <div className="float-right my-4">
        <Button onClick={nextPageOnClick()}>Next</Button>
      </div>
    </App>
  );
};
export default PrintEventData;
