import * as Z from '@iconbuild/izzy';
import React from 'react';
import styled from 'styled-components';

import GridRow from '@molecules/grid-row';
import SectionLabel from '@atoms/section-label';
import { NumberDataField, TextDataField } from '@molecules/data-field';
import { ProjectData } from './project-editor';

interface Props {
  sourceData: Z.SitePlanSourceData;
  setSourceData: (newData: Z.SitePlanSourceData) => void;
  projectData: ProjectData;
  setProjectData: (newData: ProjectData) => void;
}

const Row = styled.div`
  margin: 16px 0;
`;

const SiteDetails = ({
  sourceData,
  setSourceData,
  projectData,
  setProjectData,
}: Props) => {
  const setField = (key: string, value: number) =>
    setSourceData({
      ...sourceData,
      [key]: value,
    });
  return (
    <>
      <SectionLabel>Site Details</SectionLabel>
      <Row>
        <TextDataField
          label="Project Name"
          value={projectData.label}
          setValue={(val) => setProjectData({ ...projectData, label: val })}
        />
      </Row>
      <Row>
        <TextDataField
          label="Project Location"
          value={projectData.site}
          setValue={(val) => setProjectData({ ...projectData, site: val })}
        />
      </Row>
      <Row>
        <GridRow spacing={32} breakpoint={Infinity}>
          <NumberDataField
            label="Foundation Width"
            value={sourceData.foundationWidthInches}
            setValue={(val) => setField('foundationWidthInches', val)}
          />
          <NumberDataField
            label="Foundation Length"
            value={sourceData.foundationLengthInches}
            setValue={(val) => setField('foundationLengthInches', val)}
          />
        </GridRow>
      </Row>
      <Row>
        <GridRow spacing={32} breakpoint={Infinity}>
          <NumberDataField
            label="Latitude"
            value={sourceData.latitudeDegrees}
            setValue={(val) => setField('latitude', val)}
          />
          <NumberDataField
            label="Longitude"
            value={sourceData.longitudeDegrees}
            setValue={(val) => setField('longitude', val)}
          />
        </GridRow>
      </Row>
      <Row>
        <GridRow spacing={32} breakpoint={Infinity}>
          <NumberDataField
            label="Orientation"
            value={sourceData.orientationDegrees}
            setValue={(val) => setField('orientation', val)}
          />
          <div />
        </GridRow>
      </Row>
    </>
  );
};

export default SiteDetails;
