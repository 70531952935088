import { navigate } from 'gatsby';
import React from 'react';
import styled from 'styled-components';

import colors from '@/constants/colors';
import { Checklist } from '../types';
import ChecklistActions from '../actions';
import ChecklistItem from './checklist';

const Name = styled.h3`
  color: ${colors.black};
  font-family: Roboto;
  font-size: 1rem;
  font-weight: 300;
  line-height: 1.25em;
  user-select: none;
`;

interface ChecklistProps extends Checklist {
  className?: string;
  remove: () => void;
  toggle: () => void;
}

export const ChecklistListItem = ({
  className = '',
  id,
  name,
  remove,
  toggle,
}: ChecklistProps) => {
  return (
    <ChecklistItem className={className} onClick={() => toggle()}>
      <Name>{name}</Name>
      <ChecklistActions
        actions={[
          {
            label: 'Edit',
            icon: 'edit',
            action: () => navigate(`/checklists/${id}`),
          },
          {
            label: 'Delete',
            icon: 'trash',
            action: () => {
              remove();
            },
          },
        ]}
      />
    </ChecklistItem>
  );
};

export default ChecklistListItem;
