import {
  AdmixtureFixedDosing,
  AdmixtureForm,
  AdmixtureSteppedDosing,
} from '../types';
import {
  ambientTemperatureRangeMinimumHighDefined,
  ambientTemperatureRangeMinimumLowDefined,
} from './validator';

export const defaultFixedDosing: AdmixtureFixedDosing = {
  type: 'FIXED',
  refBatchWeightInLbs: 0,
  volumeInMl: 0,
};

export const defaultSteppedDosing: AdmixtureSteppedDosing = {
  type: 'STEPPED',
  refBatchWeightInLbs: 0,
  source: 'ambient_temperature',
  steps: [
    {
      lowTempF: ambientTemperatureRangeMinimumLowDefined,
      volumeAtLowTempInMl: 0,
      highTempF: ambientTemperatureRangeMinimumHighDefined,
      volumeAtHighTempInMl: 0,
    },
  ],
};

export const defaultAdmixture: AdmixtureForm = {
  key: '',
  name: '',
  density: 1,
  enforcedPriority: undefined,
  dosing: undefined,
  producer: '',
};
