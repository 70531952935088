import { Project, System } from '@/generated/hasura.graphql';
import moment from 'moment';
import roundTo from 'round-to';

export const formatSystemLabel = (system: System): string => {
  return `${system.name} (${system.serial})`;
};

export const formatProjectLabel = (
  project: Pick<Project, 'label' | 'id'>,
): string => {
  return `${project.label}`;
};

export const formatItemLength = (itemLength: number | null): string => {
  if (itemLength === undefined || itemLength === null) return '';
  const roundedLength = roundTo(itemLength, 2);
  return `${roundedLength}`;
};

export const formatDate = (d: string | unknown) => {
  if (typeof d !== 'string') return '';
  return moment(d).format('M/D/YY h:mm:ss a ZZ');
};
