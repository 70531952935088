import React, { useState } from 'react';
import styled from 'styled-components';

import colors from '@/constants/colors';
import Select from '@/components/atoms/select';
import { ValueType } from 'react-select';

type Item = { value: number; label: string };

// These are hard-coded because the query to get the unique list
// would be a large query, and there are test types in the data
// we wouldn't want to show up.

const PrintEventTypes = [
  'admixture-supply-threshold-crossed',
  'e-stop-activated',
  'e-stop-deactivated',
  'extrusion-started',
  'extrusion-stopped',
  'machine-batching',
  'machine-down',
  'machine-dry-run',
  'machine-e-stopped',
  'machine-off',
  'machine-offline',
  'machine-on',
  'machine-online',
  'machine-operator-ready',
  'machine-printer-ready',
  'machine-printing',
  'machine-shutdown',
  'magma-aborted',
  'magma-aborting',
  'magma-batch-done-prompt',
  'magma-dispensing',
  'magma-e-stopped',
  'magma-e-stopping',
  'magma-fill-weigh-hopper',
  'magma-initializing',
  'magma-mixing',
  'magma-mixing-paused',
  'magma-offline',
  'magma-online',
  'magma-ready',
  'magma-stopped',
  'magma-stopping',
  'maintenance-mode-off',
  'maintenance-mode-on',
  'mantle-fill-level-change',
  'nnd-dosage-updated',
  'print-item-finished',
  'print-item-paused',
  'print-item-resumed',
  'print-item-started',
  'print-item-stopped',
  'print-item-travel',
  'vulcan-aborted',
  'vulcan-e-stopped',
  'vulcan-e-stopping',
  'vulcan-fill-e-axis',
  'vulcan-homing',
  'vulcan-initializing',
  'vulcan-jogging-group-rel',
  'vulcan-jogging-ind-rel',
  'vulcan-offline',
  'vulcan-online',
  'vulcan-paused',
  'vulcan-printing',
  'vulcan-ready',
  'vulcan-resume',
  'vulcan-retrace-backward',
  'vulcan-retrace-forward',
  'vulcan-stopped',
  'vulcan-stopping',
  'vulcan-travel-to-start',
];

const Container = styled.div`
  background-color: white;
  border-color: ${colors.gray['200']};
  border-width: 1px;
  border-radius: 4px;
  padding: 0 0 0 0.75rem;
  display: flex;
  align-items: center;

  > *:last-child {
    min-width: 8rem;
  }

  [class*='control'] {
    border-width: 0px;
  }
`;

const TypeSelect = ({
  className,
  onChange,
}: {
  className?: string;
  onChange: (types: string[]) => void;
}) => {
  const [selectedTypes, setSelectedTypes] = useState<string[]>([]);

  const handleTypeChange = (selectedItems: ValueType<Item>) => {
    // The declared type for arg selectedItems is singular but the real type is
    // an array The typings for our Select atom (and the internal react-select
    // Select) are not comprehensive
    const types = (selectedItems || []).map((x) => x.label);
    // Assign selected types
    setSelectedTypes(types);
    onChange(types);
  };

  return (
    <Container className={className}>
      <label className="text-sm uppercase font-semibold mr-2 text-gray-800">
        Type:
      </label>
      <Select
        isMulti
        isClearable
        onChange={handleTypeChange}
        value={selectedTypes.map((x, id) => ({
          value: id,
          label: x,
        }))}
        options={PrintEventTypes.map((s, id) => ({
          value: id,
          label: s,
        }))}
      />
    </Container>
  );
};

export default TypeSelect;
