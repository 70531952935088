import gql from 'graphql-tag';

export const ChecklistFields = gql`
  fragment ChecklistFields on checklist {
    archived
    created_at
    created_by
    description
    id
    items
    name
    updated_at
  }
`;
