import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  padding: 0 2rem;
  height: 4rem;
  border-bottom: 1px solid #eee;
  display: flex;
  align-items: center;
  font-size: 20px;
  font-family: 'Montserrat', sans-serif;
`;

const Title = styled.div<{ secondary: boolean }>`
  font-size: 20px;
  font-weight: ${({ secondary }) => (secondary ? 'normal' : '500')};
  color: ${({ secondary }) => (secondary ? '#999' : 'inherit')};
`;

const Subtitle = styled.div`
  color: black;
  font-weight: 500;
  margin-left: 24px;
`;

const BackButton = styled.button`
  padding-left: 16px;
  margin-left: -16px;
  display: flex;
  i {
    color: #555;
    margin-right: 8px;
  }
`;

const Action = styled.div`
  margin-left: auto;
`;

export interface Props {
  title: string;
  subtitle?: string;
  onNavBack?: () => void;
  action: React.ReactNode;
}

const TitleBar = (props: Props) => (
  <Container>
    {props.onNavBack ? (
      <BackButton type="button" onClick={props.onNavBack}>
        <i className="icon-left-open" />
        <Title secondary={Boolean(props.subtitle)}>{props.title}</Title>
      </BackButton>
    ) : (
      <Title secondary={Boolean(props.subtitle)}>{props.title}</Title>
    )}
    {props.subtitle && <Subtitle>{props.subtitle}</Subtitle>}
    {props.action && <Action>{props.action}</Action>}
  </Container>
);

export default TitleBar;
