import React from 'react';

export const CirclePlus = () => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.54003 4.07999H8.40003V8.4H4.08003V9.54H8.40003V13.86H9.54003V9.54H13.92V8.4H9.54003V4.07999Z"
        fill="currentColor"
      />
      <path
        d="M9 18C4.02 18 0 13.98 0 9C0 4.02 4.02 0 9 0C13.98 0 18 4.02 18 9C18 13.92 13.92 18 9 18ZM9 1.2C4.74 1.2 1.2 4.68 1.2 9C1.2 13.26 4.68 16.8 9 16.8C13.26 16.8 16.8 13.32 16.8 9C16.74 4.68 13.26 1.2 9 1.2Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default CirclePlus;
