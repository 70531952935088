/* eslint-disable @typescript-eslint/camelcase */
import { navigate } from 'gatsby';
import { useMutation } from '@apollo/client';
import React from 'react';

import App from '@templates/app';
import { CodeFormValues } from '../types';
import CodeEditor from '../code-editor';

import {
  addLogCode as addLogCodeMutation,
  addLogCodeAudience as addLogCodeAudienceMutation,
  addLogCodeResponsibility as addLogCodeResponsibilityMutation,
} from '../queries';

const CodeAdd = () => {
  // Save Log Code
  const [addLogCode] = useMutation(addLogCodeMutation);
  const [addLogAudience] = useMutation(addLogCodeAudienceMutation);
  const [addLogResponsibility] = useMutation(addLogCodeResponsibilityMutation);

  // Save Log Code.
  const handleSave = async (logCode: CodeFormValues) => {
    const {
      data: { insert_code_one: result },
    } = await addLogCode({
      variables: {
        description: logCode.description,
        level_id: logCode.level?.value,
        resolution: logCode.resolution,
        name: logCode.name,
        operation_id: logCode.operation?.value,
        disruption_level_id: logCode.disruptionLevel?.value,
        log_system_id: logCode.system?.value,
        audio_notification_id: logCode.audioNotification?.value,
      },
    });
    const { id: code_id } = result;

    // Add relationships to Audience / Responsibility.
    const addRelationships = [
      ...logCode.audience.map((a) =>
        addLogAudience({
          variables: {
            audience_id: a.value,
            code_id,
          },
        }),
      ),
      ...logCode.responsibility.map((r) =>
        addLogResponsibility({
          variables: {
            code_id,
            responsibility_id: r.value,
          },
        }),
      ),
    ];
    await Promise.all(addRelationships);

    // Navigate to newly created Log Code
    navigate(`/codes/${code_id}`);
  };

  return (
    <App
      onNavBack={() => navigate('/codes')}
      section="codes"
      subtitle="New Log Code"
      title="Log Codes"
    >
      <CodeEditor save={handleSave} />
    </App>
  );
};

export default CodeAdd;
