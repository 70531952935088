import { equals, has, omit } from 'ramda';
import { navigate } from 'gatsby';
import { RouteComponentProps } from '@reach/router';
import { useQuery, useMutation } from '@apollo/client';
import React, { useState } from 'react';
import styled from 'styled-components';

import {
  AddChecklistMutation,
  GetChecklistQuery,
} from '@/generated/hasura.graphql';
import { useAuth } from '@providers/auth';
import App from '@templates/app';
import colors from '@/constants/colors';
import CirclePlusSvg from '@/components/icons/circle-plus';
import EmptyPage from '@/admin/empty-page';
import NoResults from '@molecules/no-results';

import { Checklist, ChecklistItem } from '../types';
import {
  generateChecklistItem,
  transformChecklistDbEntity,
  transformChecklistToDbEntity,
} from '../calculations';
import {
  addChecklist as addChecklistMutation,
  getChecklist as getChecklistQuery,
} from '../queries';
import ChecklistButton from '../button';
import EditPanel from './edit-panel';
import SortableItems from './sortable';

interface ChecklistsEditProps extends RouteComponentProps {
  className?: string;
  id?: number;
}

const ColumnName = styled.span`
  color: ${colors.gray['600']};
  font-family: Montserrat;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1rem;
  text-transform: uppercase;
`;

const Row = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr auto;
`;

const Actions = styled.div`
  display: inline-flex;

  > * + * {
    margin-left: 0.5rem;
  }
`;

interface SelectedChecklistItem extends ChecklistItem {
  nested: boolean;
}

export const ChecklistsEdit = (props: ChecklistsEditProps) => {
  // Retrieve checklists from Sol.
  const { loading, error, data } = useQuery<GetChecklistQuery>(
    getChecklistQuery,
    {
      fetchPolicy: 'no-cache',
      variables: { id: props.id },
    },
  );

  // Get auth context.
  const auth = useAuth();
  const userName = auth?.user ? auth.user.name : 'N/A';

  // Flag state for initializing checklist.
  const [initialized, setInitialized] = useState<boolean>(false);

  // Working checklist object.
  const [checklist, setChecklist] = useState<Checklist>();

  // Mirror of checklist in DB.
  const [savedChecklistItems, setSavedChecklistItems] = useState<
    ChecklistItem[]
  >([]);

  // Items contained within Checklist.
  const [checklistItems, setChecklistItems] = useState<ChecklistItem[]>([]);

  // Add Checklist to DB
  const [updateChecklist] = useMutation<AddChecklistMutation>(
    addChecklistMutation,
  );

  // Selected item used for rendering Panel.
  const [
    selectedItem,
    setSelectedItem,
  ] = useState<null | SelectedChecklistItem>(null);

  // Handle Loading / Error states for Get Checklist Query.
  if (loading) return <EmptyPage sectionLabel="Checklist" />;
  if (error || !data?.checklist_by_pk)
    return <EmptyPage problem sectionLabel="Checklist" />;

  // Populate initial state of Checklist.
  if (!initialized) {
    const checklistData = transformChecklistDbEntity(data.checklist_by_pk);
    setChecklist(checklistData);
    setChecklistItems(checklistData.items);
    setSavedChecklistItems(checklistData.items);
    setInitialized(true);
  }

  // Recursively modify the selected item with a partial checklist item payload.
  const modifyItemById = (
    updatedItem: Partial<ChecklistItem>,
    id: string | undefined,
    items: ChecklistItem[] = checklistItems,
  ): ChecklistItem[] =>
    items.map((checklistItem) => {
      if (checklistItem.id === id) {
        if (has('items', updatedItem)) {
          return {
            ...checklistItem,
            ...updatedItem,
          };
        }

        return {
          ...checklistItem,
          ...updatedItem,
          items: modifyItemById(updatedItem, id, checklistItem.items),
        };
      }

      return {
        ...checklistItem,
        items: modifyItemById(updatedItem, id, checklistItem.items),
      };
    });

  // Update checklistItems with the modified item.
  const updateItem = (
    updatedItem: Partial<ChecklistItem>,
    id: string | undefined = selectedItem?.id,
  ) => {
    // Define updatedAt to match current time.
    const updatedAt = new Date();

    if (id === selectedItem?.id) {
      const item = {
        ...selectedItem,
        ...updatedItem,
        updatedAt,
      } as SelectedChecklistItem;
      setSelectedItem(item);
    }

    setChecklistItems(modifyItemById({ ...updatedItem, updatedAt }, id));
  };

  const panel = selectedItem ? (
    <EditPanel
      key={selectedItem.id}
      {...selectedItem}
      updateItem={updateItem}
    />
  ) : null;

  // Modifies Checklist in DB and modifies the local state.
  const handleSaveChecklist = async (saveChecklistItems: ChecklistItem[]) => {
    const payload = omit(['createdAt', 'updatedAt', 'archived'], {
      ...checklist,
      items: saveChecklistItems.map((s) => transformChecklistToDbEntity(s)),
    });

    const updated = await updateChecklist({
      variables: {
        ...payload,
        items: JSON.stringify(payload.items),
      },
    });

    if (updated.data?.insert_checklist_one) {
      const updatedChecklist = transformChecklistDbEntity(
        updated.data.insert_checklist_one,
      );
      setChecklist(updatedChecklist);
      setChecklistItems(updatedChecklist.items);
      setSavedChecklistItems(updatedChecklist.items);
    }
  };

  const changed = !equals(savedChecklistItems, checklistItems);
  const actions = (
    <Actions>
      <ChecklistButton
        filled
        leading={<CirclePlusSvg />}
        onClick={() => {
          setChecklistItems([
            ...checklistItems,
            generateChecklistItem(userName)('New Checklist Item'),
          ]);
        }}
      >
        Item
      </ChecklistButton>
      <ChecklistButton
        color={changed ? colors.cyan['500'] : colors.gray['300']}
        disabled={!changed}
        filled
        onClick={() => handleSaveChecklist(checklistItems)}
      >
        Save
      </ChecklistButton>
    </Actions>
  );

  // Ensure a safe exit to prevent render errors during React's cycle of
  // updating stateful variables.
  if (typeof checklist === 'undefined') {
    return <EmptyPage sectionLabel="Checklist" />;
  }

  return (
    <App
      onNavBack={() => navigate('/checklists')}
      panel={panel}
      section="checklists"
      subtitle={checklist?.name || ''}
      title="Checklists"
      action={actions}
    >
      {checklistItems.length ? (
        <>
          <Row className="mb-3 ml-4 mr-4">
            <ColumnName>Name</ColumnName>
            <ColumnName>Printer</ColumnName>
            <ColumnName>Role</ColumnName>
            <ColumnName>Actions</ColumnName>
          </Row>
          <SortableItems
            items={checklistItems}
            setItems={setChecklistItems}
            selectedItem={selectedItem}
            setSelectedItem={(item: ChecklistItem | null) => {
              if (item === null) {
                setSelectedItem(item);
                return;
              }

              setSelectedItem({
                ...item,
                nested: !checklistItems.find((i) => i.id === item.id),
              });
            }}
            updateItem={updateItem}
          />
        </>
      ) : (
        <NoResults title="Add Checklist Items" />
      )}
    </App>
  );
};

export default ChecklistsEdit;
