import React from 'react';
import styled, { css } from 'styled-components';

import { SizeTheme, Theme } from '@/types';
import * as colors from '@data/colors';

type ColorName = keyof typeof colors;

type themeValues = 'content' | 'ui' | 'pro-ui';
type sizeThemeValues = 'default' | 'jumbo' | 'large' | 'small';
type keyedFontSizes = {
  [key in themeValues]: {
    [key in sizeThemeValues]: string;
  };
};
const fontSizes: keyedFontSizes = {
  content: {
    default: '1rem',
    large: '1.125rem',
    jumbo: '1.5rem',
    small: '0.875rem',
  },
  ui: {
    default: '0.875rem',
    large: '1rem',
    jumbo: '1.25rem',
    small: '0.75rem',
  },
  'pro-ui': {
    default: '0.875rem',
    large: '1rem',
    jumbo: '1.25rem',
    small: '0.75rem',
  },
};

export interface ButtonElProps {
  centered?: boolean;
  className?: string;
  color?: ColorName;
  danger?: boolean;
  disabled?: boolean;
  icon?: boolean | string;
  secondary?: boolean;
  sizeTheme?: SizeTheme;
  testId?: string;
  theme?: Theme;
  uppercase?: boolean;
  wide?: boolean;
}

const border = ({ danger, secondary }: ButtonElProps) => {
  if (!secondary) return 'none';
  return `1px solid ${danger ? `${colors.red}11` : '#ddd'}`;
};

const themeStyles = ({ sizeTheme, theme }: ButtonElProps) => {
  if (theme === Theme.Content) {
    return css`
      border-radius: 0;
      font-weight: 400;
      line-height: ${sizeTheme === SizeTheme.Default ||
      sizeTheme === SizeTheme.Large
        ? '4rem'
        : '3.5em'};
      padding: 0 2em;
    `;
  }

  return css`
    border-radius: 4px;
    font-weight: 500;
    line-height: 2.875em;
    padding: 0 1.5em;
  `;
};

const fontSize = ({ sizeTheme, theme }: ButtonElProps) => {
  if (!theme || !sizeTheme) return '1rem';
  return fontSizes[theme][sizeTheme] || '1rem';
};

const background = ({ color, danger, disabled, secondary }: ButtonElProps) => {
  if (disabled || typeof color === 'undefined') return '#f8f8f8';
  if (color === 'gradient')
    return 'linear-gradient(135deg, #00cbe6 0%, #bb93ff 100%)';
  if (!secondary) return danger ? colors.red : colors[color];
  return danger ? `${colors.red}11` : '#f8f8f8';
};

const textColor = ({ secondary, disabled }: ButtonElProps) => {
  if (disabled) return '#bbb';
  return secondary ? '#666' : 'white';
};

const margin = ({ centered }: ButtonElProps) => {
  if (centered) {
    return css`
      margin-left: auto;
      margin-right: auto;
    `;
  }

  return '';
};

export const ButtonEl = styled.button<ButtonElProps>`
  background: ${background};
  border: ${border};
  color: ${textColor};
  display: block;
  font-size: ${fontSize};
  overflow: hidden;
  position: relative;
  text-align: center;
  transition: background 300ms;
  text-transform: ${({ uppercase }) => (uppercase ? 'uppercase' : 'none')};
  white-space: nowrap;
  ${({ wide }) => (wide ? 'width: 100%;' : '')}
  ${margin};
  ${themeStyles}

  &:disabled {
    cursor: default;
  }

  &:focus {
    outline: none;
  }

  &:hover:not(:disabled) {
    background: ${(props) => (props.danger ? colors.red : background(props))};
    color: ${(props) => (props.danger ? 'white' : textColor(props))};
  }

  i {
    margin-left: -0.5em;
    margin-right: 0.25em;
    opacity: ${({ secondary }) => (secondary ? 0.5 : 0.8)};
  }

  svg {
    color: inherit;
    display: inline-block;
    margin-left: -0.5em;
    margin-right: 0.25em;
    opacity: 0.8;
  }
`;

export const Button = ({
  children,
  centered = false,
  className = '',
  color = 'cyan',
  danger = false,
  icon,
  onClick,
  secondary = false,
  sizeTheme = SizeTheme.Default,
  testId,
  theme = Theme.UI,
  uppercase = false,
  wide = false,
}: ButtonElProps & {
  children: React.ReactNode;
  onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}) => {
  return (
    <ButtonEl
      centered={centered}
      className={className}
      color={color}
      danger={danger}
      data-testid={testId || false}
      disabled={!onClick}
      icon={Boolean(icon) && icon}
      onClick={onClick}
      secondary={secondary}
      sizeTheme={sizeTheme}
      theme={theme}
      uppercase={uppercase}
      wide={wide}
    >
      {icon && <i className={`icon-${icon}`} data-testid="icon" />}
      {children}
    </ButtonEl>
  );
};

export default Button;
